import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import CustomSlider from "../components/CustomSlider";
import Divider from "@material-ui/core/Divider";
import { RB_IMAGE_LOGGER } from "../constants/colors";
import { showLayer, hideLayer } from "../actions/layers";
import moment from "moment-timezone";
import State from "../interfaces/state";
import { clickBehaviorConstants } from "constants/clickBehavior";
import * as MapboxGl from "mapbox-gl";
import Layer from "../interfaces/state/layers/layer";

interface LayerProps {
  dateDriven: string;
  id: number;
}

interface LayerGroupsProps {
  clickBehavior: string;
  layers: LayerProps[];
}

interface MarkProps {
  value: number;
  label: string;
  date: number;
  layerId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "80%",
      textAlign: "center",
      margin: "auto",
    },
    margin: {
      height: theme.spacing(3),
    },
    divider: {
      width: "100%",
    },
    collectionTitle: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "18px",
    },
  })
);

const valuetext = (value: number): string => {
  return `${value}`;
};

const TimelineSlider = ({ map }: { map: MapboxGl.Map }): React.ReactElement => {
  const classes = useStyles();
  const [value, setValue] = useState<number[]>([0]);
  const [marks, setMarks] = useState<MarkProps[]>([]);
  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);
  const dispatch = useDispatch();
  const layerGroups = useSelector((state: State) => state.layers.groups);

  const flyToLayer = (layerId: number): void => {
    // get all layers
    const layers = layerGroups.reduce((a, b) => {
      if (b.layers.length > 0) {
        return a.concat(b.layers);
      }
      return a;
    }, [] as Layer[]);
    // Get Layer name from layer id
    const layer = layers.find(layer => layer.id === layerId);
    if (layer) {
      const { bounds } = map.getSource(
        layer.sourceLayer
      ) as MapboxGl.VectorSource;
      if (bounds) {
        map.fitBounds(bounds as MapboxGl.LngLatBoundsLike);
      }
    }
  };

  const handleSliderChange = (_event: object, value: number[]): void => {
    setValue(value);
    if (!map) return;
    const layerToToggle = find(marks, { value: value[0] });
    if (layerToToggle) {
      dispatch(showLayer(layerToToggle.layerId));
      marks.forEach(mark => {
        if (mark.layerId !== layerToToggle.layerId) {
          dispatch(hideLayer(mark.layerId));
        }
      });
      flyToLayer(layerToToggle.layerId);
    }
  };

  const setSliderMarks = useCallback((): void => {
    const dateDrivenArr: MarkProps[] = [];
    layerGroups.forEach(layerGroup => {
      layerGroup.layers.forEach((layer: LayerProps) => {
        dateDrivenArr.push({
          // the material-ui slider automatically sorts the marks array by the value
          // so to combat this a value of 0 is initially defined
          value: 0,
          label: moment(layer.dateDriven).format("MMM Do YYYY h:mm a"),
          layerId: layer.id,
          date: moment(layer.dateDriven).valueOf(),
        });
      });
    });
    // then the array is sorted based on the date
    const sortedDateDrivenArr = dateDrivenArr.sort(
      (a: MarkProps, b: MarkProps) => a.date - b.date
    );
    // and finally, using a .map on the sorted array, the values are added according to the index
    const sortedIndexedDateDrivenArr = sortedDateDrivenArr.map((item, idx) => {
      return { ...item, value: idx };
    });
    setMarks(sortedIndexedDateDrivenArr);
  }, [layerGroups]);

  useEffect(() => {
    if (!isEmpty(marks)) {
      setMax(
        marks.sort((a: MarkProps, b: MarkProps) => b.value - a.value)[0].value
      );
      setMin(
        marks.sort((a: MarkProps, b: MarkProps) => a.value - b.value)[0].value
      );
    }
  }, [marks]);

  useEffect(() => {
    setSliderMarks();
  }, [layerGroups, setSliderMarks]);

  const hasImageLoggerData: LayerGroupsProps[] = filter(
    layerGroups,
    (obj: LayerGroupsProps) =>
      obj.clickBehavior === clickBehaviorConstants.roadwayImageLoggerPoint
  );

  return (
    <div className={classes.root}>
      {!isEmpty(hasImageLoggerData) && (
        <div>
          <Typography
            id="discrete-slider-custom"
            gutterBottom
            className={classes.collectionTitle}
          >
            Image Collections
          </Typography>
          <Divider className={classes.divider} />
          <CustomSlider
            orientation={"vertical"}
            valueLabelDisplay={"off"}
            value={value}
            handleSliderChange={handleSliderChange}
            marks={marks}
            valuetext={valuetext}
            step={1}
            max={max}
            min={min}
            showSlider={true}
            sliderColor={RB_IMAGE_LOGGER}
            itemCount={max + 1}
          />
        </div>
      )}
    </div>
  );
};

export default TimelineSlider;
