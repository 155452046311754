import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleLayerBySource } from "../actions/layers";
import PotholeCheckbox from "../components/PotholeCheckbox";

const SliderGroupContainer = ({ toggleGroups, toggleLayerBySource }) => {
  const [potholeValue, setPotholeValue] = useState(false);

  const determineIfPotholeLayerExists = () => {
    // loop over the toggleGroups looking for pothole type
    const potholeGroup = toggleGroups.filter(
      toggleGroup => toggleGroup.name === "potholes"
    );
    return potholeGroup && potholeGroup.length >= 1;
  };

  const toggleLayer = value => {
    // loop over the toggleGroups looking for pothole type
    const potholeToggleGroup = toggleGroups.filter(
      toggleGroup => toggleGroup.name === "potholes"
    );
    // go into toggles, get the id of the first one
    const potholelayerSource = potholeToggleGroup[0].toggles[0].layerId;

    // use layerId property to toggle by using toggleLayer in actions/layers
    toggleLayerBySource(potholelayerSource, value);
  };

  const handleCheckboxChange = () => {
    setPotholeValue(!potholeValue);
    // have to toggle the ! due to the state value not updating in time
    toggleLayer(!potholeValue);
  };

  return (
    <div>
      {determineIfPotholeLayerExists() && (
        <PotholeCheckbox
          handleShowPotholeChange={handleCheckboxChange}
          showPotholes={potholeValue}
        />
      )}
    </div>
  );
};

SliderGroupContainer.propTypes = {
  toggleLayerBySource: PropTypes.func.isRequired,
  toggleGroups: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  toggleGroups: state.toggles.groups,
});

const mapDispatchToProps = dispatch => ({
  toggleLayerBySource: (layerSource, value) =>
    dispatch(toggleLayerBySource(layerSource, value)),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SliderGroupContainer);
