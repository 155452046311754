import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "components/form/Button";

const useStyles = makeStyles({
  textField: {
    width: "50%",
    textAlign: "center",
  },
  content: {
    textAlign: "center",
  },
  fieldDiv: {
    margin: "2%",
  },
  actions: {
    float: "right",
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "28px",
  },
});

interface AppearanceProps {
  user: string;
  saveProfileSettings: Function;
  onTextFieldChange: Function;
  firstName: string;
  lastName: string;
  organization: string;
  disabled: boolean;
}

const AppearanceSettings = ({
  user,
  saveProfileSettings,
  onTextFieldChange,
  firstName,
  lastName,
  organization,
  disabled,
}: AppearanceProps): React.ReactNode => {
  const classes = useStyles();
  return (
    <div>
      <Grid container={true}>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Card>
            <form onSubmit={e => e.preventDefault()}>
              <CardHeader
                title={
                  <Typography className={classes.typography}>
                    Profile
                  </Typography>
                }
              />
              <Divider />
              <CardContent className={classes.content}>
                <div className={classes.fieldDiv}>
                  <Typography>Email:</Typography>
                  <TextField
                    disabled
                    id="email"
                    label="email"
                    value={user}
                    margin="dense"
                    variant="outlined"
                    className={classes.textField}
                  />
                </div>
                <div className={classes.fieldDiv}>
                  <Typography>First Name:</Typography>
                  <TextField
                    disabled={disabled}
                    id="first-name"
                    label="First Name"
                    value={firstName}
                    margin="dense"
                    variant="outlined"
                    onChange={e => onTextFieldChange(e, "firstName")}
                    className={classes.textField}
                  />
                </div>
                <div className={classes.fieldDiv}>
                  <Typography>Last Name:</Typography>
                  <TextField
                    disabled={disabled}
                    id="last-name"
                    label="Last Name"
                    value={lastName}
                    margin="dense"
                    variant="outlined"
                    onChange={e => onTextFieldChange(e, "lastName")}
                    className={classes.textField}
                  />
                </div>
                <div className={classes.fieldDiv}>
                  <Typography>Organization:</Typography>
                  <TextField
                    disabled
                    id="organization"
                    label="Organization"
                    value={organization}
                    margin="dense"
                    variant="outlined"
                    onChange={e => onTextFieldChange(e, "organization")}
                    className={classes.textField}
                  />
                </div>
              </CardContent>
              <Divider />

              <CardActions className={classes.actions}>
                <Button
                  disabled={disabled}
                  variant="contained"
                  onClick={(): void => saveProfileSettings()}
                  color="primary"
                  type="submit"
                  children="Save Profile"
                />
              </CardActions>
            </form>
          </Card>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </div>
  );
};

export default AppearanceSettings;
