import React from "react";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { ROUTE_SUMMARY } from "../../constants/routes";
import { useHistory } from "react-router-dom";
import cardBackground from "images/maps/card_background.jpg";
import { RB_YELLOW } from "../../constants/colors";
import Organization from "interfaces/organization";
import Scan from "interfaces/scan";
import Ribbon from "components/Ribbon";
import { useSelector } from "react-redux";
import classNames from "classnames";
import UserStatus from "../../constants/userStatus";
import State from "../../interfaces/state";
import ChildOrgAnalysisDropDown from "./ChildOrgAnalysisDropDown";

const useStyles = makeStyles({
  scanSelector: {
    width: "51%",
    backgroundColor: RB_YELLOW,
    borderRadius: "2px",
    marginLeft: "auto",
    marginRight: "auto",
    borderBottom: "none",
    fontFamily: "'Open Sans', sans-serif",
    position: "absolute",
    bottom: "10px",
    right: "10px",
  },
  card: {
    backgroundRepeat: "no-repeat",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
  paperMap: {
    height: "200px",
    padding: "10px",
    position: "relative",
    borderBottom: `solid 7px ${RB_YELLOW}`,
    borderTop: `solid 7px ${RB_YELLOW}`,
    backgroundImage: `url(${cardBackground})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  parentOrgName: {
    fontWeight: "bold",
    color: "gray",
    fontSize: "16px",
    fontFamily: "'Open Sans', sans-serif",
  },
  orgName: {
    fontWeight: "bold",
    marginTop: "10px",
    fontSize: "21px",
    fontFamily: "'Open Sans', sans-serif",
  },
  selectItemText: {
    fontSize: "14px",
  },
  gridWrapper: {
    position: "relative",
  },
  textPadding: {
    paddingRight: "90px",
  },
});

export const isAssessmentAnalysisPresent = (
  childOrg: Organization
): boolean => {
  return (childOrg?.scans || []).some((scan: Scan) => scan?.analysis);
};

const ChildOrgGrid = (props: ChildOrgGridProps): JSX.Element => {
  const classes = useStyles(props);
  const { childOrgs, setActiveScan, parentName } = props;
  const history = useHistory();
  const hideExampleOrganizations = useSelector(
    (state: State) => state?.userData?.hideExampleOrganizations
  );
  const userStatus = useSelector((state: State) => state?.user?.status);

  const goToSummaryPage = (
    e: React.ChangeEvent<{ value: unknown }>,
    childOrg: Organization
  ): void => {
    // the target value on the event has both the org and scan id
    const orgAndScanIdList = e.target.value as string[];
    const scanId = orgAndScanIdList[1];
    const scanData = childOrg?.scans?.find(scans => scans.id === scanId);
    if (isAssessmentAnalysisPresent(childOrg) && scanData) {
      history.push(`${ROUTE_SUMMARY}/${scanData.id}`);
    } else {
      console.error("no analysis data");
    }
  };

  return (
    <div>
      <Grid container spacing={5}>
        {childOrgs
          .filter(childOrg => {
            return !(
              hideExampleOrganizations && childOrg.isExampleOrganization
            );
          })
          .map(
            (childOrg: Organization): JSX.Element => {
              const assessmentAnalysisPresent = isAssessmentAnalysisPresent(
                childOrg
              );
              const isAccountDisabled = userStatus === UserStatus.DISABLED;
              const childOrgScans: Scan[] = childOrg?.scans || [];
              if (childOrgScans.length > 0) {
                return (
                  <Grid
                    className={classes.gridWrapper}
                    key={childOrg.id}
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    {childOrg.isExampleOrganization === true && (
                      <Ribbon>Example</Ribbon>
                    )}
                    <Paper
                      className={classNames(
                        classes.paperMap,
                        classes.textPadding
                      )}
                    >
                      <div>
                        <Typography className={classes.orgName}>
                          {childOrg.name}
                        </Typography>
                        <Typography className={classes.parentOrgName}>
                          {parentName}
                        </Typography>
                      </div>

                      <ChildOrgAnalysisDropDown
                        isAccountDisabled={isAccountDisabled}
                        assessmentAnalysisPresent={assessmentAnalysisPresent}
                        goToSummaryPage={goToSummaryPage}
                        childOrg={childOrg}
                      />

                      <FormControl
                        className={classes.scanSelector}
                        disabled={isAccountDisabled}
                      >
                        <Select
                          value=""
                          inputProps={{
                            id: "scan-simple",
                          }}
                          displayEmpty
                          disableUnderline
                          onChange={setActiveScan}
                        >
                          <MenuItem value="" disabled>
                            <Typography
                              align="center"
                              className={classes.selectItemText}
                            >
                              Assessments
                            </Typography>
                          </MenuItem>
                          {childOrgScans.map(scan => {
                            return (
                              <MenuItem
                                key={scan.id}
                                value={[scan.organizationId, scan.id]}
                                disabled={!scan?.defaults?.bounds}
                              >
                                <Typography className={classes.selectItemText}>
                                  {scan.displayName || scan.name}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Paper>
                  </Grid>
                );
              } else {
                return <div key="none" />;
              }
            }
          )}
      </Grid>
    </div>
  );
};

interface ChildOrgGridProps {
  childOrgs: Organization[];
  parentName: string;
  setActiveScan: (event: React.ChangeEvent<{ value: unknown }>) => {};
}

ChildOrgGrid.defaultProps = {
  parentName: "",
} as Partial<ChildOrgGridProps>;

ChildOrgGrid.defaultProps = {
  parentName: "",
};

export default ChildOrgGrid;
