import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import roadwayPoint from "utils/clickBehavior/roadwayPoint";
import imageLoggerPoint from "utils/clickBehavior/imageLoggerPoint";
import { getLeftAndRightPoints } from "utils/pointsUtils";
import ChevronLeftRounded from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRounded from "@material-ui/icons/ChevronRightRounded";
import PlayCircleOutlineRounded from "@material-ui/icons/PlayCircleOutlineRounded";
import PauseCircleOutlineRounded from "@material-ui/icons/PauseCircleOutlineRounded";
import leftEnd from "images/icons/leftEnd.png";
import rightEnd from "images/icons/rightEnd.png";
import * as Sentry from "@sentry/browser/dist/index";
import { playModal, stopModal, noPointsModal } from "actions/modal";
import { showLayer } from "actions/layers";
import track from "react-tracking";
import makeStyles from "@material-ui/core/styles/makeStyles";
import style from "../style";
import { clickBehaviorConstants } from "constants/clickBehavior";

const useStyles = makeStyles(style);

const VirtualDriverControls = ({
  modalData,
  enlargedImg,
  sidebarVisibility,
  modalVisibility,
  isPlaying,
  userUId,
  fatigueCrackingChecked,
  patchSealChecked,
  pavementDistortionsChecked,
  surfaceDeteriorationChecked,
  potholeChecked,
  transLongChecked,
  playModal,
  stopModal,
  map,
  dispatch,
  tracking,
}) => {
  const classes = useStyles();

  const loadNearbyPoints = useCallback((leftAndRightPoints, counter, map) => {
    const [leftPoint, rightPoint] = leftAndRightPoints;
    if (counter > 4) return;
    if (!isEmpty(leftPoint)) {
      const leftImage = new Image();
      leftImage.src = leftPoint.properties.href;
    }
    if (!isEmpty(rightPoint)) {
      const rightImage = new Image();
      rightImage.src = rightPoint.properties.href;
      rightImage.onload = () => {
        loadNearbyPoints(
          getLeftAndRightPoints({ feature: rightPoint, map }),
          counter + 1,
          map
        );
      };
    }
  }, []);

  const handlePointClick = useCallback(
    (point, modalType, isNewlyClickedPoint) => {
      if (isNewlyClickedPoint) {
        stopModal();
      }
      const leftAndRightPoints = getLeftAndRightPoints({
        feature: point,
        map,
        potholeChecked,
        fatigueCrackingChecked,
        patchSealChecked,
        transLongChecked,
        surfaceDeteriorationChecked,
        pavementDistortionsChecked,
      });
      loadNearbyPoints(leftAndRightPoints, 0, map);
      if (
        point.layer.metadata.clickBehavior ===
        clickBehaviorConstants.roadwayPoint
      ) {
        roadwayPoint({
          modalType,
          inSegmentModal: modalType === "point/segment",
          modalData,
          feature: point,
          dispatch,
          leftAndRightPoints,
          map,
          enlargedImg,
          modalVisibility,
          sidebarVisibility,
          switchPoint: isNewlyClickedPoint,
        });
      } else if (
        point.layer.metadata.clickBehavior ===
        clickBehaviorConstants.roadwayImageLoggerPoint
      ) {
        imageLoggerPoint({
          modalType,
          inSegmentModal: modalType === "point/segment",
          modalData,
          feature: point,
          dispatch,
          leftAndRightPoints,
          map,
          enlargedImg,
          modalVisibility,
          sidebarVisibility,
          switchPoint: isNewlyClickedPoint,
        });
      }
    },
    [
      dispatch,
      enlargedImg,
      fatigueCrackingChecked,
      loadNearbyPoints,
      map,
      modalData,
      modalVisibility,
      patchSealChecked,
      pavementDistortionsChecked,
      potholeChecked,
      sidebarVisibility,
      stopModal,
      surfaceDeteriorationChecked,
      transLongChecked,
    ]
  );

  const handleLeftAndRightKeypress = event => {
    if (isEmpty(modalData.pointData)) {
      return;
    }
    const leftAndRightPoints = modalData.pointData.content.leftAndRightPoints;
    const [leftPoint, rightPoint] = leftAndRightPoints;
    if (event.keyCode === 37) {
      if (!isEmpty(leftPoint)) {
        handlePointClick(leftPoint, modalData.type, true);
      }
    } else if (event.keyCode === 39) {
      if (!isEmpty(rightPoint)) {
        handlePointClick(rightPoint, modalData.type, true);
      }
    }
  };

  // eslint-disable-next-line react/sort-comp
  const debouncedHandleLeftAndRightKeypress = debounce(
    handleLeftAndRightKeypress,
    200,
    {
      leading: true,
      trailing: true,
    }
  );

  const enableLeftRightKeyShortcuts = useCallback(() => {
    window.addEventListener("keydown", debouncedHandleLeftAndRightKeypress);
  }, [debouncedHandleLeftAndRightKeypress]);

  const disableLeftRightKeyShortcuts = useCallback(() => {
    window.removeEventListener("keydown", debouncedHandleLeftAndRightKeypress);
  }, [debouncedHandleLeftAndRightKeypress]);

  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(() => {
        const leftAndRightPoints =
          modalData.pointData.content.leftAndRightPoints;
        const rightPoint = leftAndRightPoints[1];
        if (!isEmpty(rightPoint)) {
          handlePointClick(rightPoint, modalData.type, false);
        } else {
          stopModal();
        }
      }, 1500);

      return () => clearInterval(interval);
    }
  }, [isPlaying, handlePointClick, modalData, stopModal]);

  const playButtonClick = () => {
    if (!isPlaying) {
      tracking.trackEvent({
        event: "mouse-click",
        action: "play-button-click",
        userUId,
      });
      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `play button click`,
        level: Sentry.Severity.Info,
      });
      playModal();
    } else {
      tracking.trackEvent({
        event: "mouse-click",
        action: "pause-button-click",
        userUId,
      });
      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `pause button click`,
        level: Sentry.Severity.Info,
      });
      stopModal();
    }
  };

  useEffect(() => {
    enableLeftRightKeyShortcuts();

    return () => {
      disableLeftRightKeyShortcuts();
    };
  }, [enableLeftRightKeyShortcuts, disableLeftRightKeyShortcuts]);

  const leftAndRightPoints = modalData.pointData.content.leftAndRightPoints;
  const [leftPoint, rightPoint] = leftAndRightPoints;

  return (
    <div className={classes.container}>
      <img src={leftEnd} alt="" className={classes.leftEnd} />
      <Button
        disabled={isEmpty(leftPoint)}
        onClick={() => {
          tracking.trackEvent({
            event: "mouse-click",
            action: "previous-point-click",
            userUId,
          });
          Sentry.addBreadcrumb({
            category: "mouse-click",
            message: `previouse point click`,
            level: Sentry.Severity.Info,
          });
          handlePointClick(leftPoint, modalData.type, true);
        }}
        className={classes.leftButton}
      >
        <ChevronLeftRounded />
        PREV
      </Button>
      <Button
        disabled={isEmpty(rightPoint)}
        onClick={() => playButtonClick(modalData.type)}
      >
        {!isPlaying ? (
          <PlayCircleOutlineRounded className={classes.playButton} />
        ) : (
          <PauseCircleOutlineRounded className={classes.playButton} />
        )}
      </Button>
      <Button
        disabled={isEmpty(rightPoint)}
        onClick={() => {
          tracking.trackEvent({
            event: "mouse-click",
            action: "next-point-click",
            userUId,
          });

          Sentry.addBreadcrumb({
            category: "mouse-click",
            message: `next point click`,
            level: Sentry.Severity.Info,
          });
          handlePointClick(rightPoint, modalData.type, true);
        }}
        className={classes.rightButton}
      >
        NEXT
        <ChevronRightRounded />
      </Button>
      <img src={rightEnd} alt="" className={classes.rightEnd} />
    </div>
  );
};

VirtualDriverControls.propTypes = {
  modalData: PropTypes.object.isRequired,
  enlargedImg: PropTypes.bool.isRequired,
  sidebarVisibility: PropTypes.bool.isRequired,
  modalVisibility: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  fatigueCrackingChecked: PropTypes.bool.isRequired,
  userUId: PropTypes.string.isRequired,
  patchSealChecked: PropTypes.bool.isRequired,
  pavementDistortionsChecked: PropTypes.bool.isRequired,
  surfaceDeteriorationChecked: PropTypes.bool.isRequired,
  potholeChecked: PropTypes.bool.isRequired,
  transLongChecked: PropTypes.bool.isRequired,
  playModal: PropTypes.func.isRequired,
  stopModal: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  tracking: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  modalData: state.modal.modalData,
  enlargedImg: state.modal.enlargedImg,
  colorPicker: state.colorPicker,
  open: state.modal.isModalOpen,
  dataPresent: state.modal.dataPresent,
  layerGroups: state.layers.groups,
  toggleGroups: state.toggles.groups,
  sidebarVisibility: state.visibility.sidebarVisibility,
  modalVisibility: state.modal.isModalOpen,
  pointCoords: state.highlight.pointCoords,
  segmentCoords: state.highlight.segmentCoords,
  isPlaying: state.modal.isModalPlaying,
  userUId: state.user.userUId,
  noPoints: state.modal.noPoints,
  fatigueCrackingChecked: state.isolateDistresses.fatigueCrackingChecked,
  patchSealChecked: state.isolateDistresses.patchSealChecked,
  pavementDistortionsChecked:
    state.isolateDistresses.pavementDistortionsChecked,
  surfaceDeteriorationChecked:
    state.isolateDistresses.surfaceDeteriorationChecked,
  potholeChecked: state.isolateDistresses.potholeChecked,
  transLongChecked: state.isolateDistresses.transLongChecked,
});

const mapDispatchToProps = dispatch => ({
  playModal: id => dispatch(playModal(id)),
  stopModal: () => dispatch(stopModal()),
  showLayer: id => dispatch(showLayer(id)),
  noPointsModal: () => dispatch(noPointsModal()),
  dispatch,
});

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(VirtualDriverControls)
);
