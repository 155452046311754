import moment from "moment-timezone";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import rhumbDistance from "@turf/rhumb-distance";

import { parseTimestamp } from "./timestamp";
import { RB_CRACK, RB_SEAL } from "../constants/colors";

function segmentIDsMatch(feature1, feature2) {
  const segmentID1 = feature1.properties.segment;
  const segmentID2 = feature2.properties.segment;
  return segmentID1 === segmentID2;
}

function getTimestampSeconds(feature) {
  const date = moment.tz(
    parseTimestamp(feature.properties.timestamp),
    moment.tz.guess()
  );
  return parseInt(moment(date).format("x"), 10);
}

function bearingsCloseEnough(feature1, feature2) {
  // some older scans don't keep track of bearings on their points. In this case, just return true;
  if (!feature1.properties.bearing && !feature2.properties.bearing) {
    return true;
  }

  const bearingDifference = Math.abs(
    feature1.properties.bearing - feature2.properties.bearing
  );
  const angleDifference =
    bearingDifference <= 180 ? bearingDifference : 360 - bearingDifference;
  return angleDifference < 100;
}

function containsIsolateDistresses({
  potholeChecked,
  fatigueCrackingChecked,
  patchSealChecked,
  transLongChecked,
  surfaceDeteriorationChecked,
  pavementDistortionsChecked,
  featureDistresses,
}) {
  if (featureDistresses) {
    if (
      !potholeChecked &&
      !fatigueCrackingChecked &&
      !patchSealChecked &&
      !transLongChecked &&
      !surfaceDeteriorationChecked &&
      !pavementDistortionsChecked
    ) {
      return true;
    } else if (potholeChecked && featureDistresses.hasPothole) {
      return true;
    } else if (fatigueCrackingChecked && featureDistresses.hasFatigueCracking) {
      return true;
    } else if (patchSealChecked && featureDistresses.hasPatchSeal) {
      return true;
    } else if (transLongChecked && featureDistresses.hasTransLongCracking) {
      return true;
    } else if (
      surfaceDeteriorationChecked &&
      featureDistresses.hasSurfaceDeterioration
    ) {
      return true;
    } else if (
      pavementDistortionsChecked &&
      featureDistresses.hasPavementDistortions
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function isTimestampGreater(feature1, feature2) {
  if (feature1.properties.timestamp && feature2.properties.timestamp) {
    return getTimestampSeconds(feature1) > getTimestampSeconds(feature2);
  } else {
    return true;
  }
}

function isNewPointClosestToCurrentPoint(
  currentPoint,
  currentClosest,
  newPoint
) {
  const currentClosestDistance = rhumbDistance(
    currentPoint.geometry.coordinates,
    currentClosest.geometry.coordinates
  );
  const newDistance = rhumbDistance(
    currentPoint.geometry.coordinates,
    newPoint.geometry.coordinates
  );
  return newDistance < currentClosestDistance;
}

function getRightPoint({
  roadwayPointFeatures,
  feature,
  potholeChecked,
  fatigueCrackingChecked,
  patchSealChecked,
  transLongChecked,
  surfaceDeteriorationChecked,
  pavementDistortionsChecked,
}) {
  let rightPoint = null;
  roadwayPointFeatures.forEach(roadwayPointFeature => {
    let featureDistresses = {};
    if (roadwayPointFeature.properties.primaryDistresses) {
      featureDistresses = JSON.parse(
        roadwayPointFeature.properties.primaryDistresses
      );
    }
    if (
      segmentIDsMatch(roadwayPointFeature, feature) &&
      isTimestampGreater(roadwayPointFeature, feature) &&
      bearingsCloseEnough(roadwayPointFeature, feature) &&
      containsIsolateDistresses({
        potholeChecked,
        fatigueCrackingChecked,
        patchSealChecked,
        transLongChecked,
        surfaceDeteriorationChecked,
        pavementDistortionsChecked,
        featureDistresses,
      })
    ) {
      if (!rightPoint) {
        rightPoint = roadwayPointFeature;
      } else if (
        isNewPointClosestToCurrentPoint(
          feature,
          rightPoint,
          roadwayPointFeature
        )
      ) {
        rightPoint = roadwayPointFeature;
      }
    }
  });
  return rightPoint;
}

function getLeftPoint({
  roadwayPointFeatures,
  feature,
  potholeChecked,
  fatigueCrackingChecked,
  patchSealChecked,
  transLongChecked,
  surfaceDeteriorationChecked,
  pavementDistortionsChecked,
}) {
  let leftPoint = {};
  roadwayPointFeatures.forEach(roadwayPointFeature => {
    let featureDistresses = {};
    if (roadwayPointFeature.properties.primaryDistresses) {
      featureDistresses = JSON.parse(
        roadwayPointFeature.properties.primaryDistresses
      );
    }
    if (
      segmentIDsMatch(roadwayPointFeature, feature) &&
      isTimestampGreater(feature, roadwayPointFeature) &&
      bearingsCloseEnough(roadwayPointFeature, feature) &&
      containsIsolateDistresses({
        potholeChecked,
        fatigueCrackingChecked,
        patchSealChecked,
        transLongChecked,
        surfaceDeteriorationChecked,
        pavementDistortionsChecked,
        featureDistresses,
      })
    ) {
      if (isEmpty(leftPoint)) {
        leftPoint = roadwayPointFeature;
      } else if (
        isNewPointClosestToCurrentPoint(feature, leftPoint, roadwayPointFeature)
      ) {
        leftPoint = roadwayPointFeature;
      }
    }
  });
  return leftPoint;
}

export function getLeftAndRightPoints({
  feature,
  map,
  potholeChecked,
  fatigueCrackingChecked,
  patchSealChecked,
  transLongChecked,
  surfaceDeteriorationChecked,
  pavementDistortionsChecked,
}) {
  const allFeaturesOnScreen = map.queryRenderedFeatures();
  const roadwayPointFeatures = allFeaturesOnScreen.filter(
    feature =>
      get(feature.layer, "metadata.clickBehavior") &&
      feature.geometry.type === "Point"
  );

  const rightPoint = getRightPoint({
    roadwayPointFeatures,
    feature,
    potholeChecked,
    fatigueCrackingChecked,
    patchSealChecked,
    transLongChecked,
    surfaceDeteriorationChecked,
    pavementDistortionsChecked,
  });
  const leftPoint = getLeftPoint({
    roadwayPointFeatures,
    feature,
    potholeChecked,
    fatigueCrackingChecked,
    patchSealChecked,
    transLongChecked,
    surfaceDeteriorationChecked,
    pavementDistortionsChecked,
  });
  return [leftPoint, rightPoint];
}

export function centerMap({
  map,
  enlargedImg,
  sidebarVisibility,
  modalVisibility,
  lng,
  lat,
}) {
  // zoom into the feature and center on it before bringing up highlight
  if (map.getZoom() < 17) {
    map.setZoom(17);
  }

  // get widths to calculate the center of the shown map
  const point = map.project([lng, lat]);
  const sidebarWidth = document.getElementById("sidebarId").clientWidth;
  const modalSidebarWidth = document.getElementById("modalSidebarId")
    .clientWidth;
  const screenWidth = document.body.clientWidth;
  let offset = 0;
  if (modalVisibility && sidebarVisibility && !enlargedImg) {
    // both sidebars are open and no enlarged image
    offset = (screenWidth - modalSidebarWidth - sidebarWidth - 300) / 3;
  } else if (!sidebarVisibility && modalVisibility && !enlargedImg) {
    // right sidebar is open and no enlarged image
    offset = (screenWidth - modalSidebarWidth) / 3;
  } else if (!sidebarVisibility && enlargedImg) {
    // image is enlarged when left sidebar is closed
    offset = screenWidth / 3;
  } else if (sidebarVisibility && enlargedImg) {
    // image is enlarged with left sidebar open
    offset = (screenWidth - modalSidebarWidth) / 2;
  } else if (sidebarVisibility) {
    // default first when left sidebar is open
    offset = (screenWidth - modalSidebarWidth - sidebarWidth - 300) / 3;
  } else {
    // default first when left sidebar is closed
    offset = (screenWidth - modalSidebarWidth) / 3;
  }
  point.x += offset;
  const newPoint = map.unproject(point);
  map.flyTo({ center: [newPoint.lng, newPoint.lat] });
}

export function crackSealColorPicker(hasCracktoSeal, hasSealant) {
  if (hasCracktoSeal) {
    return RB_CRACK;
  } else if (hasSealant) {
    return RB_SEAL;
  }
}
