export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const SET_ACTIVE_ORGANIZATION = "SET_ACTIVE_ORGANIZATION";
export const SET_ACTIVE_SCAN = "SET_ACTIVE_SCAN";
export const SET_MEMBERSHIPS = "SET_MEMBERSHIPS";
export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const FETCH_ORGANIZATION_USERS = "FETCH_ORGANIZATION_USERS";
export const FETCH_ORGANIZATION_SCANS = "FETCH_ORGANIZATION_SCANS";
export const FETCH_ALL_ORGANIZATIONS = "FETCH_ALL_ORGANIZATIONS";
export const SET_MEASURING_SYSTEM = "SET_MEASURING_SYSTEM";
export const FETCH_ORGANIZATION_EMBEDDED_MAPS =
  "FETCH_ORGANIZATION_EMBEDDED_MAPS";
export const SET_HIDE_EXAMPLE_ORGANIZATIONS = "SET_HIDE_EXAMPLE_ORGANIZATIONS";
