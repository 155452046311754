import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "components/Sidebar";
import * as Sentry from "@sentry/browser";
import { isIPad } from "../utils/deviceCheck";
import { hideModal } from "../actions/modal";
import { closeSidebar, openSidebar } from "../actions/visibility";
import State from "../interfaces/state";
import { useTracking } from "react-tracking";

interface SidebarContainerProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
}

const SidebarContainer = ({
  header = null,
  footer = null,
  children = [],
}: SidebarContainerProps) => {
  const tracking = useTracking();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const open = useSelector(
    (state: State) => state.visibility.sidebarVisibility
  );

  const openSidebarModal = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "open-sidebar-visiblity",
      userUId: user.userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "open-sidebar-visiblity",
      level: Sentry.Severity.Info,
    });
    if (isIPad() && !open) {
      dispatch(hideModal());
    }
    dispatch(openSidebar());
  };

  const closeSidebarModal = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "close-sidebar-visiblity",
      userUId: user.userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "close-sidebar-visiblity",
      level: Sentry.Severity.Info,
    });
    if (isIPad() && !open) {
      dispatch(hideModal());
    }
    dispatch(closeSidebar());
  };

  return (
    <Sidebar
      open={open}
      header={header}
      footer={footer}
      openSidebar={openSidebarModal}
      closeSidebar={closeSidebarModal}
    >
      {children}
    </Sidebar>
  );
};

export default SidebarContainer;
