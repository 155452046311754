import { useSelector, useDispatch } from "react-redux";

import {
  fetchOrganizationUsers,
  fetchOrganizationScans,
  fetchOrganizationEmbeddedMaps,
} from "actions/userData";
import RBAPI from "api/RoadwayAPI";

const organizationDataConfig = {
  users: {
    data: "organizationUsers",
    action: fetchOrganizationUsers,
    api: orgID => RBAPI.getOrganizationUsers(orgID),
  },
  scans: {
    data: "organizationScans",
    action: fetchOrganizationScans,
    api: RBAPI.getOrganizationScans,
  },
  embeddedMaps: {
    data: "organizationEmbeddedMaps",
    action: fetchOrganizationEmbeddedMaps,
    api: orgID => RBAPI.getOrganizationEmbeddedMaps(orgID),
  },
};

const useLoadOrganizationData = (organizationId, organizationDataConfig) => {
  const dispatch = useDispatch();

  const { data, action, api } = organizationDataConfig;
  const organizationData = useSelector(({ userData }) => userData[data]);

  const fetchData = () => {
    dispatch(action(api(organizationId), organizationId));
  };

  const loadData = (forceLoad = false) => {
    if (forceLoad || organizationData[organizationId] === undefined) {
      fetchData();
    }
  };

  return loadData;
};

export const useLoadOrganizationUsersData = organizationId =>
  useLoadOrganizationData(organizationId, organizationDataConfig.users);

export const useLoadOrganizationScansData = organizationId =>
  useLoadOrganizationData(organizationId, organizationDataConfig.scans);

export const useLoadOrganizationEmbeddedMapsData = organizationId =>
  useLoadOrganizationData(organizationId, organizationDataConfig.embeddedMaps);
