import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import track from "react-tracking";
import PropTypes from "prop-types";

import OptionsMenu from "components/OptionsMenu";
import { clearAllArea } from "actions/measureArea";
import {
  clearAllMeasure,
  resetClearMeasureToggles,
} from "actions/measureDistance";
import { closeOptionsDropdown } from "../../actions/visibility";

import { ROUTE_SETTINGS } from "constants/routes";
import * as Sentry from "@sentry/browser/dist/index";
import { ROUTE_MAP } from "../../constants/routes";
import { setActiveScan } from "../../actions/userData";
import { resetModal } from "../../actions/modal";
import { setIsMapDataLoaded } from "../../actions/map";
import UserStatus from "../../constants/userStatus";
import { logoutOfFirebaseAndRedirect } from "config/firebase";
import RBAPI from "api/RoadwayAPI";

const OptionsMenuContainer = ({
  clearAllArea,
  clearAllMeasure,
  resetClearMeasureToggles,
  closeOptionsDropdown,
  tracking,
  user,
  history,
  activeScan,
  setIsMapDataLoaded,
  setActiveScan,
  resetModal,

  optionsDropdownVisibility,
  offset,
  isAdmin,
  memberships,
}) => {
  const toggleMenuVisibility = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "profile-preferences-page-click",
      userUId: user.userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "profile preferences page click",
      level: Sentry.Severity.Info,
    });
    clearAllArea();
    clearAllMeasure();
    resetClearMeasureToggles();
    closeOptionsDropdown();
    history.push(ROUTE_SETTINGS);
  };

  const clickAwayOptions = () => {
    closeOptionsDropdown();
  };

  const logout = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "logout-click",
      userUId: user.userUId,
    });
    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "logout-click",
      level: Sentry.Severity.Info,
    });
    RBAPI.logoutSession().then(resp => {
      // log out of firebase and redirect to auth
      const redirectURL = resp?.data?.accountsRedirectURL;
      logoutOfFirebaseAndRedirect(redirectURL);
    });
  };

  const getScanValue = scanOption => scanOption && scanOption.value;

  const handleSelectChange = selectedOption => {
    const formattedScanValue = getScanValue(selectedOption);
    if (activeScan !== formattedScanValue) {
      setActiveScan(formattedScanValue);
      resetModal();
      closeOptionsDropdown();
      tracking.trackEvent({
        event: "mouse-click",
        action: `scan-switcher-select-change-${formattedScanValue}`,
        userUId: user.userUId,
      });

      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `scan switcher select change ${formattedScanValue}`,
        level: Sentry.Severity.Info,
      });

      setIsMapDataLoaded(false);
      history.push(`${ROUTE_MAP}/${formattedScanValue}`);
    }
  };
  return optionsDropdownVisibility ? (
    <OptionsMenu
      handlePreferenceClick={toggleMenuVisibility}
      logout={logout}
      email={user.email}
      firstName={user.firstName}
      lastName={user.lastName}
      organization={memberships[0] ? memberships[0].organization : ""}
      clickAwayOptions={clickAwayOptions}
      offset={offset}
      isAdmin={isAdmin}
      handleSelectChange={handleSelectChange}
      userStatus={user.status}
    />
  ) : null;
};

OptionsMenuContainer.defaultProps = {
  user: {
    organization: null,
    firstName: null,
    lastName: null,
    email: null,
    userUId: null,
    status: UserStatus.DISABLED,
  },
  history: {
    push: () => null,
  },
  offset: 0,
  memberships: {},
};

OptionsMenuContainer.propTypes = {
  clearAllArea: PropTypes.func.isRequired,
  clearAllMeasure: PropTypes.func.isRequired,
  resetClearMeasureToggles: PropTypes.func.isRequired,
  closeOptionsDropdown: PropTypes.func.isRequired,
  tracking: PropTypes.shape({
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  user: PropTypes.shape({
    organization: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    userUId: PropTypes.string,
    status: PropTypes.string,
  }),
  optionsDropdownVisibility: PropTypes.bool.isRequired,
  offset: PropTypes.number,
  isAdmin: PropTypes.bool.isRequired,
  memberships: PropTypes.array,
  activeScan: PropTypes.string.isRequired,
  setIsMapDataLoaded: PropTypes.func.isRequired,
  setActiveScan: PropTypes.func.isRequired,
  resetModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  optionsDropdownVisibility: state.visibility.optionsDropdownVisibility,
  isAdmin: state.userData.isAdmin,
  memberships: state.userData.memberships,
  activeScan: state.userData.activeScan,
});

const mapDispatchToProps = dispatch => ({
  clearAllArea: () => dispatch(clearAllArea()),
  clearAllMeasure: () => dispatch(clearAllMeasure()),
  resetClearMeasureToggles: () => dispatch(resetClearMeasureToggles()),
  closeOptionsDropdown: () => dispatch(closeOptionsDropdown()),
  setActiveScan: activeScan => dispatch(setActiveScan(activeScan)),
  resetModal: () => dispatch(resetModal),
  setIsMapDataLoaded: value => dispatch(setIsMapDataLoaded(value)),
});

const trackedOptionsMenuContainer = track()(
  connect(mapStateToProps, mapDispatchToProps)(OptionsMenuContainer)
);

export default withRouter(trackedOptionsMenuContainer);
