import produce from "immer";
import {
  ADD_LAYER,
  HIDE_LAYER,
  SHOW_LAYER,
  TOGGLE_LAYER,
  CLEAR_LAYER_GROUPS,
  ADD_LAYER_GROUP,
} from "actiontypes/layers";

let layerGroupId = 0;

const defaultState = {
  groups: [],
};

function modifyLayerVisibility(state, layerId, type) {
  return produce(state, draft => {
    const layerGroupWithLayerId = draft.groups.find(layerGroup =>
      layerGroup.layers.find(layer => layer.id === layerId)
    );
    const layer = layerGroupWithLayerId.layers.find(
      layer => layer.id === layerId
    );
    layer.visible =
      type === "toggle" ? !layer.visible : type === "show" ? true : false;
  });
}

const layers = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_LAYER_GROUP:
      return produce(state, draft => {
        draft.groups.push({
          projectName: action.projectName,
          name: action.name,
          id: layerGroupId++,
          style: action.style,
          clickBehavior: action.clickBehavior,
          position: action.position,
          layers: [],
        });
      });
    case ADD_LAYER:
      return produce(state, draft => {
        const layerGroup = draft.groups.find(
          layerGroup => layerGroup.id === action.layerGroupId
        );
        layerGroup.layers.push({
          id: action.id,
          url: action.url,
          color: action.color,
          position: action.position,
          hidden: action.hidden,
          visible: action.visible,
          sourceLayer: action.sourceLayer,
          dateCreated: action.dateCreated,
          dateDriven: action.dateDriven,
        });
      });
    case TOGGLE_LAYER:
      return modifyLayerVisibility(state, action.id, "toggle");
    case SHOW_LAYER:
      return modifyLayerVisibility(state, action.id, "show");
    case HIDE_LAYER:
      return modifyLayerVisibility(state, action.id, "hide");
    case CLEAR_LAYER_GROUPS:
      return defaultState;
    default:
      return state;
  }
};

export default layers;
