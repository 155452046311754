import React from "react";
import Button from "components/form/Button";
import { determineBackgroundColor } from "utils/determineColor";
import { getLengthInPreferredUnit } from "../../utils/metrics";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_DARK_GREY, RB_RED } from "../../constants/colors";
import Typography from "@material-ui/core/Typography";
import { sidewalkMap, RAIToRoadwayRating } from "utils/sidewalkHelperFunctions";
import AnalysisViewSkeleton from "skeletons/analysisView";
import { MeasuringSystem } from "constants/measuringSystem";

const useStyles = makeStyles({
  analysisButton: {
    fontFamily: "'Open Sans', sans-serif",
    marginLeft: "4px",
    marginRight: "4px",
  },
  analysisDataRoot: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 16px",
    textAlign: "center",
  },
  analysisMetrics: {
    display: "flex",
    padding: "5px 0 8px",
    color: "white",
    flexWrap: "wrap",
  },
  analysisMetricScore: score => ({
    backgroundColor: determineBackgroundColor(score),
    padding: "10px 5px 6px",
    borderRadius: "4px",
    marginTop: "8px",
    marginLeft: "4px",
    marginRight: "4px",
    flex: "1",
  }),
  analysisMetricLength: {
    backgroundColor: RB_DARK_GREY,
    padding: "10px 5px 6px",
    borderRadius: "4px",
    marginTop: "8px",
    marginLeft: "4px",
    marginRight: "4px",
    flex: "1",
  },
  analysisPotholeCount: {
    backgroundColor: RB_RED,
    padding: "10px 5px 6px",
    borderRadius: "4px",
    marginTop: "8px",
    marginLeft: "4px",
    marginRight: "4px",
    flex: "1",
  },
  analysisMetricNumber: {
    lineHeight: "1",
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 700,
  },
  analysisMetricLabel: {
    lineHeight: "0.8",
    fontSize: "10px",
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "nowrap",
  },
});

interface AnalysisViewInterface {
  type: string;
  isLoaded: boolean;
  score: number;
  length: number;
  potholeCount?: number;
  handleClickAnalysisButton: () => {};
  measuringSystem: MeasuringSystem;
}

const AnalysisView = ({
  type,
  isLoaded = false,
  score,
  length,
  potholeCount,
  handleClickAnalysisButton,
  measuringSystem,
}: AnalysisViewInterface): React.ReactElement => {
  const classes = useStyles(score);
  return (
    <div>
      {!isLoaded ? (
        <AnalysisViewSkeleton nBlocksTop={2} nBlocksBottom={1} />
      ) : (
        score && (
          <section className={classes.analysisDataRoot}>
            <div className={classes.analysisMetrics}>
              <div className={classes.analysisMetricScore}>
                <Typography
                  variant="h4"
                  className={classes.analysisMetricNumber}
                >
                  {type.toLowerCase() === "sidewalk"
                    ? sidewalkMap[
                        RAIToRoadwayRating(parseInt(score.toFixed(0)))
                      ]
                    : score.toFixed(2)}
                </Typography>
                <Typography
                  variant="caption"
                  className={classes.analysisMetricLabel}
                >
                  {type.toLowerCase() === "sidewalk"
                    ? "Network Accessibility"
                    : "Network Score"}
                </Typography>
              </div>

              {length && (
                <div className={classes.analysisMetricLength}>
                  <Typography
                    variant="h4"
                    className={classes.analysisMetricNumber}
                  >
                    {
                      getLengthInPreferredUnit(
                        length,
                        measuringSystem,
                        "large",
                        0
                      ).scalar
                    }
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.analysisMetricLabel}
                  >
                    {measuringSystem === MeasuringSystem.Imperial
                      ? `${type} Miles`
                      : `${type} Kilometers`}
                  </Typography>
                </div>
              )}

              {potholeCount && (
                <div className={classes.analysisPotholeCount}>
                  <Typography
                    variant="h4"
                    className={classes.analysisMetricNumber}
                  >
                    {potholeCount}
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.analysisMetricLabel}
                  >
                    Potholes
                  </Typography>
                </div>
              )}
            </div>
            {type.toLowerCase() !== "sidewalk" && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickAnalysisButton}
                className={classes.analysisButton}
              >
                View Road Report Analysis
              </Button>
            )}
          </section>
        )
      )}
    </div>
  );
};

export default AnalysisView;
