import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import track from "react-tracking";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "components/form/Button";
import AddIcon from "@material-ui/icons/Add";

import useMount from "hooks/useMount";

import { fetchAllOrganizations, fetchOrganizations } from "actions/userData";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import { RB_YELLOW } from "constants/colors";

import OrganizationsAdmin from "components/admin/OrganizationsAdmin";
import OrganizationsAdminUpdateFormDialog from "./OrganizationsAdminUpdateFormDialog";
import * as Sentry from "@sentry/browser/dist/index";
import { excludeExampleOrganizations } from "../../utils/excludeExampleOrganizations";
import RBAPI from "api/RoadwayAPI";
import { getAssessmentType } from "../../utils/getAssessmentType";

const useStyles = makeStyles({
  button: { backgroundColor: RB_YELLOW, textTransform: "none" },
});

const OrganizationsAdminContainer = props => {
  const {
    allOrganizations,
    fetchAllOrganizations,
    fetchOrganizations,
    isAllOrganizationsDataLoaded,
    setSnackbarMessageAndOpen,
    tracking,
    userUId,
    openDeletePopup,
    isDeleteDialogOpen,
  } = props;
  const [isUpdateFormOpen, setIsUpdateFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isOrganizationUpdateActive, setIsOrganizationUpdateActive] = useState(
    false
  );
  const newOrganiztion = {
    name: "",
    parentOrganizationId: "",
  };
  const filteredOrganizations = excludeExampleOrganizations(allOrganizations);

  const [formValues, setFormValues] = useState(newOrganiztion);

  const handleCloseUpdateForm = () => setIsUpdateFormOpen(false);

  const loadAllOrganizations = useCallback(() => {
    // fetch all organizations to reload the organizations admin data
    fetchAllOrganizations(
      RBAPI.getOrganizations().catch(e => console.error(e))
    );
    // fetch organizations by user to reload the dashboard page data
    fetchOrganizations(
      RBAPI.fetchScansByUser(userUId, getAssessmentType()).catch(err => {
        if (err?.response?.status === 404) {
          setSnackbarMessageAndOpen(
            "Could not find assessments",
            MessageTypes.ERROR
          );
          return;
        }
        throw err;
      })
    );
  }, [
    fetchAllOrganizations,
    fetchOrganizations,
    setSnackbarMessageAndOpen,
    userUId,
  ]);

  const onMount = () => {
    if (!isAllOrganizationsDataLoaded) {
      loadAllOrganizations();
    }
  };

  const handleError = (e, message) => {
    setSnackbarMessageAndOpen(message, MessageTypes.ERROR);
    throw e;
  };

  const handleUpdate = async selectedOrganization => {
    const statusMessage = isEditing ? "Edit saved" : "Created new organization";
    try {
      setIsOrganizationUpdateActive(true);
      if (isEditing) {
        await RBAPI.updateOrganization(selectedOrganization);
      } else {
        await RBAPI.createOrganization(selectedOrganization);
      }
      setSnackbarMessageAndOpen(statusMessage);
      loadAllOrganizations();
    } catch (e) {
      const statusMessage = isEditing
        ? "Failed to save the edits"
        : "Failed to create new organization";
      handleError(e, statusMessage);
    } finally {
      setIsOrganizationUpdateActive(false);
      setIsUpdateFormOpen(false);
      setIsEditing(false);
    }
  };

  const handleClickAdd = () => {
    setIsEditing(false);
    setFormValues(newOrganiztion);
    setIsUpdateFormOpen(true);
    tracking.trackEvent({
      event: "mouse-click",
      action: "admin-add-organization",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `admin add organization`,
      level: Sentry.Severity.Info,
    });
  };

  const handleEdit = selectedOrganization => {
    setIsEditing(true);
    setFormValues(selectedOrganization);
    setIsUpdateFormOpen(true);
    tracking.trackEvent({
      event: "mouse-click",
      action: `admin-edit-organization-${selectedOrganization.id}`,
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `admin-edit-organization-${selectedOrganization.id}`,
      level: Sentry.Severity.Info,
    });
  };

  useMount(onMount);

  const classes = useStyles();

  useEffect(() => {
    return () => {
      if (isDeleteDialogOpen) {
        loadAllOrganizations();
      }
    };
  }, [isDeleteDialogOpen, loadAllOrganizations]);

  return (
    <div>
      <Button
        className={classes.button}
        onClick={handleClickAdd}
        variant="contained"
        color="primary"
        capitalizeLabel={false}
      >
        <AddIcon />
        Add New Organization
      </Button>
      <OrganizationsAdmin
        isDataLoading={!isAllOrganizationsDataLoaded}
        organizations={filteredOrganizations}
        handleEdit={handleEdit}
        handleDelete={openDeletePopup}
      />
      {isUpdateFormOpen && (
        <OrganizationsAdminUpdateFormDialog
          classes={classes}
          handleUpdate={handleUpdate}
          organizations={filteredOrganizations}
          isOpen
          handleClose={handleCloseUpdateForm}
          isUpdateActive={isOrganizationUpdateActive}
          values={formValues}
          title={isEditing ? "Edit Organization" : "Add New Organization"}
          userUId={userUId}
        />
      )}
    </div>
  );
};

OrganizationsAdminContainer.defaultProps = {};

OrganizationsAdminContainer.propTypes = {
  allOrganizations: PropTypes.array.isRequired,
  fetchAllOrganizations: PropTypes.func.isRequired,
  isAllOrganizationsDataLoaded: PropTypes.bool.isRequired,
  setSnackbarMessageAndOpen: PropTypes.func.isRequired,
  tracking: PropTypes.object.isRequired,
  userUId: PropTypes.string.isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  openDeletePopup: PropTypes.func.isRequired,
  isDeleteDialogOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ userData, user }) => ({
  allOrganizations: userData.allOrganizations,
  isAllOrganizationsDataLoaded: userData.isAllOrganizationsDataLoaded,
  userUId: user.userUId,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSnackbarMessageAndOpen,
      fetchAllOrganizations,
      fetchOrganizations,
    },
    dispatch
  );

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsAdminContainer)
);
