import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";

const useStyles = makeStyles({
  compass: {
    background: RB_LIGHT_GREY,
    margin: "5px 20px ",
  },
});

const CompassSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Skeleton
      className={classes.compass}
      variant="circle"
      width={70}
      height={70}
    />
  );
};

export default CompassSkeleton;
