import {
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
  CLOSE_DROPDOWN,
  OPEN_DROPDOWN,
  CLOSE_NOTIFICATIONS,
  OPEN_NOTIFICATIONS,
  OPEN_TOP_BANNER,
  CLOSE_TOP_BANNER,
} from "../actiontypes/visibility";

interface ActionReturn {
  type: string;
}

export function closeSidebar(): ActionReturn {
  return {
    type: CLOSE_SIDEBAR,
  };
}

export function openSidebar(): ActionReturn {
  return {
    type: OPEN_SIDEBAR,
  };
}

export function closeNotificationDropdown(): ActionReturn {
  return {
    type: CLOSE_NOTIFICATIONS,
  };
}

export function openNotificationDropdown(): ActionReturn {
  return {
    type: OPEN_NOTIFICATIONS,
  };
}

export function closeOptionsDropdown(): ActionReturn {
  return {
    type: CLOSE_DROPDOWN,
  };
}

export function openOptionsDropdown(): ActionReturn {
  return {
    type: OPEN_DROPDOWN,
  };
}

export function openTopBanner(): ActionReturn {
  return {
    type: OPEN_TOP_BANNER,
  };
}

export function closeTopBanner(): ActionReturn {
  return {
    type: CLOSE_TOP_BANNER,
  };
}
