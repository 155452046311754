import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { ROUTE_LOGIN } from "constants/routes";
import SideBar from "roadbotics-os-sidebar";
import firebase from "config/firebase";

const PrivateRoute = ({
  component: Component,
  userUId,
  user,
  accessAllowed,
  allowFrom = true,
  ...rest
}) => {
  const renderPublicRoute = () => {
    return (
      <Route
        {...rest}
        render={props =>
          accessAllowed ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: ROUTE_LOGIN,
                // eslint-disable-next-line react/prop-types
                state: { from: allowFrom ? props.location : null },
              }}
            />
          )
        }
      />
    );
  };

  // TODO remove check when we release to staging and production
  return (
    <div>
      {process.env.REACT_APP_PROJECT_ID === "roadbotics-roadway-api-dev" ? (
        <SideBar
          userUId={userUId}
          apiBase={process.env.REACT_APP_ROADWAY_API_BASE}
          accountsDomain={`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/login`}
          firebase={firebase}
        >
          {renderPublicRoute()}
        </SideBar>
      ) : (
        renderPublicRoute()
      )}
    </div>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  accessAllowed: PropTypes.bool.isRequired,
  user: PropTypes.string,
  allowFrom: PropTypes.bool,
};

export default PrivateRoute;
