import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import CreateProfileForm from "../components/CreateProfileForm";
import Snackbar from "./Snackbar";
import { ROUTE_IMAGE_LOGGER_SIGN_UP_SUCCESS } from "../constants/routes";
import RBAPI from "api/RoadwayAPI";
import { submitHubSpotForm } from "../utils/hubspot";
import { IMAGELOGGER_FORM_ID } from "../constants/hubspot";

export type ImageLoggerSignUpContainerFormData = {
  email: string;
  organizationName: string;
  firstName: string;
  lastName: string;
  password: string;
  verifyPassword: string;
  numberOfMiles: number;
};

const ImageLoggerSignUpContainer: React.FC = () => {
  const { register, handleSubmit, watch, errors } = useForm<
    ImageLoggerSignUpContainerFormData
  >();

  const location = useLocation();
  const history = useHistory();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleSubmit(
      async ({ email, organizationName, firstName, lastName, password }) => {
        setFormSubmitted(true);

        try {
          if (process.env.NODE_ENV === "production") {
            await submitHubSpotForm(
              firstName,
              lastName,
              email,
              process.env.REACT_APP_ROADWAY_URL + "/" + location.pathname,
              IMAGELOGGER_FORM_ID
            );
          }
        } catch (e) {
          console.error(e);
        }

        try {
          await RBAPI.createImageLogger({
            email,
            organizationName,
            firstName,
            lastName,
            password,
          });
          history.push(ROUTE_IMAGE_LOGGER_SIGN_UP_SUCCESS);
        } catch (e) {
          console.error(e);
          setFormSubmitted(false);
        }
      }
    )(event);
  };

  return (
    <React.Fragment>
      <CreateProfileForm
        title={"RoadWay Image Logger Sign Up"}
        register={register}
        watch={watch}
        errors={errors}
        onSubmit={onSubmit}
        location={location}
        formSubmitted={formSubmitted}
      />
      <Snackbar />
    </React.Fragment>
  );
};

export default ImageLoggerSignUpContainer;
