import {
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_YELLOW,
  RB_ORANGE,
  RB_RED,
} from "constants/colors";

export const determineBackgroundColor = value => {
  if (value < 1.5) {
    return RB_GREEN;
  } else if (value >= 1.5 && value < 2.5) {
    return RB_LIGHT_GREEN;
  } else if (value >= 2.5 && value < 3.5) {
    return RB_YELLOW;
  } else if (value >= 3.5 && value < 4.5) {
    return RB_ORANGE;
  } else if (value >= 4.5) {
    return RB_RED;
  }
};
