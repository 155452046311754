import React, { useState } from "react";
import MaterialTable, { Icons, Action } from "material-table";
import Button from "components/form/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownRounded from "@material-ui/icons/KeyboardArrowDownRounded";
import { TABLE_HEADER_BACKGROUND, TABLE_HEADER_TEXT } from "constants/colors";
import styleTableRow from "utils/styleTableRow";
import omit from "lodash/omit";
import Users from "interfaces/user";
import TableSkeleton from "skeletons/table";
import UserStatus from "../../constants/userStatus";
import InviteStatus from "../InviteStatus";

interface OrganizationUsersInterface {
  organizationName: string;
  organizationUsers: Users[];
  isUsersLoaded: boolean;
  isSendInviteActive: boolean;
  handleDelete: (data: Users[]) => void;
  handleEdit: (data: Users[]) => void;
  handleClickInvite: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  currentUserId: string;
  handleInvite: (data: Users) => void;
  invitingEmail: string;
}

const OrganizationUsers: React.FC<OrganizationUsersInterface> = ({
  organizationName = "",
  organizationUsers = [],
  isUsersLoaded,
  handleDelete,
  handleEdit,
  handleClickInvite,
  currentUserId = null,
  handleInvite,
  isSendInviteActive,
  invitingEmail,
}) => {
  const [pageSize, setPageSize] = useState(5);

  const renderInviteStatus = (rowData: Users): React.ReactElement => {
    return rowData.status !== UserStatus.ACTIVE ? (
      <InviteStatus
        handleInvite={handleInvite}
        invitingEmail={invitingEmail}
        isSendInviteActive={isSendInviteActive}
        rowData={rowData}
      />
    ) : (
      <span>{UserStatus.ACTIVE}</span>
    );
  };

  return (
    <div>
      <Button
        onClick={handleClickInvite}
        disabled={!isUsersLoaded}
        variant="contained"
        color="primary"
        capitalizeLabel={false}
      >
        <AddIcon />
        {`Invite User to ${organizationName}`}
      </Button>
      <MaterialTable
        isLoading={!isUsersLoaded}
        style={{ boxShadow: "none" }}
        onChangeRowsPerPage={setPageSize}
        options={{
          pageSizeOptions: [5, 10, 20, 50],
          pageSize,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: TABLE_HEADER_BACKGROUND,
            color: TABLE_HEADER_TEXT,
            fontWeight: "bold",
            fontSize: "14px",
          },
          rowStyle: styleTableRow,
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit User",
            onClick: (_event, rowData): void => {
              handleEdit(omit(rowData, ["tableData"]) as Users[]); // rm tableData from MatTable
            },
          },
          (rowData: Users): Action<Users> => ({
            hidden: rowData.id === currentUserId,
            icon: "delete",
            tooltip: "Delete User",
            onClick: (_event, rowData): void => {
              handleDelete(omit(rowData, ["tableData"]) as Users[]); // rm tableData from MatTable
            },
          }),
        ]}
        title={`${organizationName} Users`}
        columns={[
          { title: "First Name", field: "firstName" },
          { title: "Last Name", field: "lastName" },
          { title: "Email", field: "email" },
          {
            title: "Role",
            field: "role",
          },
          {
            title: "Last Sign In",
            field: "lastLogin",
          },
          {
            title: "Status",
            field: "status",
            render: (rowData: Users): React.ReactNode =>
              renderInviteStatus(rowData),
          },
        ]}
        data={organizationUsers}
        icons={{ SortArrow: KeyboardArrowDownRounded } as Icons}
        localization={{
          header: { actions: "" },
          body: {
            emptyDataSourceMessage: ((isUsersLoaded ? (
              <Typography>
                No records to display. Contact RoadBotics if this is incorrect
              </Typography>
            ) : (
              <TableSkeleton nIcons={2} />
            )) as unknown) as string,
          },
        }}
      />
    </div>
  );
};

export default OrganizationUsers;
