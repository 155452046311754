import Rating1 from "images/ratings/Rating1.png";
import Rating2 from "images/ratings/Rating2.png";
import Rating3 from "images/ratings/Rating3.png";
import Rating4 from "images/ratings/Rating4.png";
import Rating5 from "images/ratings/Rating5.png";
import RatingNP from "images/ratings/RatingNP.png";
import Candidate from "images/ratings/Candidate.png";
import Sealed from "images/ratings/Sealed.png";

export function determineRatingSign(rating) {
  switch (rating) {
    case 1:
      return Rating1;
    case 2:
      return Rating2;
    case 3:
      return Rating3;
    case 4:
      return Rating4;
    case 5:
      return Rating5;
    default:
      return RatingNP;
  }
}

export function displayAverageRating(averageRating) {
  return averageRating
    ? parseFloat(averageRating).toFixed(2)
    : "N/A (Not Paved)";
}

export function determineCrackSealSign(csDistresses) {
  if (csDistresses.hasCracktoSeal) {
    return Candidate;
  } else {
    return Sealed;
  }
}
