import React from "react";
import PropTypes from "prop-types";
import LineModalContent from "components/LineModalContent";
import MaterialTable from "material-table";
import { TABLE_HEADER_BACKGROUND, TABLE_HEADER_TEXT } from "constants/colors";
import styleTableRow from "utils/styleTableRow";
import KeyboardArrowDownRounded from "@material-ui/icons/KeyboardArrowDownRounded";
import { calculateAverageRating, calculateTotalLength } from "utils/segments";
import { getLengthInPreferredUnit } from "utils/metrics";
import { displayAverageRating } from "utils/ratings";
import makeSegment from "utils/makeSegment";
import { MeasuringSystem } from "constants/measuringSystem";

const ModalDynamicSegmentation = ({
  segmentsData,
  measuringSystem,
  segments,
  colorPicker,
}) => {
  const dynamicSegmentationContent = segmentsData.content;
  const streets = {};
  segments.forEach(segmentFeature => {
    const segmentStreetName = makeSegment(segmentFeature).name;
    if (!(segmentStreetName in streets)) {
      streets[segmentStreetName] = {
        segmentFeatures: [segmentFeature],
      };
    } else {
      streets[segmentStreetName].segmentFeatures.push(segmentFeature);
    }
  });
  const streetsList = Object.entries(streets).map(street => {
    const [name, streetObj] = street;
    const { segmentFeatures } = streetObj;
    const totalLength = calculateTotalLength(segmentFeatures);
    const averageRating = calculateAverageRating(segmentFeatures);
    return {
      name,
      totalLength: getLengthInPreferredUnit(
        totalLength, //assumed to be metric meters at this point
        measuringSystem,
        "small"
      ).scalar,
      averageRating: displayAverageRating(averageRating),
    };
  });

  return (
    <React.Fragment>
      <LineModalContent
        chartData={dynamicSegmentationContent.chartData}
        averageRating={dynamicSegmentationContent.averageRating}
        length={dynamicSegmentationContent.length}
        colorPicker={colorPicker}
      />
      <MaterialTable
        style={{ boxShadow: "none" }}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: TABLE_HEADER_BACKGROUND,
            color: TABLE_HEADER_TEXT,
            fontWeight: "bold",
            fontSize: "14px",
          },
          rowStyle: styleTableRow,
          search: false,
          pageSize: streetsList.length,
          paging: false,
          toolbar: false,
        }}
        columns={[
          {
            title: "Street Name",
            customSort: (a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            // eslint-disable-next-line react/display-name
            render: rowData => (
              <div>
                {rowData.name !== "null" ? rowData.name : <i>Unknown Name</i>}
              </div>
            ),
          },
          {
            title: `Length (${
              measuringSystem === MeasuringSystem.Imperial ? "ft" : "m"
            })`,
            field: "totalLength",
          },
          {
            title: "Avg Score",
            field: "averageRating",
          },
        ]}
        data={streetsList}
        icons={{ SortArrow: KeyboardArrowDownRounded }}
      />
    </React.Fragment>
  );
};

export default ModalDynamicSegmentation;

ModalDynamicSegmentation.propTypes = {
  segmentsData: PropTypes.object.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  segments: PropTypes.array.isRequired,
  colorPicker: PropTypes.object.isRequired,
};
