import pluralize from "pluralize";
import times from "lodash/times";
import get from "lodash/get";
import * as damageRatings from "constants/damageRatings";
import {
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_YELLOW,
  RB_ORANGE,
  RB_RED,
  RB_GREY,
  RB_CRACK,
  RB_SEAL,
} from "constants/colors";

const COLORS = [
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_YELLOW, // This was ffffbf
  RB_ORANGE, // This was fdae61
  RB_RED,
  RB_GREY,
  RB_CRACK,
  RB_SEAL,
];
const LAYER_ORDER = ["segment", "point", "crackseal", "pothole"];
const LAYER_COUNT = {
  segment: 6,
  point: 6,
  crackseal: 2,
};

const mungey2 = {
  startMunge(layers) {
    return {
      data: this.buildData(layers),
      layout: this.buildLayout(layers),
    };
  },
  getColor(level) {
    return COLORS[level - 1];
  },
  buildData(layers) {
    return {
      project: {
        dataSet: {
          layerGroups: this.buildLayerGroups(layers),
        },
      },
    };
  },
  getLayerTypes(layers) {
    const layerTypes = layers.reduce((a, b) => {
      const type = b.type;
      if (a.indexOf(type) === -1) {
        a.push(type);
      }
      return a;
    }, []);
    return layerTypes.sort((a, b) => {
      return (
        (LAYER_ORDER.indexOf(a) !== -1
          ? LAYER_ORDER.indexOf(a)
          : LAYER_ORDER.length) -
        (LAYER_ORDER.indexOf(b) !== -1
          ? LAYER_ORDER.indexOf(b)
          : LAYER_ORDER.length)
      );
    });
  },
  buildLayerGroups(layers) {
    const layerTypes = this.getLayerTypes(layers);
    return layerTypes.reduce((layerGroups, layerType) => {
      const capped = layerType.charAt(0).toUpperCase() + layerType.slice(1);
      const layerGroup = {
        clickBehavior: `roadway${capped}`,
        layers: this.buildLayers(
          layers.filter(layer => layer.type === layerType)
        ),
        name: `Layer ${layerType}`,
        style: `roadway${capped}s`,
        position: layerTypes.indexOf(layerType),
      };
      layerGroups.push(layerGroup);
      return layerGroups;
    }, []);
  },
  buildLayers(layers) {
    return layers.map(layer => {
      const level = parseInt(layer.level, 10);
      return {
        sourceLayer: layer.layerSource,
        url: layer.uploadId,
        color: this.getColor(level),
        position: level,
        hidden: false,
        dateCreated: layer.dateCreated,
        dateDriven: layer.dateDriven,
      };
    });
  },
  buildLayout(layers) {
    return {
      project: {
        dataSet: {
          sidebar: this.buildSidebar(layers),
        },
      },
    };
  },
  buildSidebar(layers) {
    let tabs = {
      ratings_layer: true,
    };
    const crackSealLayers = layers.find(layer => layer.type === "crackseal");
    if (crackSealLayers && Object.entries(crackSealLayers).length > 0) {
      tabs = {
        ratings_layer: true,
        crack_seal: true,
      };
    }
    return {
      tabs,
      toggleGroups: this.buildToggleGroups(layers),
    };
  },
  buildToggles(layers, layerType, cracksealOffset) {
    const existingLayers = layers.map(layer => {
      const level = parseInt(layer.level, 10);
      const displayName = this.getDisplayName(layer.level);

      return {
        layerId: layer.layerSource,
        position: level,
        toggleable: true,
        color: this.getColor(level),
        displayName,
      };
    });
    if (
      layerType === "segment" ||
      layerType === "point" ||
      layerType === "crackseal"
      // layerType === "sidewalkSegment" ||
      // layerType === "sidewalkPoint"
    ) {
      return times(get(LAYER_COUNT, layers[0].type, layers.length)).map(idx => {
        const position = idx + 1 + cracksealOffset;
        let existingLayer = existingLayers.find(
          layer => position === layer.position
        );
        if (!existingLayer) {
          existingLayer = {
            layerId: "missing",
            position,
            toggleable: false,
            color: RB_GREY,
            displayName: this.getDisplayName(position),
          };
        }
        return existingLayer;
      });
    } else {
      return existingLayers;
    }
  },
  buildToggleGroups(layers) {
    const layerTypes = this.getLayerTypes(layers);
    return layerTypes.map((layerType, idx) => {
      const pluralName = pluralize(layerType);
      const filteredLayers = layers.filter(layer => layer.type === layerType);
      const cracksealOffset = layerType === "crackseal" ? 6 : 0;
      return {
        name: pluralName,
        displayName: pluralName.replace(/([A-Z])/g, " $1"),
        position: idx,
        toggleAll: true,
        style: "roadwayToggleAll",
        toggles: this.buildToggles(filteredLayers, layerType, cracksealOffset),
      };
    });
  },
  getDisplayName(level) {
    const displayName = damageRatings[`LEVEL_${level}`];
    if (displayName) {
      return displayName;
    }
    return level;
  },
};

export default mungey2;
