import get from "lodash/get";
import { getLeftAndRightPoints } from "../pointsUtils";
import * as Sentry from "@sentry/browser/dist/index";
import { styleSignTypes } from "../../constants/signs";
import roadwaySegment from "./roadwaySegment";
import roadwayPoint from "./roadwayPoint";
import sidewalkSegment from "./sidewalkSegment";
import sidewalkPoint from "./sidewalkPoint";
import roadwayPartnerLayer from "./roadwayPartnerLayer";
import imageLoggerPoint from "./imageLoggerPoint";
import { clickBehaviorConstants } from "constants/clickBehavior";

const clickBehavior = {
  mapClick({
    map,
    event,
    dispatch,
    colorPicker,
    trackEvent,
    modalVisibility,
    sidebarVisibility,
    enlargedImg,
    potholeChecked,
    fatigueCrackingChecked,
    patchSealChecked,
    transLongChecked,
    surfaceDeteriorationChecked,
    pavementDistortionsChecked,
  }) {
    // adds 5 pixel radius to click point for better layer selection
    // modify the bounding box to add a larger radius if zoomed in more (do this later)
    const bbox = [
      [event.point.x - 5, event.point.y - 5],
      [event.point.x + 5, event.point.y + 5],
    ];
    const features = map.queryRenderedFeatures(bbox);
    const feature = features.find(feat =>
      get(feat.layer, "metadata.clickBehavior")
    );

    if (feature) {
      if (styleSignTypes.includes(feature.layer.metadata.clickBehavior)) {
        roadwayPartnerLayer({
          feature,
          event,
          dispatch,
          colorPicker,
          trackEvent,
          modalVisibility,
          sidebarVisibility,
          map,
        });
      } else {
        let leftAndRightPoints = null;
        // this conditional is used to run the left and right points get
        // only on a point feature
        // It removes the unnecessary running of it on segment clicks
        // All future point types should be added to this array.
        if (
          [
            clickBehaviorConstants.roadwayPoint,
            clickBehaviorConstants.roadwayPartnerLayer,
            clickBehaviorConstants.roadwayCrackseal,
            clickBehaviorConstants.roadwayPothole,
            clickBehaviorConstants.roadwaySidewalkPoint,
            clickBehaviorConstants.roadwayImageLoggerPoint,
          ].includes(feature.layer.metadata.clickBehavior)
        ) {
          leftAndRightPoints = getLeftAndRightPoints({
            feature,
            map,
            potholeChecked,
            fatigueCrackingChecked,
            patchSealChecked,
            transLongChecked,
            surfaceDeteriorationChecked,
            pavementDistortionsChecked,
          });
        }
        clickBehavior.layerClick({
          feature,
          event,
          dispatch,
          colorPicker,
          trackEvent,
          leftAndRightPoints,
          modalVisibility,
          sidebarVisibility,
          enlargedImg,
          map,
        });
      }
    } else {
      trackEvent({ event: "mouse-click", action: "map-click" });
      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `Map Click`,
        level: Sentry.Severity.Info,
      });
    }
  },

  layerClick: ({
    feature,
    event,
    dispatch,
    responsive,
    colorPicker,
    trackEvent,
    leftAndRightPoints,
    modalVisibility,
    sidebarVisibility,
    enlargedImg,
    map,
  }) => {
    switch (feature.layer.metadata.clickBehavior) {
      case clickBehaviorConstants.roadwaySegment:
        trackEvent({ event: "mouse-click", action: "map-click-segment" });
        Sentry.addBreadcrumb({
          category: "mouse-click",
          message: "map click segment",
          level: Sentry.Severity.Info,
        });
        return roadwaySegment({
          feature,
          dispatch,
          colorPicker,
          modalVisibility,
          sidebarVisibility,
          enlargedImg,
          map,
          event,
        });
      case clickBehaviorConstants.roadwayPoint:
        trackEvent({ event: "mouse-click", action: "map-click-point" });
        Sentry.addBreadcrumb({
          category: "mouse-click",
          message: "map click point",
          level: Sentry.Severity.Info,
        });
        return roadwayPoint({
          feature,
          dispatch,
          responsive,
          leftAndRightPoints,
          modalVisibility,
          sidebarVisibility,
          enlargedImg,
          map,
        });
      case clickBehaviorConstants.roadwayPartnerLayer:
        trackEvent({ event: "mouse-click", action: "map-click-partner-layer" });
        Sentry.addBreadcrumb({
          category: "mouse-click",
          message: "map click partner layer",
          level: Sentry.Severity.Info,
        });
        return roadwayPoint({
          feature,
          dispatch,
          responsive,
          modalVisibility,
          leftAndRightPoints,
          sidebarVisibility,
          enlargedImg,
          map,
          dispatchHighlight: false,
          showRating: false,
        });
      case clickBehaviorConstants.roadwayCrackseal:
        trackEvent({ event: "mouse-click", action: "map-click-crack-seal" });
        Sentry.addBreadcrumb({
          category: "mouse-click",
          message: "map click crack seal",
          level: Sentry.Severity.Info,
        });
        return roadwayPoint({
          modalType: "cracksealPoint",
          feature,
          dispatch,
          responsive,
          leftAndRightPoints,
          modalVisibility,
          sidebarVisibility,
          enlargedImg,
          map,
        });
      case clickBehaviorConstants.roadwayPothole:
        trackEvent({ event: "mouse-click", action: "map-click-pothole-layer" });
        Sentry.addBreadcrumb({
          category: "mouse-click",
          message: "map click pothole layer",
          level: Sentry.Severity.Info,
        });
        return roadwayPoint({
          feature,
          dispatch,
          responsive,
          leftAndRightPoints,
          modalVisibility,
          sidebarVisibility,
          enlargedImg,
          map,
          dispatchHighlight: false,
          showRating: false,
        });
      case clickBehaviorConstants.roadwaySidewalkSegment:
        trackEvent({
          event: "mouse-click",
          action: "map-click-sidewalk-layer",
        });
        Sentry.addBreadcrumb({
          category: "mouse-click",
          message: "map click sidewalk layer",
          level: Sentry.Severity.Info,
        });
        return sidewalkSegment({
          feature,
          dispatch,
          colorPicker,
          modalVisibility,
          sidebarVisibility,
          enlargedImg,
          map,
          event,
        });
      case clickBehaviorConstants.roadwaySidewalkPoint:
        trackEvent({
          event: "mouse-click",
          action: "map-click-sidewalk-layer",
        });
        Sentry.addBreadcrumb({
          category: "mouse-click",
          message: "map click sidewalk layer",
          level: Sentry.Severity.Info,
        });
        return sidewalkPoint({
          modalType: "sidewalkPoint",
          feature,
          dispatch,
          responsive,
          leftAndRightPoints,
          modalVisibility,
          sidebarVisibility,
          enlargedImg,
          map,
          dispatchHighlight: true,
          showRating: true,
        });
      case clickBehaviorConstants.roadwayImageLoggerPoint:
        trackEvent({
          event: "mouse-click",
          action: "map-click-imagelogger-point",
        });
        Sentry.addBreadcrumb({
          category: "mouse-click",
          message: "map click imagelogger point",
          level: Sentry.Severity.Info,
        });
        return imageLoggerPoint({
          feature,
          dispatch,
          responsive,
          leftAndRightPoints,
          modalVisibility,
          sidebarVisibility,
          enlargedImg,
          map,
        });
      default:
        return null;
    }
  },
};

export default clickBehavior;
