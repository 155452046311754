import React, { useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import calculateArea from "@turf/area";
import {
  meterSquaredToMileSquared,
  meterSquaredToFeetSquared,
  meterSquaredToKilometerSquared,
} from "../utils/metrics";
import { MAP_STYLE_SATELLITE } from "constants/mapStyles";
import { onArea, offArea, updateArea, setArea } from "actions/measureArea";
import { setMapStyle } from "actions/map";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import track from "react-tracking";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser/dist/index";
import { MeasuringSystem } from "constants/measuringSystem";

const style = {
  speedDialAction: {
    width: "30px",
    height: "30px",
    minHeight: "30px",
    flex: 1,
  },
};

const AreaContainer = ({
  updateArea,
  measuringSystem,
  isAreaing,
  draw,
  setArea,
  baseMapStyle,
  offArea,
  isRotating,
  tracking,
  clearMap,
  isDynamicSegmentationActive,
  isMeasuring,
  map,
  onArea,
  setMapStyle,
  action,
  classes,
  open,
}) => {
  useEffect(() => {
    if (isAreaing) {
      map.getCanvas().style.cursor = "crosshair";
    } else {
      map.getCanvas().style.cursor = "";
    }
  }, [isAreaing, map]);

  const calcArea = e => {
    const data = draw.getAll();
    if (data.features.length > 0) {
      const areaM = calculateArea(data); // assumed to be m^2 here
      switch (measuringSystem) {
        case MeasuringSystem.Imperial:
          const areaMi = meterSquaredToMileSquared(areaM);
          const areaFt = meterSquaredToFeetSquared(areaM);
          setArea(areaFt.scalar, "feet");
          if (areaMi.scalar < 1) {
            updateArea(areaFt.scalar, "feet");
          } else {
            updateArea(areaMi.scalar, "miles");
          }
          break;

        case MeasuringSystem.Metric:
          const areaKm = meterSquaredToKilometerSquared(areaM);
          setArea(areaM, "meters");
          if (areaKm.scalar < 1) {
            updateArea(areaM, "meters");
          } else {
            setArea(areaM, "meters");
            updateArea(areaKm.scalar, "kilometers");
          }
          break;
        default:
          throw new Error(`${measuringSystem} is not a valid unit preference`);
      }
    } else if (e.type !== "draw.delete")
      // eslint-disable-next-line no-alert
      alert("Use the draw tools to draw a polygon!");
  };

  const turnOnAreaTool = () => {
    onArea();
    map.addControl(draw);
    draw.changeMode("draw_polygon");
    map.on("draw.create", calcArea);
    map.on("draw.delete", calcArea);
    map.on("draw.update", calcArea);
    if (measuringSystem === MeasuringSystem.Imperial) {
      setArea(0, "feet");
      updateArea(0, "feet");
    } else {
      setArea(0, "meters");
      updateArea(0, "meters");
    }
  };

  const setMapStyleSatellite = () => {
    setMapStyle(MAP_STYLE_SATELLITE);
  };

  const toggleArea = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "measure-area-toggle",
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `measure area toggle`,
      level: Sentry.Severity.Info,
    });

    if (isAreaing) {
      offArea();
      map.removeControl(draw);
      map.off("draw.create", calcArea);
      map.off("draw.delete", calcArea);
      map.off("draw.update", calcArea);
    } else if (!isRotating) {
      if (isDynamicSegmentationActive || isMeasuring) {
        clearMap();
      }

      if (baseMapStyle !== MAP_STYLE_SATELLITE) {
        setMapStyleSatellite();
        map.once("styledata", () => {
          turnOnAreaTool();
        });
      } else {
        turnOnAreaTool();
      }
    }
  };

  return (
    <SpeedDialAction
      key={`spdial-up-${action.title}`}
      tooltipTitle={action.title}
      className={classes.speedDialAction}
      tooltipPlacement="right"
      icon={action.icon}
      open={open}
      onClick={toggleArea}
      FabProps={{
        "data-testid": isAreaing ? `${action.title}-on` : `${action.title}-off`,
      }}
    />
  );
};

AreaContainer.propTypes = {
  updateArea: PropTypes.func.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  measureArea: PropTypes.shape({
    draw: PropTypes.shape({
      getAll: PropTypes.func.isRequired,
      changeMode: PropTypes.func.isRequired,
      getMode: PropTypes.func.isRequired,
    }).isRequired,
    isAreaing: PropTypes.bool.isRequired,
  }).isRequired,
  tracking: PropTypes.shape({
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  setArea: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired,
  onArea: PropTypes.func.isRequired,
  offArea: PropTypes.func.isRequired,
  isRotating: PropTypes.bool.isRequired,
  isDynamicSegmentationActive: PropTypes.bool.isRequired,
  isMeasuring: PropTypes.bool.isRequired,
  setMapStyle: PropTypes.func.isRequired,
  clearMap: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  baseMapStyle: PropTypes.string.isRequired,
  isAreaing: PropTypes.bool.isRequired,
  draw: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  measureArea: state.measureArea,
  isAreaing: state.measureArea.isAreaing,
  draw: state.measureArea.draw,
  isMeasuring: state.measureDistance.isMeasuring,
  measuringSystem: state.userData.measuringSystem,
  colorPicker: state.colorPicker,
  isRotating: state.rotation.isRotating,
  isDynamicSegmentationActive:
    state.dynamicSegmentation.isDragActive ||
    state.dynamicSegmentation.isSelectActive,
  baseMapStyle: state.baseMap.style,
});

const mapDispatchToProps = dispatch => ({
  onArea: () => dispatch(onArea()),
  offArea: () => dispatch(offArea()),
  updateArea: (area, value) => dispatch(updateArea(area, value)),
  setArea: (area, value) => dispatch(setArea(area, value)),
  setMapStyle: base => dispatch(setMapStyle(base)),
});

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(AreaContainer))
);
