import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";

interface BottomBarProps {
  modalTab: string;
  handleTabChange: () => void;
  tabsToShow: string[];
}

const useStyles = makeStyles({
  divContainer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    textAlign: "center",
    zIndex: 100,
    backgroundColor: "white",
    maxHeight: "38px",
    marginLeft: "-8px",
    overflowX: "hidden",
  },
  tabs: {
    maxHeight: "35px",
  },
  indicator: {
    top: 0,
    backgroundColor: "#fad201",
    height: "8px",
  },
});

const BottomBar = ({
  modalTab,
  handleTabChange,
  tabsToShow,
}: BottomBarProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.divContainer}>
      <Divider />
      <Tabs
        value={modalTab}
        onChange={handleTabChange}
        TabIndicatorProps={{ className: classes.indicator }}
      >
        {tabsToShow.map(tab => (
          <Tab className={classes.tabs} key={tab} value={tab} label={tab} />
        ))}
      </Tabs>
    </div>
  );
};

export default BottomBar;
