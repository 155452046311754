import {
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
  CLOSE_DROPDOWN,
  OPEN_DROPDOWN,
  CLOSE_NOTIFICATIONS,
  OPEN_NOTIFICATIONS,
  OPEN_TOP_BANNER,
  CLOSE_TOP_BANNER,
} from "../actiontypes/visibility";

const initialState = {
  optionsDropdownVisibility: false,
  notificationDropdownVisibility: false,
  sidebarVisibility: false,
  isTopBannerOpen: false,
};

interface Action {
  type: string;
}

const visibility = (state = initialState, action: Action) => {
  switch (action.type) {
    case OPEN_NOTIFICATIONS:
      return {
        ...state,
        notificationDropdownVisibility: true,
      };
    case CLOSE_NOTIFICATIONS:
      return {
        ...state,
        notificationDropdownVisibility: false,
      };
    case OPEN_DROPDOWN:
      return {
        ...state,
        optionsDropdownVisibility: true,
      };
    case CLOSE_DROPDOWN:
      return {
        ...state,
        optionsDropdownVisibility: false,
      };
    case OPEN_SIDEBAR:
      return {
        ...state,
        sidebarVisibility: true,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        sidebarVisibility: false,
      };
    case OPEN_TOP_BANNER:
      return {
        ...state,
        isTopBannerOpen: true,
      };
    case CLOSE_TOP_BANNER:
      return {
        ...state,
        isTopBannerOpen: false,
      };
    default:
      return state;
  }
};

export default visibility;
