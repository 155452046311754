import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import track from "react-tracking";
import moment from "moment-timezone";
import Hidden from "@material-ui/core/Hidden";
import firebase from "config/firebase";
import store from "store";
import { withRouter } from "react-router-dom";
import {
  setUser,
  setUserUId,
  setFirstName,
  setLastName,
  setOrganization,
  setLastLogin,
  showRoadwayWelcome,
  setIsFreeTrialUser,
} from "actions/login";
import {
  addNotifications,
  setNotificationsPriorToLastLogin,
} from "actions/notifications";
import {
  setMeasuringSystem,
  setHideExampleOrganizations,
} from "actions/userData";
import { toggleColorBlindMode } from "actions/colorPicker";
import { setMemberships, fetchOrganizations } from "actions/userData";
import Router from "components/Router";
import MobileWarning from "components/MobileWarning/MobileWarning";
import AppBarContainer from "containers/AppBarContainer";
import LoadingSpinner from "../components/LoadingSpinner";
import TOSView from "../components/TOSView";
import { PUBLIC_ROUTES, ROUTE_MAP } from "../constants/routes";
import { logoutUser, setUserStatus } from "../actions/login";
import * as Sentry from "@sentry/browser/dist/index";
import useMount from "hooks/useMount";
import Snackbar from "containers/Snackbar";
import PersistentBanner from "./Banner";
import { openTopBanner } from "../actions/visibility";
import { setSnackbarMessageAndOpen, MessageTypes } from "../actions/snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { topBannerHeight } from "../views/Banner";
import classNames from "classnames";
import { getDaysBetween } from "../utils/timestamp";
import UserStatus from "../constants/userStatus";
import { MeasuringSystem } from "constants/measuringSystem";
import RBAPI from "api/RoadwayAPI";
import { getAssessmentType } from "../utils/getAssessmentType";
import { preferencesListener } from "../listners/listeners";

const useStyles = makeStyles({
  wrap: {
    marginTop: `${topBannerHeight}px`,
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      backgroundColor: "#ededed",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#000",
      borderRadius: "5px",
    },
  },
});

const Roadway = ({
  history,
  addNotifications,
  setFirstName,
  setLastName,
  setOrganization,
  setMemberships,
  setNotificationsPriorToLastLogin,
  setLastLogin,
  setMeasuringSystem,
  toggleColorBlindMode,
  setUser,
  setUserUId,
  tracking,
  user,
  isTopBannerOpen,
  organizations,
  userUId,
  fetchOrganizations,
  location,
  loading,
  isLatestVersion,
  refreshCacheAndReload,
  setIsFreeTrialUser,
  setHideExampleOrganizations,
  openTopBanner,
  setUserStatus,
  userStatus,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isCurrentTOSAccepted, setIsCurrentTOSAccepted] = useState(true);
  const [isPriorTOSAccepted, setIsPriorTOSAccepted] = useState(false);
  const [TOSChecked, setTOSChecked] = useState(false);
  const [ActiveChecked, setActiveChecked] = useState(true);
  const [TOSPackage, setTOSPackage] = useState({});
  const [bannerMessage, setBannerMessage] = useState("");
  const [isRegisteredUSEntity, setIsRegisteredUSEntity] = useState();

  const setNewNotifications = async (notifications, lastLogin) => {
    // how many notifications are new compared to the last login
    const notificationsPriorToLastLogin = notifications.notifications
      // filter out the one that is tagged mostRecent (it is not a true notification)
      .filter(notification => !notification.mostRecent)
      .reduce((total, notification) => {
        return new Date(notification.date) > new Date(lastLogin) || !lastLogin
          ? total + 1
          : total;
      }, 0);
    setNotificationsPriorToLastLogin(notificationsPriorToLastLogin);
  };

  const showRemainingDaysBanner = async apiUser => {
    const TOSAgreedDate = apiUser.tosAgreed.date;
    const daysInFreeTrial = apiUser?.daysInFreeTrial || 60; // 60 days is the default
    const nDaysLeft =
      daysInFreeTrial - getDaysBetween(new Date(), new Date(TOSAgreedDate));
    let bannerMsg = `Access to your free trial assessment ends in ${nDaysLeft} days.`;

    if (nDaysLeft <= 0) {
      bannerMsg = `Your free trial has ended. Please contact RoadBotics to restore access.`;
      if (apiUser.status !== UserStatus.DISABLED) {
        await RBAPI.updateUser({
          id: userUId,
          status: UserStatus.DISABLED,
        }).catch(e => {
          setSnackbarMessageAndOpen(
            "Could not update user",
            MessageTypes.ERROR
          );
          throw e;
        });
        setUserStatus(UserStatus.DISABLED);
      }
    }

    setBannerMessage(bannerMsg);
    openTopBanner();
  };

  const getData = async userUID => {
    return Promise.all([
      RBAPI.getUserSettings(userUID).catch(err => {
        if (err?.response?.status === 404) {
          return;
        }
        throw err;
      }),
      RBAPI.fetchMembershipsByUsers(userUID).catch(err => {
        if (err?.response?.status === 404) {
          return;
        }
        throw err;
      }),
      RBAPI.getTOS().catch(err => {
        if (err?.response?.status === 404) {
          return;
        }
        throw err;
      }),
      RBAPI.getNotifications().catch(err => {
        if (err?.response?.status === 404) {
          return;
        }
        throw err;
      }),
    ]);
  };

  const setupUser = async (apiUser, userSettings, notifications, fullTOS) => {
    setUser(apiUser.email);
    setUserUId(apiUser.id);
    setIsFreeTrialUser(apiUser?.isFreeTrialUser);
    setUserStatus(apiUser.status);
    setFirstName(apiUser.firstName);
    setLastName(apiUser.lastName);
    setOrganization(apiUser.organization);
    setLastLogin(apiUser.lastLogin);
    setIsPriorTOSAccepted(apiUser?.tosAgreed?.hasOwnProperty("tosId"));
    setNewNotifications(notifications, apiUser.lastLogin);

    if (userSettings) {
      setMeasuringSystem(
        userSettings?.measuringSystem || MeasuringSystem.Imperial
      );
      toggleColorBlindMode(userSettings?.colorMode);
      setHideExampleOrganizations(userSettings?.hideExampleOrganizations);
    }
    setIsCurrentTOSAccepted(apiUser?.tosAgreed?.tosId === fullTOS.tos.version);
    if (apiUser?.isFreeTrialUser && apiUser?.tosAgreed?.date) {
      await showRemainingDaysBanner(apiUser);
    }
  };

  const mountAPICalls = async userUId => {
    RBAPI.resetAuthorizationHeaderToken();
    setIsLoading(true);

    preferencesListener(userUId);

    // Get the user and user related data
    fetchOrganizations(
      RBAPI.fetchScansByUser(userUId, getAssessmentType()).catch(err => {
        if (err?.response?.status === 404) {
          setSnackbarMessageAndOpen(
            "Could not find assessments",
            MessageTypes.ERROR
          );
          return;
        }
        throw err;
      })
    );
    const data = await getData(userUId);

    const [userSettings, memberships, fullTOS, notifications] = data;

    // Update the user last login, which is now
    const updateUserResp = await RBAPI.updateUser({
      id: userUId,
      lastLogin: moment().format("YYYY-MM-DD"),
    }).catch(e => {
      setSnackbarMessageAndOpen("Could not update user", MessageTypes.ERROR);
      throw e;
    });
    const apiUser = updateUserResp.data;

    // Get memberships and set
    setMemberships(memberships);

    // Get TOS and set

    setTOSPackage(fullTOS.tos);

    // Get Notifications and set
    addNotifications(notifications.notifications);

    // If user is pending, send them to set their password
    if (apiUser.status === UserStatus.PENDING) {
      // go to passwordSet route
      setActiveChecked(false);
      window.location.replace(
        `${process.env.REACT_APP_ACCOUNTS_DOMAIN}/passwordset`
      );
    } else {
      setActiveChecked(true);
    }
    tracking.trackEvent({
      event: "mouse-click",
      action: "user-revisit",
      userUId,
    });
    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "user revisit",
      level: Sentry.Severity.Info,
    });

    // setup the user and user related data
    setupUser(apiUser, userSettings, notifications, fullTOS);

    setIsLoading(false);
  };

  useMount(async () => {
    // Have to check if it is a public route before trying to validate
    // otherwise it will auto redirect to the accounts page now
    if (!PUBLIC_ROUTES.includes(window.location.pathname)) {
      if (process.env.REACT_APP_ISDEMO === "1") {
        console.log("is demo");
        firebase
          .auth()
          .signInWithEmailAndPassword("demo@roadbotics.com", "roadbotics")
          .then(async resp => {
            const { email, uid: userUId } = resp.user;
            setUser(email);
            setUserUId(userUId);

            mountAPICalls(userUId);
          });
      } else {
        try {
          const resp = await RBAPI.validateSessionToken();

          setUser(resp.data.data.decodedClaims.email);
          setUserUId(resp.data.data.decodedClaims.uid);

          firebase
            .auth()
            .signInWithCustomToken(resp.data.data.customToken)
            .then(async user => {
              if (user) {
                const userUId = resp.data.data.decodedClaims.uid;
                mountAPICalls(userUId);
              }
            });
        } catch (e) {
          // redirect to the accounts login page
          firebase
            .auth()
            .signOut()
            .then(() => {
              window.location = e.response.data.accountsRedirectURL;
            });
        }
      }
    } else {
      setIsLoading(false);
    }
  });

  const handleChooseRegisteredUSEntity = event => {
    setIsRegisteredUSEntity(event.target.value === "true" ? true : false);
  };

  const handleTOSAccept = () => {
    const user = firebase.auth().currentUser;
    const editedUser = {
      id: user.uid,
      tosAgreed: {
        tosId: TOSPackage.version,
        date: new Date(),
      },
      isRegisteredUSEntity: isRegisteredUSEntity,
    };
    RBAPI.updateUser(editedUser)
      .catch(e => {
        setSnackbarMessageAndOpen("Could not update user", MessageTypes.ERROR);
        throw e;
      })
      .then(async () => {
        tracking.trackEvent({
          event: "mouse-click",
          action: "tos-agreed",
          userUId: editedUser.id,
        });
        Sentry.addBreadcrumb({
          category: "mouse-click",
          message: "tos agreed",
          level: Sentry.Severity.Info,
        });
        setIsCurrentTOSAccepted(true);
        const { uid: userUId } = user;
        const apiUser = await RBAPI.getUser(userUId).catch(err => {
          setSnackbarMessageAndOpen("Could not get user", MessageTypes.ERROR);
          throw err;
        });
        if (apiUser?.isFreeTrialUser) {
          showRemainingDaysBanner(apiUser);
        }
      });
  };

  const getNewestScanId = orgScans => {
    const sortedScan = orgScans.sort(
      (a, b) => moment(a.dateCreated) < moment(b.dateCreated)
    )[0];
    return sortedScan.id;
  };

  useEffect(() => {
    if (!ActiveChecked) {
      window.location.replace(
        `${process.env.REACT_APP_ACCOUNTS_DOMAIN}/passwordset`
      );
    }
  }, [ActiveChecked, history]);

  useEffect(() => {
    const hasChildrenOrganization =
      organizations && organizations[0]?.childrenOrganizations?.length > 0;
    if (isCurrentTOSAccepted && ActiveChecked && !hasChildrenOrganization) {
      if (organizations[0]?.scans?.length > 0) {
        history.push(`${ROUTE_MAP}/${getNewestScanId(organizations[0].scans)}`);
      }
    }
  }, [organizations, ActiveChecked, isCurrentTOSAccepted, history]);

  const renderTOSContent = () => {
    return (
      <Fragment>
        <AppBarContainer
          urlLocation={location.pathname}
          isTOSView={!isCurrentTOSAccepted}
        />
        <TOSView
          content={TOSPackage.content}
          tosAgreed={TOSChecked}
          registeredUSEntityPicked={isRegisteredUSEntity}
          handleClickTosAgreed={() => setTOSChecked(!TOSChecked)}
          handleClickAccept={handleTOSAccept}
          handleChooseRegisteredUSEntity={handleChooseRegisteredUSEntity}
          isActiveAcceptButton={false}
          isPriorTOSAccepted={isPriorTOSAccepted}
        />
      </Fragment>
    );
  };

  const renderContent = () => {
    if (
      !process.env.REACT_APP_ISDEMO !== "1" &&
      !isCurrentTOSAccepted &&
      TOSPackage.content
    ) {
      return renderTOSContent();
    }
    return (
      <Fragment>
        <Hidden xsDown>
          <div>
            <Router userUId={userUId} user={user} userStatus={userStatus} />
          </div>
        </Hidden>
        <Hidden smUp>
          <MobileWarning />
        </Hidden>
      </Fragment>
    );
  };

  const renderApplication = () => {
    return (
      <div>
        {isLoading || loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <PersistentBanner text={bannerMessage} />
            <div
              className={classNames({
                [classes.wrap]: isTopBannerOpen,
              })}
            >
              <Fragment>
                {renderContent()}
                <Snackbar />
              </Fragment>
            </div>
          </>
        )}
      </div>
    );
  };

  if (!loading && !isLatestVersion) refreshCacheAndReload();
  return renderApplication();
};

Roadway.defaultProps = {
  user: null,
  userUId: "",
};

Roadway.propTypes = {
  user: PropTypes.string,
  setUser: PropTypes.func.isRequired,
  setUserUId: PropTypes.func.isRequired,
  tracking: PropTypes.shape({
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  setOrganization: PropTypes.func.isRequired,
  addNotifications: PropTypes.func.isRequired,
  setNotificationsPriorToLastLogin: PropTypes.func.isRequired,
  setLastLogin: PropTypes.func.isRequired,
  setMeasuringSystem: PropTypes.func.isRequired,
  toggleColorBlindMode: PropTypes.func.isRequired,
  setMemberships: PropTypes.func.isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  userUId: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isLatestVersion: PropTypes.bool.isRequired,
  refreshCacheAndReload: PropTypes.func.isRequired,
  setIsFreeTrialUser: PropTypes.func.isRequired,
  setHideExampleOrganizations: PropTypes.func.isRequired,
  isTopBannerOpen: PropTypes.bool.isRequired,
  openTopBanner: PropTypes.func.isRequired,
  setUserStatus: PropTypes.func.isRequired,
  userStatus: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.email,
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  userUId: state.user.userUId,
  organizations: state.userData.organizations,
  isTopBannerOpen: state.visibility.isTopBannerOpen,
  userStatus: state.user.status,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUser,
      setUserUId,
      setFirstName,
      setLastName,
      setOrganization,
      addNotifications,
      setLastLogin,
      setMeasuringSystem,
      setNotificationsPriorToLastLogin,
      toggleColorBlindMode,
      setMemberships,
      showRoadwayWelcome,
      fetchOrganizations,
      logoutUser,
      setIsFreeTrialUser,
      setHideExampleOrganizations,
      openTopBanner,
      setUserStatus,
      setSnackbarMessageAndOpen,
    },
    dispatch
  );

const ConnectedRoadway = connect(mapStateToProps, mapDispatchToProps)(Roadway);

const TrackedRoadway = track(
  // app-level tracking data
  () => {
    const state = store.getState();
    const trackingData = {
      app: "roadway",
    };
    if (state.userUId) {
      trackingData.userUId = state.userUId;
    }
    return trackingData;
  }
)(ConnectedRoadway);

export default withRouter(TrackedRoadway);
