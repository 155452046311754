export const SET_USER = "SET_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const SET_LAST_NAME = "SET_LAST_NAME";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const SET_USER_UID = "SET_USER_UID";
export const SET_LAST_LOGIN = "SET_LAST_LOGIN";
export const SET_ROLE = "SET_ROLE";
export const SHOW_ROADWAY_WELCOME = "SHOW_ROADWAY_WELCOME";
export const SET_IS_FREE_TRIAL_USER = "SET_IS_FREE_TRIAL_USER";
export const SET_USER_STATUS = "SET_USER_STATUS";
