import React from "react";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import withStyles from "@material-ui/styles/withStyles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import PropTypes from "prop-types";

const styles = {
  root: {
    padding: "5px",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
};

const defaultTheme = createMuiTheme({
  typography: { useNextVariants: true },
});

const DynamicCheckbox = ({ classes, color, onChange, checked }) => (
  <MuiThemeProvider
    theme={
      color
        ? {
            ...defaultTheme,
            overrides: {
              ...defaultTheme.overrides,
              MuiCheckbox: {
                colorPrimary: {
                  color,
                  "&$checked": {
                    color,
                  },
                },
              },
            },
          }
        : defaultTheme
    }
  >
    <Checkbox
      color="primary"
      classes={{
        root: classes.root,
      }}
      onChange={onChange}
      checked={checked}
      icon={<RadioButtonUnchecked />}
      checkedIcon={<RadioButtonChecked />}
    />
  </MuiThemeProvider>
);

DynamicCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DynamicCheckbox);
