import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeletePopup from "../../components/DeletePopup";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import OrgTree from "containers/OrgTree";
import EmbeddedMapsAdminContainer from "./EmbeddedMapsAdminContainer";
import ScansAdminContainer from "./ScansAdminContainer";
import OrganizationsAdminContainer from "./OrganizationsAdminContainer";
import UsersAdminContainer from "./UsersAdminContainer";
import RBAPI from "api/RoadwayAPI";
import * as Sentry from "@sentry/browser/dist/index";
import track from "react-tracking";
import { fetchAllOrganizations, fetchOrganizations } from "actions/userData";
import useMount from "hooks/useMount";
import { getAssessmentType } from "../../utils/getAssessmentType";
import State from "../../interfaces/state";

interface HandleAdminContainerProps {
  tabValue: number;
  currentOrganization: any;
  adminOrganizations: any[];
  handleOrganizationClick: (selectedOrganization: any) => void;
  hasOrganizationsData: boolean;
  tracking: any;
  userUId: string;
}

const HandleAdminContainer = ({
  tabValue,
  currentOrganization,
  adminOrganizations,
  handleOrganizationClick,
  hasOrganizationsData,
  tracking,
  userUId,
}: HandleAdminContainerProps): JSX.Element => {
  const dispatch = useDispatch();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState({
    id: "",
    displayName: "",
    deleted: false,
    firstName: "",
    lastName: "",
    name: "",
  });

  const handleError = (e: ErrorEvent, message: string) => {
    dispatch(setSnackbarMessageAndOpen(message, MessageTypes.ERROR));
    throw e;
  };

  const openDeletePopup = (incomingObject: any) => {
    setObjectToDelete(incomingObject);
    setIsDeleteDialogOpen(true);
  };

  const closeDeletePopup = () => {
    setObjectToDelete({
      id: "",
      displayName: "",
      deleted: false,
      firstName: "",
      lastName: "",
      name: "",
    });
    setIsDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    setIsProcessingDelete(true);
    const updateObjectToDelete = { ...objectToDelete };
    updateObjectToDelete.deleted = true;
    try {
      switch (tabValue) {
        case 0:
          await RBAPI.deleteUser(updateObjectToDelete, currentOrganization.id);
          tracking.trackEvent({
            event: "mouse-click",
            action: `admin-user-delete-${updateObjectToDelete.id}`,
            userUId: userUId,
          });

          Sentry.addBreadcrumb({
            category: "mouse-click",
            message: `admin user delete ${updateObjectToDelete.id}`,
            level: Sentry.Severity.Info,
          });
          break;
        case 1:
          await RBAPI.updateScan(updateObjectToDelete);
          tracking.trackEvent({
            event: "mouse-click",
            action: `admin-scan-delete-${updateObjectToDelete.id}`,
            userUId,
          });

          Sentry.addBreadcrumb({
            category: "mouse-click",
            message: `admin scan delete ${updateObjectToDelete.id}`,
            level: Sentry.Severity.Info,
          });
          break;
        case 2:
          await RBAPI.updateOrganization(updateObjectToDelete);

          tracking.trackEvent({
            event: "mouse-click",
            action: `admin-deleted-organization-${updateObjectToDelete.id}`,
            userUId,
          });

          Sentry.addBreadcrumb({
            category: "mouse-click",
            message: `admin-deleted-organization-${updateObjectToDelete.id}`,
            level: Sentry.Severity.Info,
          });
          break;
        case 3:
          await RBAPI.updateMap({ id: updateObjectToDelete.id, deleted: true });
          tracking.trackEvent({
            event: "mouse-click",
            action: `admin-embedded-map-delete-${updateObjectToDelete.id}`,
            userUId,
          });
          Sentry.addBreadcrumb({
            category: "mouse-click",
            message: `admin embedded map delete ${updateObjectToDelete.id}`,
            level: Sentry.Severity.Info,
          });
          break;
        default:
          return null;
      }
    } catch (e) {
      handleError(e, "Failed to delete");
    } finally {
      setIsProcessingDelete(false);
      closeDeletePopup();
    }
  };

  const isAllOrganizationsDataLoaded = useSelector(
    (state: State) => state.userData.isAllOrganizationsDataLoaded
  );

  const loadAllOrganizations = useCallback(() => {
    // fetch all organizations to reload the organizations admin data
    dispatch(
      fetchAllOrganizations(
        RBAPI.getOrganizations().catch((e: ErrorEvent) => console.error(e))
      )
    );
    // fetch organizations by user to reload the dashboard page data
    dispatch(
      fetchOrganizations(
        RBAPI.fetchScansByUser(userUId, getAssessmentType()).catch(
          (err: any) => {
            if (err?.response?.status === 404) {
              dispatch(
                setSnackbarMessageAndOpen(
                  "Could not find assessments",
                  MessageTypes.ERROR
                )
              );
              return;
            }
            throw err;
          }
        )
      )
    );
  }, [userUId, dispatch]);

  const onMount = () => {
    if (!isAllOrganizationsDataLoaded) {
      loadAllOrganizations();
    }
  };

  useMount(onMount);

  useEffect(() => {
    return () => {
      if (isProcessingDelete) {
        loadAllOrganizations();
      }
    };
  }, [isDeleteDialogOpen, isProcessingDelete, loadAllOrganizations]);

  return (
    <div>
      <Grid container>
        {tabValue !== 2 && (
          <Grid item xs={2}>
            <Typography variant="overline" gutterBottom>
              Organizations
            </Typography>
            <OrgTree
              rootOrganizations={adminOrganizations}
              handleOrganizationClick={handleOrganizationClick}
              isLoading={!hasOrganizationsData}
            />
          </Grid>
        )}
        {tabValue === 0 && (
          <Grid item xs={10}>
            <UsersAdminContainer
              isDeleteDialogOpen={isDeleteDialogOpen}
              openDeletePopup={openDeletePopup}
              currentOrganization={currentOrganization}
            />
          </Grid>
        )}
        {tabValue === 1 && (
          <Grid item xs={10}>
            <ScansAdminContainer
              openDeletePopup={openDeletePopup}
              currentOrganization={currentOrganization}
            />
          </Grid>
        )}
        {tabValue === 2 && (
          <Grid item xs={12}>
            <OrganizationsAdminContainer
              isDeleteDialogOpen={isDeleteDialogOpen}
              openDeletePopup={openDeletePopup}
            />
          </Grid>
        )}
        {tabValue === 3 && (
          <Grid item xs={10}>
            <EmbeddedMapsAdminContainer
              isDeleteDialogOpen={isDeleteDialogOpen}
              openDeletePopup={openDeletePopup}
              currentOrganization={currentOrganization}
            />
          </Grid>
        )}
      </Grid>
      {isDeleteDialogOpen && (
        <DeletePopup
          deleteName={
            tabValue === 0
              ? `${objectToDelete.firstName} ${objectToDelete.lastName}`
              : objectToDelete.displayName || objectToDelete.name
          }
          isOpen={isDeleteDialogOpen}
          handleDelete={handleDelete}
          handleClose={closeDeletePopup}
          isExampleOrganization={false}
          isProcessingDelete={isProcessingDelete}
        />
      )}
    </div>
  );
};

export default track()(HandleAdminContainer);
