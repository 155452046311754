import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import MaterialTable, { Icons } from "material-table";
import KeyboardArrowDownRounded from "@material-ui/icons/KeyboardArrowDownRounded";
import {
  TABLE_HEADER_BACKGROUND,
  TABLE_HEADER_TEXT,
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_YELLOW,
  RB_ORANGE,
  RB_RED,
} from "../constants/colors";
import styleTableRow from "../utils/styleTableRow";
import { determineBackgroundColor } from "../utils/determineColor";
import ScrollableAnchor from "react-scrollable-anchor";
import { getLengthInPreferredUnit } from "../utils/metrics";
import TableSkeleton from "../skeletons/table";
import { MeasuringSystem } from "constants/measuringSystem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: "#f9f7f6",
      margin: "20px 20px 40px",
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    avgTitle: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "19px",
      textAlign: "center",
      marginTop: "15px",
    },
    barChartTitle: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "19px",
      textAlign: "center",
      marginTop: "15px",
    },
    box: {
      color: "white",
      margin: "2px 80px",
      padding: "5px",
      textAlign: "center",
    },
    number: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "35px",
      fontWeight: "bold",
      padding: "10px",
    },
    title: {
      paddingLeft: "10px",
      fontSize: "30px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    titleDiv: {
      borderLeft: `solid 10px ${RB_YELLOW}`,
      borderBottom: "solid 1px #d0d0d0",
    },
    tableTitle: {
      fontSize: "20px",
      fontFamily: "'Open Sans', sans-serif",
    },
  })
);

interface StreetTypeAnalysisChartProps {
  streetData: {
    name: string;
    ratingDistribution: { [key: string]: number };
    avgRating: number;
    key: string | number;
    totalLength: number;
  };
  measuringSystem: MeasuringSystem;
  streetList: Array<RowDataProps>;
  isDataLoaded: boolean;
}

interface RowDataProps {
  totalLength: number;
  avgRating: number;
  name: string;
  highwayType: string;
  id: string;
  ratingDistribution: { [key: number]: number };
}

const StreetTypeAnalysisChart = ({
  streetData,
  measuringSystem,
  streetList,
  isDataLoaded = false,
}: StreetTypeAnalysisChartProps): React.ReactElement | null => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(5);

  const appendTextToHighwayType = (
    text: string,
    name: string
  ): React.ReactNode => {
    if (name !== "null") {
      return (
        <span>
          {name} {text}
        </span>
      );
    }
    return (
      <span>
        <i>Unknown Name </i>
        {text}
      </span>
    );
  };

  const barChart = {
    data: {
      datasets: [
        {
          data: Object.keys(streetData.ratingDistribution).map(key => {
            const rating = streetData.ratingDistribution[key];
            return rating ? rating.toFixed(2) : 0;
          }),
          backgroundColor: [
            RB_GREEN,
            RB_LIGHT_GREEN,
            RB_YELLOW,
            RB_ORANGE,
            RB_RED,
          ],
          barThickness: 20,
        },
      ],
      labels: ["Rating 1", "Rating 2", "Rating 3", "Rating 4", "Rating 5"],
    },
    options: {
      maintainAspectRatio: true,
      legend: { display: false },
      layout: {
        padding: {
          left: 40,
          right: 40,
          top: 20,
          bottom: 20,
        },
      },
      scales: {
        xAxes: [
          {
            ticks: { beginAtZero: true, max: 100 },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          display: true,
          align: "end",
          anchor: "end",
          color: "black",
          formatter: function(value: number): string {
            return `${value} %`;
          },
        },
      },
    },
  };

  if (!streetList) return null;

  return (
    <Paper className={classes.wrapper}>
      <ScrollableAnchor id={`${streetData.name}`}>
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.titleDiv}>
              <Typography className={classes.title} gutterBottom>
                {appendTextToHighwayType("Roads", streetData.name)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.avgTitle}>Score Average</Typography>
            <Paper
              className={classes.box}
              style={{
                backgroundColor: determineBackgroundColor(
                  streetData.avgRating.toFixed(2)
                ),
              }}
            >
              <Typography className={classes.number}>
                {streetData.avgRating.toFixed(2)}
              </Typography>
            </Paper>
            <Typography className={classes.barChartTitle}>
              Road Network Ratings Make Up
            </Typography>
            <HorizontalBar
              data={barChart.data}
              width={100}
              height={60}
              options={barChart.options}
            />
          </Grid>
          <Grid item xs={8}>
            <MaterialTable
              onChangeRowsPerPage={setPageSize}
              options={{
                actionsColumnIndex: -1,
                pageSizeOptions: [5, 10, 20],
                pageSize,
                headerStyle: {
                  backgroundColor: TABLE_HEADER_BACKGROUND,
                  color: TABLE_HEADER_TEXT,
                  fontWeight: "bold",
                  fontSize: "14px",
                },
                rowStyle: styleTableRow,
                search: false,
              }}
              title={
                <div className={classes.tableTitle}>
                  {appendTextToHighwayType("Road Statistics", streetData.name)}
                </div>
              }
              columns={[
                {
                  title: "Street Name",
                  customSort: (a: RowDataProps, b: RowDataProps): number =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                  render: function renderStreetName(
                    rowData: RowDataProps
                  ): React.ReactNode {
                    return (
                      <div>
                        {rowData.name !== "null" ? (
                          rowData.name
                        ) : (
                          <i>Unknown Name</i>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title:
                    measuringSystem === MeasuringSystem.Imperial
                      ? "Length (mi)"
                      : "Length (km)",
                  customSort: (a: RowDataProps, b: RowDataProps): number =>
                    a.totalLength - b.totalLength,
                  render: function renderLength(
                    rowData: RowDataProps
                  ): React.ReactNode {
                    return (
                      <div>
                        {!Number.isNaN(rowData.totalLength)
                          ? getLengthInPreferredUnit(
                              rowData.totalLength,
                              measuringSystem,
                              "large"
                            ).scalar.toFixed(3)
                          : ""}
                      </div>
                    );
                  },
                },
                {
                  title: "Score",
                  render: function renderScore(
                    rowData: RowDataProps
                  ): React.ReactNode {
                    return (
                      <div>{rowData?.avgRating?.toFixed(2) || "Unknown"}</div>
                    );
                  },
                  customSort: (a: RowDataProps, b: RowDataProps): number =>
                    a.avgRating - b.avgRating,
                },
              ]}
              localization={{
                header: { actions: "" },
                body: {
                  emptyDataSourceMessage: ((isDataLoaded ? (
                    <Typography>
                      No records to display. Contact RoadBotics if this is
                      incorrect
                    </Typography>
                  ) : (
                    <TableSkeleton />
                  )) as unknown) as string,
                },
              }}
              data={streetList}
              icons={
                {
                  SortArrow: KeyboardArrowDownRounded,
                } as Icons
              }
            />
          </Grid>
        </Grid>
      </ScrollableAnchor>
    </Paper>
  );
};

export default StreetTypeAnalysisChart;
