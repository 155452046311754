import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LineModalContent from "components/LineModalContent";
import IndividualDistressContainer from "containers/IndividualDistressContainer";
import Button from "@material-ui/core/Button";
import leftEnd from "images/icons/leftEnd.png";
import rightEnd from "images/icons/rightEnd.png";
import viewSegmentImages from "images/icons/viewSegmentImages.png";
import noSegmentImages from "images/icons/noSegmentImages.png";
import ChevronLeftRounded from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRounded from "@material-ui/icons/ChevronRightRounded";
import PlayCircleOutlineRounded from "@material-ui/icons/PlayCircleOutlineRounded";
import style from "../style";
import get from "lodash/get";
import PropTypes from "prop-types";
import roadwayPoint from "utils/clickBehavior/roadwayPoint";
import imageLoggerPoint from "utils/clickBehavior/imageLoggerPoint";
import { getLeftAndRightPoints, isTimestampGreater } from "utils/pointsUtils";
import { getCurrentToggleGroups, allTogglesToggled } from "utils/toggleGroups";
import track from "react-tracking";
import * as Sentry from "@sentry/browser/dist/index";
import { clickBehaviorConstants } from "constants/clickBehavior";

const useStyles = makeStyles(style);

const RawSegmentContent = ({
  modalData,
  colorPicker,
  noPoints,
  tracking,
  map,
  layerGroups,
  dispatch,
  modalVisibility,
  sidebarVisibility,
  toggleGroups,
  showLayer,
  noPointsModal,
  segmentCoords,
  enlargedImg,
  userUId,
}) => {
  const clickPointOnSegmentWithLowestTimestamp = roadwayPointFeatures => {
    let pointOnSegmentWithLowestTimeStamp;

    roadwayPointFeatures.forEach(point => {
      if (
        !pointOnSegmentWithLowestTimeStamp &&
        point.properties.segment === modalData.segmentData.content.segmentId
      ) {
        pointOnSegmentWithLowestTimeStamp = point;
      } else if (
        pointOnSegmentWithLowestTimeStamp &&
        !isTimestampGreater(point, pointOnSegmentWithLowestTimeStamp) &&
        point.properties.segment === modalData.segmentData.content.segmentId
      ) {
        pointOnSegmentWithLowestTimeStamp = point;
      }
    });

    const leftAndRightPoints = getLeftAndRightPoints({
      feature: pointOnSegmentWithLowestTimeStamp,
      map,
    });
    if (
      pointOnSegmentWithLowestTimeStamp.layer.metadata.clickBehavior ===
      clickBehaviorConstants.roadwayPoint
    ) {
      roadwayPoint({
        inSegmentModal: true,
        feature: pointOnSegmentWithLowestTimeStamp,
        dispatch,
        leftAndRightPoints,
        enlargedImg,
        sidebarVisibility,
        modalVisibility,
        map,
        modalData,
      });
    } else if (
      pointOnSegmentWithLowestTimeStamp.layer.metadata.clickBehavior ===
      clickBehaviorConstants.roadwayImageLoggerPoint
    ) {
      imageLoggerPoint({
        inSegmentModal: true,
        feature: pointOnSegmentWithLowestTimeStamp,
        dispatch,
        leftAndRightPoints,
        enlargedImg,
        sidebarVisibility,
        modalVisibility,
        map,
        modalData,
      });
    }
  };
  const displayFirstPoint = () => {
    let allFeaturesOnScreen = map.queryRenderedFeatures();
    let roadwayPointFeatures = allFeaturesOnScreen.filter(
      feature =>
        get(feature.layer, "metadata.clickBehavior") &&
        feature.geometry.type === "Point"
    );

    if (roadwayPointFeatures.length === 0) {
      return noPointsModal();
    }

    // I'm only checking if the first point feature has the segment property
    // We could check all of the points, but chances are, if one the points doesn't
    // have the segment id data, none of them do
    const doPointsHaveSegmentData = roadwayPointFeatures[0].properties.segment;
    if (!doPointsHaveSegmentData) {
      // eslint-disable-next-line no-alert
      return alert(
        "This feature isn't available on older datasets. Please view points manually."
      );
    }

    const isSegmentOnScreen = roadwayPointFeatures.some(
      point =>
        point.properties.segment ===
        get(modalData, "segmentData.content.segmentId", null)
    );
    let isCenteringMap = false;

    if (map.getZoom() < 17) {
      // if zoomed too far out, always center the map
      isCenteringMap = true;
      map.flyTo({
        center: segmentCoords[0],
        zoom: 17,
      });
    } else if (!isSegmentOnScreen) {
      // if the segment isn't on the screen, center the map
      isCenteringMap = true;
      map.flyTo({
        center: segmentCoords[0],
      });
    }

    if (isCenteringMap) {
      // wait until map is done being centered
      map.once("moveend", () => {
        allFeaturesOnScreen = map.queryRenderedFeatures();
        roadwayPointFeatures = allFeaturesOnScreen.filter(
          feature =>
            get(feature.layer, "metadata.clickBehavior") &&
            feature.geometry.type === "Point"
        );
        const isSegmentOnScreen = roadwayPointFeatures.some(
          point =>
            point.properties.segment ===
            get(modalData, "segmentData.content.segmentId", null)
        );
        // this if conditional should be true 99.999% of the time
        if (isSegmentOnScreen) {
          clickPointOnSegmentWithLowestTimestamp(roadwayPointFeatures);
        } else {
          return noPointsModal();
        }
      });
    } else {
      clickPointOnSegmentWithLowestTimestamp(roadwayPointFeatures);
    }
  };
  const handleVirtualDriver = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: "handle-street-view",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `handle street view`,
      level: Sentry.Severity.Info,
    });

    const currentToggleGroups = getCurrentToggleGroups(toggleGroups);

    const toggleGroupIdPoints = currentToggleGroups.find(
      toggleGroup =>
        toggleGroup.name === "points" ||
        toggleGroup.name === "imageLoggerPoints"
    ).id;

    if (
      !allTogglesToggled({
        toggleGroupId: toggleGroupIdPoints,
        toggleGroups,
        layerGroups,
      })
    ) {
      const pointLayerGroups = layerGroups
        .map(layerGroup => layerGroup.id)
        .reduce((accumulator, currentValue) => {
          const layerGroup = layerGroups.find(item => item.id === currentValue);
          if (
            layerGroup.clickBehavior === clickBehaviorConstants.roadwayPoint ||
            layerGroup.clickBehavior ===
              clickBehaviorConstants.roadwayImageLoggerPoint
          ) {
            accumulator.push(layerGroup);
          }
          return accumulator;
        }, []);
      pointLayerGroups.forEach(layerGroup => {
        layerGroup.layers.forEach(layer => {
          showLayer(layer.id);
        });
      });

      // wait for the map to finish adding the points
      map.once("idle", () => {
        displayFirstPoint();
      });
    } else {
      // TODO: refactor out setTimeout. This somehow fixes streetview crashing the app
      setTimeout(displayFirstPoint, 0);
    }
  };

  const classes = useStyles();

  const segmentContent = modalData.segmentData.content;
  return (
    <React.Fragment>
      <LineModalContent
        chartData={segmentContent.chartData}
        averageRating={segmentContent.averageRating}
        length={segmentContent.length}
        colorPicker={colorPicker}
        clickType={modalData.type}
      />
      {segmentContent.segmentDistressStatistics && (
        <IndividualDistressContainer
          segmentDistressStatistics={segmentContent.segmentDistressStatistics}
        />
      )}
      {modalData.type !== "sidewalkSegment" && (
        <div className={classes.fakeImage}>
          <Button
            onClick={handleVirtualDriver}
            className={classes.segmentClick}
            disabled={noPoints}
          >
            <img
              src={noPoints ? noSegmentImages : viewSegmentImages}
              alt="viewSegmentImages"
            />
          </Button>
          <div className={classes.container}>
            <img src={leftEnd} alt="" className={classes.leftEnd} />
            <Button disabled={true} className={classes.leftButton}>
              <ChevronLeftRounded />
              PREV
            </Button>
            <Button disabled={true}>
              <PlayCircleOutlineRounded className={classes.playButton} />
            </Button>
            <Button disabled={true} className={classes.rightButton}>
              NEXT
              <ChevronRightRounded />
            </Button>
            <img src={rightEnd} alt="" className={classes.rightEnd} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default track()(RawSegmentContent);

RawSegmentContent.defaultProps = {
  userUID: "",
  enlargedImg: false,
};

RawSegmentContent.propTypes = {
  modalData: PropTypes.object.isRequired,
  colorPicker: PropTypes.object.isRequired,
  noPoints: PropTypes.bool.isRequired,
  tracking: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  layerGroups: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  modalVisibility: PropTypes.bool.isRequired,
  sidebarVisibility: PropTypes.bool.isRequired,
  toggleGroups: PropTypes.array.isRequired,
  showLayer: PropTypes.func.isRequired,
  noPointsModal: PropTypes.func.isRequired,
  segmentCoords: PropTypes.array.isRequired,
  enlargedImg: PropTypes.bool,
  userUId: PropTypes.string,
};
