import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import { RB_YELLOW } from "../../constants/colors";
import Scan from "interfaces/scan";
import { AssessmentTypes } from "../../constants/assessmentTypes";
import Organization from "interfaces/organization";

const useStyles = makeStyles({
  button: {
    width: "37%",
    backgroundColor: RB_YELLOW,
    textTransform: "none",
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "'Open Sans', sans-serif",
    "&:hover": {
      backgroundColor: RB_YELLOW,
    },
    borderRadius: "2px",
    position: "absolute",
    bottom: "10px",
    left: "10px",
  },
  selectItemText: {
    fontSize: "14px",
  },
});

const isNotImageLoggerScan = (scan: Scan): boolean =>
  scan.assessmentType !== AssessmentTypes.IMAGELOGGER;

interface ChildOrgAnalysisDropDownProps {
  isAccountDisabled: boolean;
  assessmentAnalysisPresent: boolean;
  goToSummaryPage: Function;
  childOrg: Organization;
}

const ChildOrgAnalysisDropDown = ({
  isAccountDisabled,
  assessmentAnalysisPresent,
  goToSummaryPage,
  childOrg,
}: ChildOrgAnalysisDropDownProps) => {
  const classes = useStyles();
  const childOrgScans: Scan[] = childOrg?.scans || [];

  const childOrgScansAssessments = childOrgScans.filter(isNotImageLoggerScan);

  if (childOrgScansAssessments.length < 1) {
    return null;
  }

  return (
    <FormControl
      disabled={isAccountDisabled || !assessmentAnalysisPresent}
      className={classes.button}
    >
      <Select
        value=""
        inputProps={{
          id: "scan-simple",
        }}
        displayEmpty
        disableUnderline
        onChange={(e: React.ChangeEvent<{ value: unknown }>): void => {
          goToSummaryPage(e, childOrg);
        }}
      >
        <MenuItem value="" disabled>
          <Typography align="center" className={classes.selectItemText}>
            Analysis
          </Typography>
        </MenuItem>
        {childOrgScansAssessments.map(scan => {
          return (
            <MenuItem key={scan.id} value={[scan.organizationId, scan.id]}>
              <Typography className={classes.selectItemText}>
                {scan.displayName || scan.name}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ChildOrgAnalysisDropDown;
