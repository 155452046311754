import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import ColorPreferenceContainer from './ColorPreferenceContainer'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import ExampleOrganizationVisibility from './ExampleOrganizationVisibility'

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 30px',
    marginTop: '1%'
  },
  buttons: {
    padding: '0px 12px 20px 0px',
    bottom: '0px',
    right: '10px',
    position: 'absolute'
  },
  newCard: {
    paddingBottom: '50px'
  },
  radio: {
    margin: '-8px 0px -8px 3px'
  },
  divider: {
    marginBottom: '2%'
  },
  unitsDiv: {
    marginTop: '2%'
  },
  group: {
    marginLeft: '-2%'
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '28px'
  },
  root: {
    boxShadow: 'none'
  }
})

interface AppearanceSettingsInterface {
  saveAppearanceSettings: () => void
  resetAppearanceSettings: () => void
  measuringSystem: string
  updateMeasuringSystem: (event: React.ChangeEvent<{ value: string }>) => void
  disabled: boolean
  colorBlindMode: string
  updateColorBlindMode: (event: React.ChangeEvent<{ value: string }>) => void
  freeTrialUser: boolean
  hideExampleOrganizations: boolean
  setHideExampleOrganizations: (value: boolean) => void
}

const AppearanceSettings = ({
  saveAppearanceSettings,
  resetAppearanceSettings,
  measuringSystem,
  updateMeasuringSystem,
  disabled,
  colorBlindMode,
  updateColorBlindMode,
  freeTrialUser,
  hideExampleOrganizations,
  setHideExampleOrganizations
}: AppearanceSettingsInterface): JSX.Element => {
  const classes = useStyles()
  return (
    <div>
      <Card className={classes.root} raised={false}>
        <CardContent>
          <Typography className={classes.typography}>Color Mode</Typography>
        </CardContent>
        <Divider />
        <CardContent>
          <ColorPreferenceContainer
            colorBlindMode={colorBlindMode}
            updateColorBlindMode={updateColorBlindMode}
          />
          <Typography className={classes.typography}>Units</Typography>
        </CardContent>

        <Divider />
        <CardContent>
          <FormControl component='fieldset' className={classes.formControl}>
            <RadioGroup
              aria-label='units'
              name='units'
              className={classes.group}
              value={measuringSystem}
              onChange={updateMeasuringSystem}
            >
              <FormControlLabel
                value='imperial'
                control={
                  <Radio
                    classes={{
                      root: classes.radio
                    }}
                    color='primary'
                  />
                }
                label='Imperial'
              />
              <FormControlLabel
                value='metric'
                control={
                  <Radio
                    classes={{
                      root: classes.radio
                    }}
                    color='primary'
                  />
                }
                label='Metric'
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
        <Divider />
        {freeTrialUser && (
          <React.Fragment>
            <CardContent>
              <Typography className={classes.typography}>
                Organizations
              </Typography>
            </CardContent>
            <CardContent>
              <ExampleOrganizationVisibility
                hideExampleOrganizations={hideExampleOrganizations}
                setHideExampleOrganizations={setHideExampleOrganizations}
              />
            </CardContent>
          </React.Fragment>
        )}

        <CardActions style={{ float: 'right' }}>
          <Button onClick={resetAppearanceSettings} disabled={disabled}>
            Reset to Default
          </Button>
          <Button
            variant='contained'
            onClick={saveAppearanceSettings}
            color='primary'
            disabled={disabled}
          >
            Save Settings
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default AppearanceSettings
