import {
  ADD_LAYER,
  TOGGLE_LAYER,
  SHOW_LAYER,
  HIDE_LAYER,
  ADD_LAYER_GROUP,
  CLEAR_LAYER_GROUPS,
} from "actiontypes/layers";
import find from "lodash/find";
import get from "lodash/get";

let layerId = 0;

export function addLayer(layerGroupId, layer) {
  return {
    type: ADD_LAYER,
    id: layerId++,
    layerGroupId,
    position: layer.position,
    url: layer.url,
    color: layer.color,
    hidden: layer.hidden || false,
    visible: layer.visible || false,
    sourceLayer: layer.sourceLayer,
    dateCreated: layer.dateCreated,
    dateDriven: layer.dateDriven,
  };
}

export function toggleLayer(layerId) {
  return {
    type: TOGGLE_LAYER,
    id: layerId,
  };
}

export function showLayer(layerId) {
  return {
    type: SHOW_LAYER,
    id: layerId,
  };
}

export function hideLayer(layerId) {
  return {
    type: HIDE_LAYER,
    id: layerId,
  };
}

export function toggleLayerBySource(layerSource, toggleValue) {
  return (dispatch, getState) => {
    const currentState = getState();
    const { layers } = currentState;

    const results = find(layers.groups, {
      layers: [{ sourceLayer: layerSource }],
    });
    const layer = find(get(results, "layers"), { sourceLayer: layerSource });

    if (layer) {
      if (toggleValue) {
        dispatch(showLayer(layer.id));
      } else {
        dispatch(hideLayer(layer.id));
      }
    }
  };
}

let layerGroupId = 0;

export function addLayerGroup(layerGroup) {
  return {
    projectName: layerGroup.projectName,
    name: layerGroup.name,
    type: ADD_LAYER_GROUP,
    id: layerGroupId++,
    style: layerGroup.style,
    clickBehavior: layerGroup.clickBehavior,
    position: layerGroup.position,
  };
}

export function clearLayerGroups() {
  return {
    type: CLEAR_LAYER_GROUPS,
  };
}
