import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import useMount from "hooks/useMount";
import { configureAnchors } from "react-scrollable-anchor";
import { getAppBarHeight } from "../../utils/getAppBarHeight";
import { ROUTE_NOT_FOUND } from "../../constants/routes";
import SummaryPage from "../../components/SummaryPage";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import RBAPI from "api/RoadwayAPI";

const SummaryPageContainer = ({
  history,
  match,
  location,
  measuringSystem,
  setSnackbarMessageAndOpen,
}) => {
  const [analysisData, setAnalysisData] = useState({});
  const [isStreetDataLoaded, setIsStreetDataLoaded] = useState(false);
  const [isNetworkDataLoaded, setIsNetworkDataLoaded] = useState(false);
  const [classificationAnalysisList, setClassificationAnalysisList] = useState(
    []
  );
  const [
    individualRoadsAnalysisList,
    setIndividualRoadsAnalysisList,
  ] = useState({});

  const onMount = () => {
    setIsStreetDataLoaded(false);
    setIsNetworkDataLoaded(false);
    const scanId = match.params.scanId;

    RBAPI.fetchScanStreetAnalysis(scanId)
      .catch(err => {
        if (err?.response?.status === 404) {
          //
          setSnackbarMessageAndOpen(
            "Could not find street analysis",
            MessageTypes.ERROR
          );
          return;
        }
        throw err;
      })
      .then(analysisType => {
        setIndividualRoadsAnalysisList(analysisType);
        setIsStreetDataLoaded(true);
      });

    RBAPI.fetchScanNetworkAnalysis(scanId)
      .catch(err => {
        if (err?.response?.status === 404) {
          //
          setSnackbarMessageAndOpen(
            "Could not find network analysis",
            MessageTypes.ERROR
          );
          return;
        }
        throw err;
      })
      .then(async data => {
        const scan = await RBAPI.fetchScan(scanId).catch(err => {
          if (err?.response?.status === 404) {
            //
            setSnackbarMessageAndOpen(
              "Could not find assessment",
              MessageTypes.ERROR
            );
            return;
          }
          throw err;
        });
        data["displayName"] = scan.displayName || scan.name;
        setAnalysisData(data);

        // determine the data for the material table
        Object.keys(data.highway).forEach(key => {
          setClassificationAnalysisList(classificationList => {
            data.highway[key].key = key;
            return [...classificationList, data.highway[key]];
          });
        });
        setIsNetworkDataLoaded(true);
      })
      .catch(e => {
        if (e.response && e.response.status === 404) {
          history.push(ROUTE_NOT_FOUND);
        }
      });
  };

  // fetch the scan data on mount
  useMount(onMount);

  useEffect(() => {
    classificationAnalysisList.sort((a, b) => {
      return a.name > b.name;
    });
  }, [classificationAnalysisList]);

  // configure the anchors to account for the appbar height
  configureAnchors({ offset: -getAppBarHeight() });

  return (
    <SummaryPage
      location={location}
      analysisData={analysisData}
      measuringSystem={measuringSystem}
      classificationAnalysisList={classificationAnalysisList}
      isNetworkDataLoaded={isNetworkDataLoaded}
      individualRoadsAnalysisList={individualRoadsAnalysisList}
      isStreetDataLoaded={isStreetDataLoaded}
    />
  );
};

SummaryPageContainer.propTypes = {
  measuringSystem: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      scanId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  setSnackbarMessageAndOpen: (message, type) =>
    dispatch(setSnackbarMessageAndOpen(message, type)),
});

const mapStateToProps = state => ({
  user: state.user,
  measuringSystem: state.userData.measuringSystem,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SummaryPageContainer)
);
