import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import grey from "@material-ui/core/colors/grey";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Link } from "react-router-dom";

import { RoadboticsLogoNoText } from "images/logos";
import { ROUTE_ADMIN, ROUTE_DASHBOARD } from "constants/routes";
import { FAQ, TARGET_BLANK } from "constants/links";
import ScanSwitcherSelectContainer from "../containers/ScanSwitcherSelectContainer";
import UserStatus from "../constants/userStatus";

const useStyles = makeStyles({
  paper: ({ offset }: { offset: number }) => ({
    position: "absolute",
    backgroundColor: "white",
    whiteSpace: "nowrap",
    top: "70px",
    right: "10px",
    width: "360px",
    borderRadius: "2px",
    left: offset - 300,
  }),
  arrowUp: ({ offset }: { offset: number }) => ({
    width: 0,
    height: 0,
    border: "solid rgba(0, 0, 0, 0.12)",
    borderWidth: "0 1px 1px 0",
    padding: "10px",
    transform: "rotate(-135deg)",
    background: "white",
    top: "60px",
    position: "absolute",
    right: "39px",
    zIndex: 100000000,
    left: offset,
  }),
  preferenceButton: {
    marginLeft: "auto",
    height: "100%",
    right: -24,
    paddingRight: "15px",
  },
  preferenceLabel: {
    fontSize: "16px",
    alignText: "center",
    fontFamily: "'Open Sans', sans-serif",
    color: "#000000",
    padding: "0px 3px 2px 8px",
  },
  divider: {
    width: "100%",
  },
  name: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 18,
    textAlign: "left",
    padding: "0px 10px 3px",
    textTransform: "none",
    fontWeight: 700,
  },
  email: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 12,
    textAlign: "left",
    padding: "0px 10px 3px",
    textTransform: "none",
  },
  org: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    textAlign: "left",
    padding: "0px 10px",
    textTransform: "none",
    fontWeight: 500,
  },
  user: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 12,
    textAlign: "left",
    padding: "5px 10px 10px",
    textTransform: "none",
    fontWeight: 500,
  },
  text: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    textAlign: "left",
    textTransform: "none",
    padding: "5px, 10px",
    fontWeight: 500,
  },
  button: {
    justifyContent: "start",
    padding: "10px 10px",
    width: "100%",
    borderRadius: "0px",
  },
  smallButton: {
    justifyContent: "start",
    padding: "10px 10px",
    width: "100%", // change back to 50% when implementing privacy policy
    borderRadius: "0px",
  },
  avatar: {
    height: "75px",
    width: "75px",
    margin: "15px 10px",
  },
  profileCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "360px",
  },
  profileDetails: {
    width: "285px",
    marginLeft: "-5px",
    padding: "8px 6px",
  },
  scanSwitcherItem: {
    display: "flex",
    alignItems: "center",
    padding: "10px 5px 5px 10px",
    justifyContent: "space-between",
  },
  scanSwitcherDropdown: {
    width: "100%",
    marginRight: "5px",
  },
  profileDetailsInner: {
    borderLeft: `1px solid ${grey[300]}`,
    padding: "2px 2px",
  },
});

interface OptionsMenuProps {
  handlePreferenceClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  clickAwayOptions: () => void;
  logout: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleSelectChange: (event: object, child?: object) => void;
  firstName: string;
  lastName: string;
  organization: string;
  offset: number;
  isAdmin: boolean;
  email: string;
  userStatus: UserStatus;
}

const OptionsMenu = ({
  handlePreferenceClick,
  logout,
  firstName = "",
  lastName = "",
  organization = "",
  clickAwayOptions,
  offset,
  isAdmin,
  email,
  handleSelectChange,
  userStatus,
}: OptionsMenuProps): React.ReactElement => {
  const classes = useStyles({ offset });

  const renderAdminMenuItem = (): React.ReactFragment | null => {
    if (isAdmin && userStatus !== UserStatus.DISABLED) {
      return (
        <>
          <Link to={ROUTE_ADMIN}>
            <Button className={classes.button} onClick={clickAwayOptions}>
              <Typography className={classes.text}>Administration</Typography>
            </Button>
          </Link>
          <Divider className={classes.divider} />
        </>
      );
    }
    return null;
  };

  return (
    <div>
      <div className={classes.arrowUp} />
      <ClickAwayListener onClickAway={clickAwayOptions}>
        <Paper className={classes.paper} variant="outlined">
          <div className={classes.profileCard}>
            <Avatar className={classes.avatar} src={RoadboticsLogoNoText} />
            <div className={classes.profileDetails}>
              <div className={classes.profileDetailsInner}>
                <Typography className={classes.name}>
                  {`${firstName} ${lastName}`}
                </Typography>
                <Typography noWrap={true} className={classes.email}>
                  {`${email}`.toLowerCase()}
                </Typography>
                <Typography className={classes.org}>{organization}</Typography>
                <div className={classes.scanSwitcherItem}>
                  <ScanSwitcherSelectContainer
                    className={classes.scanSwitcherDropdown}
                    handleChange={handleSelectChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider className={classes.divider} />
          {/*TODO: remove this preferences button and all the associated files
          when ready to release OS Sidebar*/}
          {process.env.REACT_APP_PROJECT_ID !==
            "roadbotics-roadway-api-dev" && (
            <Button className={classes.button} onClick={handlePreferenceClick}>
              <Typography className={classes.text}>Preferences</Typography>
            </Button>
          )}
          <Divider className={classes.divider} />
          {renderAdminMenuItem()}
          <Link to={ROUTE_DASHBOARD}>
            <Button className={classes.button} onClick={clickAwayOptions}>
              <Typography className={classes.text}>Dashboard</Typography>
            </Button>
          </Link>
          <Divider className={classes.divider} />
          {/*TODO: remove this FAQ button and all the associated files
          when ready to release OS Sidebar*/}
          {process.env.REACT_APP_PROJECT_ID !==
            "roadbotics-roadway-api-dev" && (
            <Button
              className={classes.smallButton}
              href={FAQ}
              target={TARGET_BLANK}
            >
              <Typography className={classes.text}>FAQ</Typography>
            </Button>
          )}
          <Divider className={classes.divider} />
          <Button className={classes.smallButton} onClick={logout}>
            <Typography className={classes.text}>Sign out</Typography>
          </Button>
        </Paper>
      </ClickAwayListener>
    </div>
  );
};

export default OptionsMenu;
