import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import AppBarContainer from "containers/AppBarContainer";
import Button from "components/form/Button";
import LoadingLogo from "./LoadingLogo";
import { validateEmail } from "../utils/validateEmail";
import background from "images/imageloggersignup-bg.jpg";
import { getAppBarHeight } from "utils/getAppBarHeight";

interface AdditionalInputProps {
  label: string;
  name: string;
  type: string;
  validation?: {
    required?: string;
  };
}

interface CreateProfileFormProps {
  register: any; // eslint-disable-line
  watch: any; // eslint-disable-line
  errors: any; // eslint-disable-line
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  location: {
    pathname: string;
  };
  formSubmitted: boolean;
  title: string;
  additionalInputs?: AdditionalInputProps[];
}

const useStyles = makeStyles({
  textField: {
    margin: "2% 1%",
    width: "95%",
    textAlign: "center",
  },
  card: {
    margin: `${getAppBarHeight()}px auto`,
    borderRadius: "20px",
    width: "75%",
    border: "1px solid black",
  },
  cardHeader: {
    backgroundColor: "#fad201",
  },
  headerText: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 800,
  },
  actions: {
    float: "right",
    paddingBottom: "15px",
  },
  container: {
    minHeight: "100vh",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  },
  root: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    overflow: "scroll",
  },
});

const CreateProfileForm: React.FC<CreateProfileFormProps> = ({
  title,
  register,
  watch,
  errors,
  onSubmit,
  location,
  formSubmitted,
  additionalInputs = [],
}: CreateProfileFormProps) => {
  const classes = useStyles();
  const password = React.useRef({});
  password.current = watch("password", "");
  return (
    <div>
      <AppBarContainer urlLocation={location.pathname} isTOSView={true} />
      <Grid container className={classes.container}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          {formSubmitted ? (
            <div className={classes.root}>
              <LoadingLogo />
            </div>
          ) : (
            <Card className={classes.card}>
              <form style={{ textAlign: "center" }} onSubmit={onSubmit}>
                <CardHeader
                  className={classes.cardHeader}
                  title={
                    <Typography className={classes.headerText} variant="h4">
                      {title}
                    </Typography>
                  }
                />
                <Divider />
                <CardContent className={classes.content}>
                  <Typography variant="body1" gutterBottom>
                    Please fill out the following information:
                  </Typography>
                  <div>
                    <TextField
                      className={classes.textField}
                      error={errors?.email !== undefined}
                      helperText={errors?.email?.message}
                      label="Email"
                      name={"email"}
                      inputRef={register({
                        required: "This field is required.",
                        validate: (value: string) =>
                          validateEmail(value) || "Please use a valid email",
                      })}
                    />
                  </div>
                  <div>
                    <TextField
                      className={classes.textField}
                      error={errors?.organizationName !== undefined}
                      helperText={errors?.organizationName?.message}
                      label="Organization Name"
                      name={"organizationName"}
                      inputRef={register({
                        required: "This field is required.",
                      })}
                    />
                  </div>
                  <div>
                    <TextField
                      className={classes.textField}
                      error={errors?.firstName !== undefined}
                      helperText={errors?.firstName?.message}
                      label="First Name"
                      name="firstName"
                      inputRef={register({
                        required: "This field is required.",
                      })}
                    />
                  </div>
                  <div>
                    <TextField
                      className={classes.textField}
                      error={errors?.lastName !== undefined}
                      helperText={errors?.lastName?.message}
                      label="Last Name"
                      name="lastName"
                      inputRef={register({
                        required: "This field is required.",
                      })}
                    />
                  </div>
                  <div>
                    <TextField
                      className={classes.textField}
                      error={errors?.password !== undefined}
                      helperText={errors?.password?.message}
                      label="Password"
                      name="password"
                      inputRef={register({
                        required: "This field is required.",
                        minLength: {
                          value: 8,
                          message: "Minimum password length is 8 characters.",
                        },
                      })}
                      inputProps={{ type: "password" }}
                    />
                  </div>
                  <div>
                    <TextField
                      className={classes.textField}
                      error={errors?.verifyPassword !== undefined}
                      helperText={errors?.verifyPassword?.message}
                      label="Verify Password"
                      name="verifyPassword"
                      inputRef={register({
                        required: "This field is required",
                        validate: (value: string) =>
                          value === password.current ||
                          "Passwords do not match",
                      })}
                      inputProps={{ type: "password" }}
                    />
                  </div>
                  {additionalInputs.map(({ label, name, type, validation }) => (
                    <TextField
                      className={classes.textField}
                      key={`create-profile-form-${name}`}
                      error={errors[name] !== undefined}
                      helperText={errors[name]?.message}
                      label={label}
                      name={name}
                      inputRef={register({ ...validation })}
                      inputProps={{ type }}
                    />
                  ))}
                  <br />
                  <div className={classes.actions}>
                    <Button variant="contained" color="primary" type="submit">
                      <Typography>Create your profile</Typography>
                    </Button>
                  </div>
                </CardContent>
              </form>
            </Card>
          )}
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </div>
  );
};

export default CreateProfileForm;
