import React, { FormEvent } from "react";
import Dialog from "components/Dialog/Dialog";
import TextField from "components/form/TextField";
import ActivityIndicatorButton from "components/ActivityIndicatorButton";
import { Select, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { Controller, useForm } from "react-hook-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AssessmentTypes } from "../../constants/assessmentTypes";

interface CopyDialog {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  handleCreate: (formState: object) => void;
  values: object;
  isCreateActive: boolean;
}

const assessmentTypes = [
  {
    name: "Conditional Ratings",
    id: AssessmentTypes.NORMAL,
  },
  {
    name: "Image Logger",
    id: AssessmentTypes.IMAGELOGGER,
  },
  {
    name: "Sidewalk",
    id: AssessmentTypes.SIDEWALK,
  },
];

const useStyles = makeStyles({
  assessmentType: {
    width: "100%",
  },
  assessmentText: {
    width: "150px",
    float: "left",
    marginTop: "5px",
  },
});

const ScansAdminCreateFormDialog = ({
  title,
  isOpen,
  handleClose,
  handleCreate,
  values: initialFormState,
  isCreateActive,
}: CopyDialog): JSX.Element => {
  const classes = useStyles();
  const { register, control, errors, handleSubmit } = useForm({
    defaultValues: initialFormState,
  });

  const handleFormSubmit = (event: FormEvent): void => {
    event.preventDefault();
    handleSubmit(data => {
      handleCreate(data);
    })(event);
  };

  const renderForm = (): JSX.Element => (
    <form onSubmit={handleFormSubmit}>
      <div>
        <TextField
          label="New Scan Name"
          name="name"
          error={errors?.name !== undefined}
          helperText={errors?.name?.message}
          inputRef={register({
            required: "This field is required",
          })}
        />
        <TextField
          label="New Scan Display Name"
          name="displayName"
          error={errors?.displayName !== undefined}
          helperText={errors?.displayName?.message}
          inputRef={register({
            required: "This field is required",
          })}
        />
        <div className={classes.assessmentType}>
          <Typography className={classes.assessmentText}>
            Assessment Type:
          </Typography>
          <FormControl error={errors?.assessmentType !== undefined}>
            <Controller
              as={
                <Select displayEmpty name="assessmentType" inputRef={register}>
                  {assessmentTypes.map(type => {
                    return (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              name="assessmentType"
              control={control}
              rules={{
                required: "This field is required",
              }}
            />
            <FormHelperText>{errors?.assessmentType?.message}</FormHelperText>
          </FormControl>
        </div>
      </div>
    </form>
  );

  const renderFormActions = (): JSX.Element => (
    <ActivityIndicatorButton
      onClick={handleFormSubmit}
      isActive={isCreateActive}
      progressSize={14}
      disabled={false}
      type="submit"
    >
      Submit
    </ActivityIndicatorButton>
  );

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      handleClose={handleClose}
      actions={renderFormActions()}
    >
      {renderForm()}
    </Dialog>
  );
};

export default ScansAdminCreateFormDialog;
