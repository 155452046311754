import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { setHideExampleOrganizations } from "../actions/userData";
import State from "../interfaces/state";
import RBAPI from "api/RoadwayAPI";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 30px",
  },
  checkbox: {
    marginLeft: "-2%",
  },
});

const ExampleOrganizationVisibility = (): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hideExampleOrganizations = useSelector(
    (state: State) => state.userData.hideExampleOrganizations
  );
  const userUId = useSelector((state: State) => state.user.userUId);

  const onChange = (): void => {
    const newHideExampleOrganizations = !hideExampleOrganizations;
    dispatch(setHideExampleOrganizations(newHideExampleOrganizations));
    RBAPI.updateUserSettings(userUId, {
      hideExampleOrganizations: newHideExampleOrganizations,
    }).catch((e: any) => console.error(e));
  };

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            checked={hideExampleOrganizations}
            onChange={onChange}
            name="hideExampleOrganizations"
            color="primary"
            className={classes.checkbox}
          />
        }
        label="Hide Example Organizations"
      />
    </div>
  );
};

export default ExampleOrganizationVisibility;
