import { osDrawerWidth } from "roadbotics-os-sidebar";

// TODO this is a temp const that allows this feature to deploy only in dev
// Remove check when the side bar is launched to dev, staging, and production

// this width is set to 0 in all other envs
const OS_DRAWER_WIDTH =
  process.env.REACT_APP_PROJECT_ID === "roadbotics-roadway-api-dev"
    ? osDrawerWidth
    : 0;

interface UiConstants {
  osDrawerWidth: number;
}

export const uiConstants: UiConstants = { osDrawerWidth: OS_DRAWER_WIDTH };

// allow callers to change the drawer width
export function updateOsDrawerWidth(val: number): void {
  uiConstants.osDrawerWidth = val;
}
