import { MeasuringSystem } from "../constants/measuringSystem";

const conversionMap = {
  m2ToMi2: 0.00000038610215855,
  m2ToFt2: 10.7639,
  m2ToKm2: 0.000001,
  mToft: 3.28084,
  mToKm: 0.001,
  kmToM: 1000,
  mToMi: 0.000621371,
  ftToM: 0.3048,
  ftToMi: 0.000189394,
  miToFt: 5280,
  ftToKm: 0.0003048,
};

const displayNameMap: { [unit: string]: string } = {
  m: "Meters",
  ft: "Feet",
};

export interface Quantity {
  unit: string;
  scalar: number;
}

const round = function(num: number, dec: number): number {
  const numSign = num >= 0 ? 1 : -1;
  return (
    Math.round(num * Math.pow(10, dec) + numSign * 0.0001) / Math.pow(10, dec)
  );
};

export const meterSquaredToMileSquared = function(
  meters: number,
  precision = 3
): Quantity {
  const scalar = meters * conversionMap["m2ToMi2"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "mi2" };
};

export const meterSquaredToFeetSquared = function(
  meters: number,
  precision = 3
): Quantity {
  const scalar = meters * conversionMap["m2ToFt2"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "ft2" };
};

export const meterSquaredToKilometerSquared = function(
  meters: number,
  precision = 3
): Quantity {
  const scalar = meters * conversionMap["m2ToKm2"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "km2" };
};

export const meterToFeet = function(meter: number, precision = 3): Quantity {
  const scalar = meter * conversionMap["mToft"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "ft" };
};

export const meterToKilometer = function(
  meter: number,
  precision = 3
): Quantity {
  const scalar = meter * conversionMap["mToKm"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "km" };
};

export const kilometersToMeters = function(
  kilometer: number,
  precision = 3
): Quantity {
  const scalar = kilometer * conversionMap["kmToM"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "m" };
};

export const meterToMiles = function(meter: number, precision = 3): Quantity {
  const scalar = meter * conversionMap["mToMi"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "mi" };
};

export const feetToMeter = function(feet: number, precision = 3): Quantity {
  const scalar = feet * conversionMap["ftToM"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "m" };
};

export const feetToMiles = function(feet: number, precision = 3): Quantity {
  const scalar = feet * conversionMap["ftToMi"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "mi" };
};

export const milesToFeet = function(miles: number, precision = 3): Quantity {
  const scalar = miles * conversionMap["miToFt"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "ft" };
};

export const feetToKilometers = function(
  feet: number,
  precision = 3
): Quantity {
  const scalar = feet * conversionMap["ftToKm"];
  const rounded = round(scalar, precision);
  return { scalar: rounded, unit: "km" };
};

export function getDisplayUnit(unit: string): string {
  if (unit in displayNameMap) {
    return displayNameMap[unit];
  } else {
    throw new Error(`${unit} is not a valid unit preference`);
  }
}

export function getLengthInPreferredUnit(
  length: number,
  measuringSystem: MeasuringSystem,
  scale: "small" | "large",
  precision = 3
): Quantity {
  switch (measuringSystem) {
    case MeasuringSystem.Metric:
      switch (scale) {
        case "small":
          return { scalar: length, unit: "m" };
        case "large":
          return meterToKilometer(length, precision);
      }
      break;

    case MeasuringSystem.Imperial:
      switch (scale) {
        case "small":
          return meterToFeet(length, precision);
        case "large":
          return meterToMiles(length, precision);
      }
      break;

    default:
      throw new Error(`${measuringSystem} is not a valid unit preference`);
  }
}

export function displayLength(
  length: number,
  measuringSystem: MeasuringSystem,
  scale: "small" | "large"
): string {
  const qty = getLengthInPreferredUnit(length, measuringSystem, scale, 1);
  return `${qty.scalar.toFixed(1)} ${getDisplayUnit(qty.unit)}`;
}
