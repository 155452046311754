import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuBookRounded from "@material-ui/icons/MenuBookRounded";
import OndemandVideoRounded from "@material-ui/icons/OndemandVideoRounded";
import moment from "moment-timezone";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

const styles = {
  paper: {
    position: "absolute",
    backgroundColor: "white",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    top: "70px",
    width: "290px",
    borderRadius: "2px",
  },
  arrowUp: {
    width: 0,
    height: 0,
    borderLeft: "11px solid transparent",
    borderRight: "11px solid transparent",
    borderBottom: "11px solid white",
    top: "59px",
    position: "absolute",
    zIndex: 100000000,
  },
  divider: {
    width: "100%",
  },
  notificationWrapper: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  notificationButton: {
    textTransform: "none",
    padding: "5px",
    width: "100%",
    justifyContent: "start",
  },
  notificationIcon: {
    float: "left",
    paddingTop: "4px",
    paddingLeft: "4px",
  },
  notificationContent: {
    paddingLeft: "6px",
  },
};

const NotificationMenu = ({
  classes,
  notifications,
  offset,
  clickAwayNotifications,
}) => (
  <div>
    <div className={classes.arrowUp} style={{ left: offset + 23 }} />
    <ClickAwayListener onClickAway={clickAwayNotifications}>
      <Paper className={classes.paper} style={{ left: offset - 235 }}>
        {notifications.length ? (
          notifications.map(child => (
            <div key={child.date} className={classes.notificationWrapper}>
              <Button
                className={classes.notificationButton}
                key={child.date}
                href={child.link}
                target="_blank"
              >
                <div className={classes.notificationIcon}>
                  {child.type === "blog" ? (
                    <MenuBookRounded />
                  ) : (
                    <OndemandVideoRounded />
                  )}
                </div>
                <div className={classes.notificationContent}>
                  {`${child.title} ${moment(child.date).format("MM/DD")}`}
                </div>
              </Button>
              <br />
            </div>
          ))
        ) : (
          <CircularProgress />
        )}
      </Paper>
    </ClickAwayListener>
  </div>
);

NotificationMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  clickAwayNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  offset: PropTypes.number.isRequired,
};

export default withStyles(styles)(NotificationMenu);
