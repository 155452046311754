import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openOptionsDropdown,
  closeOptionsDropdown,
  openNotificationDropdown,
  closeNotificationDropdown,
} from "../../actions/visibility";
import AppBar from "components/AppBar";
import { track, useTracking } from "react-tracking";
import moment from "moment-timezone";
import { setLastLogin } from "actions/login";
import * as Sentry from "@sentry/browser/dist/index";
import State from "../../interfaces/state";

interface AppBarProps {
  urlLocation: string;
  isTOSView?: boolean;
}

const AppBarContainer: React.FC<AppBarProps> = ({
  urlLocation,
  isTOSView = false,
}) => {
  const tracking = useTracking();
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.user);

  const isTopBannerOpen = useSelector(
    (state: State) => state.visibility.isTopBannerOpen
  );
  const optionsDropdownVisibility = useSelector(
    (state: State) => state.visibility.optionsDropdownVisibility
  );
  const notificationDropdownVisibility = useSelector(
    (state: State) => state.visibility.notificationDropdownVisibility
  );
  const activeScan = useSelector((state: State) => state.userData.activeScan);
  const notificationsPriorToLastLogin = useSelector(
    (state: State) => state.notifications.priorToLastLogin
  );
  const [notificationOffset, setNotificationOffset] = useState(0);
  const [optionsOffset, setOptionsOffset] = useState(0);
  const [optionsWidth, setOptionsWidth] = useState(0);

  const toggleOptionsDropdownVisibility = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const child: HTMLElement = e.currentTarget.children[0] as HTMLElement;
    const grandchild: HTMLElement = child.children[1] as HTMLElement;
    const offset = !Number.isNaN(grandchild.offsetLeft)
      ? e.currentTarget.offsetLeft + grandchild.offsetLeft
      : e.currentTarget.offsetLeft + child.offsetLeft;

    setOptionsOffset(offset);
    setOptionsWidth(e.currentTarget.offsetWidth);
    tracking.trackEvent({
      event: "mouse-click",
      action: `toggle-options-dropdown-visiblity-${
        optionsDropdownVisibility ? "close" : "open"
      }`,
      userUId: user.userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `toggle-options-dropdown-visiblity-${
        optionsDropdownVisibility ? "close" : "open"
      }`,
      level: Sentry.Severity.Info,
    });

    if (optionsDropdownVisibility) {
      dispatch(closeOptionsDropdown());
    } else {
      dispatch(openOptionsDropdown());
      dispatch(closeNotificationDropdown());
    }
  };

  const toggleNotificationDropdownVisibility = (
    e: React.MouseEvent<HTMLElement>
  ): void => {
    setNotificationOffset(e.currentTarget.offsetLeft);
    tracking.trackEvent({
      event: "mouse-click",
      action: `toggle-notification-dropdown-visiblity-${
        notificationDropdownVisibility ? "close" : "open"
      }`,
      userUId: user.userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `toggle-notification-dropdown-visiblity-${
        notificationDropdownVisibility ? "close" : "open"
      }`,
      level: Sentry.Severity.Info,
    });

    if (notificationDropdownVisibility) {
      dispatch(closeNotificationDropdown());
    } else {
      dispatch(openNotificationDropdown());
      dispatch(closeOptionsDropdown());
      dispatch(setLastLogin(moment().format("YYYY-MM-DD")));
    }
  };

  const _closeOptionsDropdown = (): void => {
    dispatch(closeOptionsDropdown());
  };

  return (
    <AppBar
      isTopBannerOpen={isTopBannerOpen}
      handleUserOptionsClick={toggleOptionsDropdownVisibility}
      handleUserNotificationClick={toggleNotificationDropdownVisibility}
      optionsDropdownVisibility={optionsDropdownVisibility}
      user={user}
      offset={notificationOffset}
      optionsOffset={optionsOffset}
      optionsWidth={optionsWidth}
      notificationsPriorToLastLogin={notificationsPriorToLastLogin}
      urlLocation={urlLocation}
      activeScan={activeScan}
      isTOSView={isTOSView}
      closeOptionsDropdown={_closeOptionsDropdown}
    />
  );
};

export default track({ component: "app-bar" })(AppBarContainer);
