import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";

const useStyles = makeStyles({
  root: {
    marginLeft: "15px",
  },
  orgName: {
    background: RB_LIGHT_GREY,
  },
  orgBox: {
    background: RB_LIGHT_GREY,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    background: RB_LIGHT_GREY,
    flexGrow: 1,
    margin: "10px",
  },
  viewButtons: {
    background: RB_LIGHT_GREY,
    marginRight: "10px",
    marginTop: "20px",
  },
});
interface OrgSkeletonInterface {
  nBlocksPerOrg: number;
  orgTitleWidth: number;
}

const layout: OrgSkeletonInterface[] = [
  { nBlocksPerOrg: 3, orgTitleWidth: 500 },
  { nBlocksPerOrg: 4, orgTitleWidth: 350 },
];

const OrganizationGridSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid key="skeleton-grid" container>
        <Grid
          key="skeleton-org-grid-item"
          item
          xs={11}
          container
          spacing={5}
          className={classes.root}
        >
          {layout.map((v, i) => {
            return (
              <Grid key={`${i}-skeleton-org`} item xs={12}>
                <Skeleton
                  className={classes.orgName}
                  variant="text"
                  height={100}
                  width={v.orgTitleWidth}
                />
                <Grid key="skeleton-org-grid" container spacing={5}>
                  {[...Array(v.nBlocksPerOrg).keys()].map(i => {
                    return (
                      <Grid
                        key={`${i}-skeleton-scan`}
                        item
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <Skeleton
                          className={classes.orgBox}
                          variant="rect"
                          height={170}
                        />
                        <div className={classes.buttonContainer}>
                          <Skeleton
                            className={classes.button}
                            variant="text"
                            height={50}
                            width={120}
                          />
                          <Skeleton
                            className={classes.button}
                            variant="text"
                            height={50}
                            width={120}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          key="skeleton-viewbuttons-item"
          container
          item
          xs={1}
          justify="flex-end"
        >
          <Skeleton
            className={classes.viewButtons}
            variant="rect"
            height={30}
            width={90}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrganizationGridSkeleton;
