import {
  ADD_NOTIFICATIONS,
  SET_NOTIFICATIONS_PRIOR_TO_LAST_LOGIN,
} from "actiontypes/notifications";

export function addNotifications(notifications) {
  return {
    type: ADD_NOTIFICATIONS,
    notifications,
  };
}

export function setNotificationsPriorToLastLogin(priorToLastLogin) {
  return {
    type: SET_NOTIFICATIONS_PRIOR_TO_LAST_LOGIN,
    priorToLastLogin,
  };
}
