import React from "react";
import PropTypes from "prop-types";

import RatingsLayerToggleGroup from "containers/RatingsLayerToggleGroup";

import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "70%",
  },
  dividerFullWidth: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
};

const RatingsLayer = ({ classes, toggleGroups }) => (
  <div className={classes.root} data-testid="ratings-layer">
    {toggleGroups
      .filter(
        toggleGroup =>
          toggleGroup.name !== "imageLoggerSegments" &&
          toggleGroup.name !== "imageLoggerPoints"
      )
      .map(toggleGroup => (
        <React.Fragment key={`Ratings Layer Toggle Group ${toggleGroup.id}`}>
          <RatingsLayerToggleGroup toggleGroup={toggleGroup} />
          <Divider className={classes.dividerFullWidth} />
        </React.Fragment>
      ))}
  </div>
);

RatingsLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleGroups: PropTypes.array.isRequired,
};

export default withStyles(styles)(RatingsLayer);
