import { AssessmentTypes } from "constants/assessmentTypes";

interface SearchKeys {
  assessmentType?: string;
}

export const getAssessmentType = (): AssessmentTypes | null => {
  // TODO remove this check when os is released so that the
  // appropriate assessmentType is returned. null will be returned on every
  // envinroment except dev, so that all assessments are retrieved.
  if (process.env.REACT_APP_PROJECT_ID !== "roadbotics-roadway-api-dev")
    return null;

  // remove the first char, which is a ? and split via the & and then =
  // to get an object where the url parameters are converted
  // into key value pairs.
  //     i.e ?key1=value1?key2=value2 === {key1: value1, key2:value2}

  const searchParams = {} as SearchKeys;
  window.location.search
    .substr(1)
    .split("&")
    .map(s => s.split("="))
    .forEach(([key, val]) => Object.assign(searchParams, { [key]: val }));

  // set to normal if on roadway or the url parameter `assessmentType`
  // is set to normal
  const host = window.location.host;
  if (
    host.startsWith("roadway.") ||
    searchParams?.assessmentType === AssessmentTypes.NORMAL
  ) {
    return AssessmentTypes.NORMAL;
  }

  // set to imageLogger (videoLogger) if on roadsense`assessmentType`
  // is set to imageLogger
  if (
    host.startsWith("roadsense.") ||
    searchParams?.assessmentType === AssessmentTypes.IMAGELOGGER
  ) {
    return AssessmentTypes.IMAGELOGGER;
  }

  //default
  return AssessmentTypes.NORMAL;
};
