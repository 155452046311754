import React, { useState, useCallback, useEffect } from 'react'

import Avatar from '@material-ui/core/Avatar'
import SettingsIcon from '../SVGs/SettingsSVG'
import SettingsDialog from '../Settings/SettingsDialog'
import { RBAPI } from '../../api/roadboticsAPI'
import { RB_DARK_GREY } from '../../constants/colors'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '10px',
    fontWeight: 900,
    color: RB_DARK_GREY,
    padding: theme.spacing(1)
  },
  settingsDiv: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  },

  dialog: {
    width: '100%'
  },
  avatar: {
    marginBottom: '-5px'
  }
}))

interface SettingsProps {
  userUId: string
  isFreeTrialUser: boolean
}

const Settings = ({ userUId, isFreeTrialUser }: SettingsProps): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [userSettings, setUserSettings] = useState({
    colorMode: '',
    measuringSystem: 'imperial'
  })

  const getUserSettings = useCallback(async () => {
    return await RBAPI.getUserSettings(userUId)
      .then((resp: any) => {
        if (resp) {
          setUserSettings(resp.data.data)
        }
      })
      .catch((e: ErrorEvent) => console.error(e))
  }, [userUId])

  useEffect(() => {
    if (userUId) {
      getUserSettings()
    }
  }, [userUId, getUserSettings])

  const openSettings = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  const closeSettings = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsDialogOpen(false)
  }

  const classes = useStyles()

  return (
    <div className={classes.settingsDiv}>
      <Tooltip title='Settings' placement='right'>
        <div onClick={openSettings} className={classes.avatar}>
          <Avatar
            height='40px'
            width='40px'
            fill={RB_DARK_GREY}
            variant={'square'}
            component={SettingsIcon}
          />
        </div>
      </Tooltip>
      <SettingsDialog
        setIsDialogOpen={openSettings}
        isDialogOpen={isDialogOpen}
        userUId={userUId}
        isFreeTrialUser={isFreeTrialUser}
        userSettings={userSettings}
        closeSettings={closeSettings}
      />
    </div>
  )
}

export default Settings
