import {
  ADD_NOTIFICATIONS,
  SET_NOTIFICATIONS_PRIOR_TO_LAST_LOGIN,
} from "actiontypes/notifications";

const initialState = {
  notifications: [],
  priorToLastLogin: 0,
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
      };
    case SET_NOTIFICATIONS_PRIOR_TO_LAST_LOGIN:
      return {
        ...state,
        priorToLastLogin: action.priorToLastLogin,
      };
    default:
      return state;
  }
};

export default notifications;
