import React, { useState } from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import track from "react-tracking";

import { default as MUIAppBar } from "@material-ui/core/AppBar";
import { default as MUIToolbar } from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";

import ToolbarToggleGroupContainer from "containers/ToolbarToggleGroupContainer";
import RBWebsiteButton from "components/RBWebsiteButton";

import OptionsMenuContainer from "containers/OptionsMenuContainer/OptionsMenuContainer";
import NotificationMenuContainer from "containers/NotificationMenuContainer";
import Avatar from "@material-ui/core/Avatar";
import { RoadboticsLogoNoText, RoadboticsLogoWithText } from "images/logos";
import {
  PersonRounded,
  NotificationsNoneRounded,
  NotificationsActiveRounded,
  ExpandMoreRounded,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { drawerWidth } from "./Sidebar";
import { getAppBarHeight } from "../utils/getAppBarHeight";
import { PRIMARY_HOVER_COLOR, RB_YELLOW } from "../constants/colors";
import { ROUTE_MAP } from "../constants/routes";
import { topBannerHeight } from "../views/Banner";
import { uiConstants } from "../constants/ui";

const styles = theme => ({
  topBanner: {
    top: `${topBannerHeight}px`,
  },
  appBar: {
    backgroundColor: "#ffffff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarOSDrawerShift: {
    left: uiConstants.osDrawerWidth,
    width: `calc(100% - ${uiConstants.osDrawerWidth}px)`,
  },
  appBarShift: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  toolbarDense: {
    minHeight: "50px",
  },
  gutters: {
    paddingLeft: "0px",
  },
  menuButton: {
    marginRight: "auto",
    color: "#000000",
    height: `${getAppBarHeight()}px`,
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
  },
  backButton: {
    marginRight: "auto",
    color: "#000000",
    height: `${getAppBarHeight()}px`,
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
    width: "130px",
    left: "64px",
  },
  roadboticsButton: {
    marginRight: "auto",
    color: "#000000",
    height: `${getAppBarHeight()}px`,
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
  },
  logo: {
    width: "180px",
  },
  roadboticsButtonNoMenu: {
    marginRight: "auto",
    color: "#000000",
    height: `${getAppBarHeight()}px`,
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
    left: "64px",
  },
  notificationButton: {
    marginLeft: "auto",
    height: `${getAppBarHeight()}px`,
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
    color: "black",
  },
  rightButtons: {
    float: "right",
  },
  leftButtons: {
    float: "left",
  },
  optionsButton: {
    marginLeft: "auto",
    height: `${getAppBarHeight()}px`,
    paddingRight: "15px",
    paddingLeft: "15px",
    "&:hover": {
      color: PRIMARY_HOVER_COLOR,
    },
    color: "black",
  },
  menuLabel: {
    fontSize: "16px",
    alignText: "center",
    fontFamily: "'Open Sans', sans-serif",
    padding: "0px 0px 2px 5px",
  },
  toolbarItems: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
  avatar: {
    height: "30px",
    width: "30px",
  },
  expandOpen: {
    color: PRIMARY_HOVER_COLOR,
  },
  expandClosed: {
    color: "black",
  },
  settingsLabel: {
    fontSize: "16px",
    alignText: "center",
    fontFamily: "'Open Sans', sans-serif",
    padding: "0px 7px 2px 0px",
    textTransform: "none",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  notificationActive: {
    fontSize: "30px",
    color: RB_YELLOW,
  },
  notificationInactive: {
    fontSize: "30px",
  },
  scanTypography: {
    fontFamily: "'Open Sans', sans-serif",
  },
});

const AppBar = ({
  classes,
  handleUserOptionsClick,
  optionsDropdownVisibility,
  handleUserNotificationClick,
  user,
  notificationsPriorToLastLogin,
  offset,
  optionsOffset,
  urlLocation,
  activeScan,
  tracking,
  isTOSView,
  isTopBannerOpen,
  closeOptionsDropdown,
}) => {
  const handleLogoClick = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: `app-bar-logo-click`,
      userUId: user.userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "Clicked on logo",
      level: Sentry.Severity.Info,
    });
    if (optionsDropdownVisibility) {
      closeOptionsDropdown();
    }
  };

  const handleBackToMap = () => {
    tracking.trackEvent({
      event: "mouse-click",
      action: `app-bar-back-to-map-click`,
      userUId: user.userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "Clicked on back to map",
      level: Sentry.Severity.Info,
    });
  };

  const regex = /\/map\/.*/g;

  const renderNoMenuLogoButton = () => {
    // TODO: remove this check when releasing the OS
    if (process.env.REACT_APP_PROJECT_ID !== "roadbotics-roadway-api-dev") {
      return (
        <Button
          color="inherit"
          onClick={handleLogoClick}
          className={classes.roadboticsButtonNoMenu}
        >
          <img
            className={classes.logo}
            src={RoadboticsLogoWithText}
            alt="appBarLogo"
          />
        </Button>
      );
    }
  };

  const [notificationClicked, setNotificationClicked] = useState(false);

  return (
    <Grid container>
      <MUIAppBar
        position="fixed"
        id="appBarId"
        className={classNames(classes.appBar, {
          [classes.topBanner]: isTopBannerOpen,
          [classes.appBarOSDrawerShift]: !isTOSView,
        })}
      >
        <MUIToolbar
          variant="dense"
          classes={{
            dense: classes.toolbarDense,
            gutters: classes.gutters,
          }}
        >
          {urlLocation.match(regex) ? (
            <Grid item sm={5} md={4}>
              <div className={classes.leftButtons}>
                {/*TODO: remove this check when releasing the OS*/}
                {process.env.REACT_APP_PROJECT_ID !==
                  "roadbotics-roadway-api-dev" && (
                  <Link to="/">
                    <Button
                      color="inherit"
                      onClick={handleLogoClick}
                      className={classes.roadboticsButton}
                    >
                      <img
                        className={classes.logo}
                        src={RoadboticsLogoWithText}
                        alt="appBarLogo"
                      />
                    </Button>
                  </Link>
                )}
              </div>
            </Grid>
          ) : (
            <Grid item sm={5} md={4}>
              <div className={classes.leftButtons}>
                {isTOSView ? (
                  renderNoMenuLogoButton()
                ) : (
                  <Link to="/">{renderNoMenuLogoButton()}</Link>
                )}
                {activeScan !== "" ? (
                  <Link to={`${ROUTE_MAP}/${activeScan}`}>
                    <Button
                      color="inherit"
                      onClick={handleBackToMap}
                      className={classes.backButton}
                    >
                      <Typography className={classes.scanTypography}>
                        Back to map
                      </Typography>
                    </Button>
                  </Link>
                ) : null}
              </div>
            </Grid>
          )}

          <Grid item sm={6} md={7}>
            {urlLocation.includes(ROUTE_MAP) && (
              <div className={classes.toolbarItems}>
                <ToolbarToggleGroupContainer />
              </div>
            )}
          </Grid>

          {!isTOSView && (
            <Grid item sm={5} md={4}>
              <div className={classes.rightButtons}>
                {/* TODO remove development check */}
                {process.env.REACT_APP_PROJECT_ID !==
                  "roadbotics-roadway-api-dev" && (
                  <Button
                    color="inherit"
                    onClick={event => {
                      handleUserNotificationClick(event);
                      setNotificationClicked(true);
                    }}
                    className={classes.notificationButton}
                  >
                    {(notificationsPriorToLastLogin > 0 ||
                      user.lastLogin === undefined) &&
                    !notificationClicked ? (
                      <Badge
                        badgeContent={notificationsPriorToLastLogin}
                        color="primary"
                      >
                        <NotificationsActiveRounded
                          className={classes.notificationActive}
                        />
                      </Badge>
                    ) : (
                      <NotificationsNoneRounded
                        className={classes.notificationInactive}
                      />
                    )}
                  </Button>
                )}
                <Button
                  color="inherit"
                  onClick={handleUserOptionsClick}
                  className={classes.optionsButton}
                >
                  <Hidden mdDown>
                    <div className={classes.settingsLabel}>
                      {`${user.firstName || ""} ${user.lastName || ""}`}
                    </div>
                  </Hidden>

                  {/*TODO: remove this check when releasing the OS*/}
                  {process.env.REACT_APP_PROJECT_ID !==
                  "roadbotics-roadway-api-dev" ? (
                    <Avatar
                      className={classes.avatar}
                      src={RoadboticsLogoNoText}
                    />
                  ) : (
                    <PersonRounded />
                  )}
                  {optionsDropdownVisibility ? (
                    <ExpandMoreRounded className={classes.expandOpen} />
                  ) : (
                    <ExpandMoreRounded />
                  )}
                </Button>
              </div>
            </Grid>
          )}

          <OptionsMenuContainer offset={optionsOffset} />
          <NotificationMenuContainer offset={offset} />

          {process.env.REACT_APP_ISDEMO === "1" && <RBWebsiteButton />}
        </MUIToolbar>
      </MUIAppBar>
    </Grid>
  );
};

AppBar.defaultProps = {
  user: {
    organization: null,
    firstName: null,
    lastName: null,
    userUId: null,
  },
  notificationsPriorToLastLogin: 0,
  offset: 0,
  optionsOffset: 0,
  activeScan: "",
};

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleUserOptionsClick: PropTypes.func.isRequired,
  optionsDropdownVisibility: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    organization: PropTypes.string,
    lastLogin: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userUId: PropTypes.string,
  }),
  handleUserNotificationClick: PropTypes.func.isRequired,
  notificationsPriorToLastLogin: PropTypes.number,
  offset: PropTypes.number,
  optionsOffset: PropTypes.number,
  urlLocation: PropTypes.string.isRequired,
  activeScan: PropTypes.string,
  tracking: PropTypes.object.isRequired,
  isTOSView: PropTypes.bool.isRequired,
  isTopBannerOpen: PropTypes.bool.isRequired,
};

export default track()(withStyles(styles)(AppBar));
