export enum clickBehaviorConstants {
  roadwaySegment = "roadwaySegment",
  roadwayPoint = "roadwayPoint",
  roadwayPartnerLayer = "roadwayPartnerLayer",
  roadwayCrackseal = "roadwayCrackseal",
  roadwayPothole = "roadwayPothole",
  roadwaySidewalkSegment = "roadwaySidewalkSegment",
  roadwaySidewalkPoint = "roadwaySidewalkPoint",
  roadwayImageLoggerPoint = "roadwayImageLoggerPoint",
}
