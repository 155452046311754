import {
  TURN_OFF_DYNAMIC_SEGMENTATION_DRAG,
  TOGGLE_DYNAMIC_SEGMENTATION_DRAG,
  TOGGLE_DYNAMIC_SEGMENTATION_SELECT,
  TURN_OFF_DYNAMIC_SEGMENTATION_SELECT,
} from "actiontypes/dynamicSegmentation";

const initialState = {
  isDragActive: false,
  isSelectActive: false, // for individually clicking segments
};

const dynamicSegmentation = (state = initialState, action) => {
  switch (action.type) {
    case TURN_OFF_DYNAMIC_SEGMENTATION_DRAG:
      return {
        ...state,
        isDragActive: false,
      };
    case TOGGLE_DYNAMIC_SEGMENTATION_DRAG:
      return {
        ...state,
        isDragActive: !state.isDragActive,
        isSelectActive: false,
      };
    case TURN_OFF_DYNAMIC_SEGMENTATION_SELECT:
      return {
        ...state,
        isSelectActive: false,
      };
    case TOGGLE_DYNAMIC_SEGMENTATION_SELECT:
      return {
        ...state,
        isSelectActive: !state.isSelectActive,
        isDragActive: false,
      };
    default:
      return state;
  }
};

export default dynamicSegmentation;
