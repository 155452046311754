import React from "react";
import { connect } from "react-redux";
import NotificationMenu from "components/NotificationMenu";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import track from "react-tracking";
import PropTypes from "prop-types";
import { closeNotificationDropdown } from "../../actions/visibility";

const NotificationMenuContainer = ({
  notifications,
  closeNotificationDropdown,
  notificationDropdownVisibility,
  offset,
}) => {
  const filterNotifications = () => {
    return notifications
      .filter(child => !child.mostRecent)
      .sort((a, b) => moment(a.date) < moment(b.date));
  };

  const clickAwayNotifications = () => {
    closeNotificationDropdown();
  };

  const filteredNotifications = filterNotifications();
  return notificationDropdownVisibility ? (
    <NotificationMenu
      offset={offset}
      notifications={filteredNotifications}
      clickAwayNotifications={clickAwayNotifications}
    />
  ) : null;
};

NotificationMenuContainer.defaultProps = {
  offset: 0,
};

NotificationMenuContainer.propTypes = {
  notifications: PropTypes.array.isRequired,
  notificationDropdownVisibility: PropTypes.bool.isRequired,
  offset: PropTypes.number,
  closeNotificationDropdown: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notificationDropdownVisibility:
    state.visibility.notificationDropdownVisibility,
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = dispatch => ({
  closeNotificationDropdown: () => dispatch(closeNotificationDropdown()),
});

export default track()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NotificationMenuContainer)
  )
);
