import React from "react";
import Slider from "@material-ui/core/Slider";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const useStyles = makeStyles(
  {
    container: props => ({
      float: "left",
      padding: "10px 0px",
      height: `calc(30px * ${props.itemCount})`,
    }),
    slider: props => ({
      top: 22,
      width: "80%",
      color: props.sliderColor,
    }),
    title: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
      fontSize: "18px",
    },
    rail: {
      color: "grey",
      width: "2px !important",
      marginLeft: -5,
    },
    valueLabel: {
      top: -22,
      "& *": {
        background: "transparent",
        color: "black",
        fontFamily: "'Open Sans', sans-serif",
      },
    },
    noValueLabel: {
      top: -22,
      "& *": {
        background: "transparent",
        color: "transparent",
      },
    },
    mark: {
      color: "currentColor",
      marginLeft: -9,
      width: 8,
    },
    thumb: {
      height: "auto",
      width: "auto",
      display: "flex",
      borderRadius: "15px",
      flexDirection: "column",
      "&:hover": {
        boxShadow: "0px 0px 0px 4px rgba(63, 81, 181, 0.16)",
      },
      "&$active": {
        boxShadow: "0px 0px 0px 8px rgba(63, 81, 181, 0.16)",
      },
      "&$focusVisible": {
        boxShadow: "0px 0px 0px 4px rgba(63, 81, 181, 0.16)",
      },
      "& .thumbIcon": {
        color: "#fff",
        height: "18px",
      },
      "& .rotate": {
        transform: "rotate(90deg)",
      },
    },
    vertical: {
      "& $thumb": {
        marginLeft: "-14px",
        marginBottom: "-25px",
      },
    },
    active: {
      boxShadow: "0px 0px 0px 8px rgba(63, 81, 181, 0.16)",
    },
    focusVisible: {
      boxShadow: "0px 0px 0px 4px rgba(63, 81, 181, 0.16)",
    },
  },
  { name: "MuiSlider" }
);

function ThumbComponent(props) {
  return (
    <span {...props}>
      <span className="thumbIcon">
        <ExpandLessIcon fontSize={"small"} />
      </span>
      <span className="thumbIcon">
        <DragIndicatorIcon fontSize={"small"} className="rotate" />
      </span>
      <span className="thumbIcon">
        <ExpandMoreIcon fontSize={"small"} />
      </span>
    </span>
  );
}

const CustomSlider = ({
  value,
  handleSliderChange,
  marks,
  valuetext,
  step,
  max,
  min,
  showSlider,
  sliderColor,
  orientation,
  valueLabelDisplay,
  itemCount,
}) => {
  const props = { sliderColor, itemCount };
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <Slider
        ThumbComponent={ThumbComponent}
        orientation={orientation}
        className={classes.slider}
        value={value}
        onChange={handleSliderChange}
        valueLabelDisplay={valueLabelDisplay}
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        marks={marks}
        step={step}
        classes={{
          rail: classes.rail,
          valueLabel: showSlider ? classes.valueLabel : classes.noValueLabel,
          mark: classes.mark,
          thumb: classes.thumb,
          vertical: classes.vertical,
          active: classes.active,
          focusVisible: classes.focusVisible,
        }}
        max={max}
        min={min}
        disabled={!showSlider}
      />
    </div>
  );
};

CustomSlider.defaultProps = {
  marks: [],
  orientation: "horizontal",
  valueLabelDisplay: "on",
};

CustomSlider.propTypes = {
  value: PropTypes.array.isRequired,
  handleSliderChange: PropTypes.func.isRequired,
  marks: PropTypes.array,
  valuetext: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  showSlider: PropTypes.bool.isRequired,
  sliderColor: PropTypes.string.isRequired,
  orientation: PropTypes.string,
  valueLabelDisplay: PropTypes.string,
  itemCount: PropTypes.number.isRequired,
};

export default CustomSlider;
