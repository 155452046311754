import makeSegment from "./makeSegment";

export function calculateTotalLength(segmentFeatures) {
  let totalLength = 0;
  segmentFeatures.forEach(segmentFeature => {
    const segmentLength = makeSegment(segmentFeature).length;
    totalLength += segmentLength;
  });
  return totalLength;
}

export function calculateAverageRating(segmentFeatures) {
  // if a segment doesn't have an average rating (is unpaved for example), don't include it in
  // the average rating calculation
  let numValidSegments = 0;
  let aggregateRating = 0;
  segmentFeatures.forEach(segmentFeature => {
    const segmentRating = segmentFeature.properties.avg_rating;
    if (segmentRating) {
      numValidSegments += 1;
      aggregateRating += segmentRating;
    }
  });
  if (numValidSegments > 0) {
    return parseFloat(aggregateRating / numValidSegments).toFixed(2);
  } else {
    return null;
  }
}
