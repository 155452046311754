import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import makeStyles from '@material-ui/core/styles/makeStyles'
// import { RB_LIGHT_GREY } from '../constants/colors'

const useStyles = makeStyles({
  avatar: {
    background: '#3f51b5'
  }
})

const AvatarSkeleton: React.FC = () => {
  const classes = useStyles()
  return (
    <Skeleton
      className={classes.avatar}
      variant='circle'
      height={40}
      width={40}
    />
  )
}

export default AvatarSkeleton
