import React from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import ChildOrgList from "components/ChildOrg/ChildOrgList";
import Tooltip from "@material-ui/core/Tooltip";
import ViewList from "@material-ui/icons/ViewList";
import ViewModule from "@material-ui/icons/ViewModule";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

import { ORGANIZATION_SELECTOR_VIEW_FORMAT } from "../../constants/organizationSelector";
import RoadSenseGridItem from "../ChildOrg/RoadSenseGridItem";
import { useSelector } from "react-redux";

const OrganizationSelector = ({
  format,
  handleViewChange,
  childOrganizations,
  setActiveScan,
  organizationScans,
  isOrganizationsDataLoaded,
  displayGrid,
  measuringSystem,
}) => {
  const handleGridViewClick = () =>
    handleViewChange(ORGANIZATION_SELECTOR_VIEW_FORMAT.GRID);
  const handleListViewClick = () =>
    handleViewChange(ORGANIZATION_SELECTOR_VIEW_FORMAT.LIST);
  const isFreeTrialUser = useSelector(
    state => state?.user?.isFreeTrialUser || false
  );

  return (
    <React.Fragment>
      <div>
        <ButtonGroup
          style={{ right: 20, position: "fixed", marginTop: "1%" }}
          variant="contained"
          size="small"
        >
          <Button
            disabled={format === ORGANIZATION_SELECTOR_VIEW_FORMAT.GRID}
            onClick={handleGridViewClick}
          >
            <Tooltip title="Grid View" placement="bottom">
              <ViewModule />
            </Tooltip>
          </Button>

          <Button
            disabled={format === ORGANIZATION_SELECTOR_VIEW_FORMAT.LIST}
            onClick={handleListViewClick}
          >
            <Tooltip title="List View" placement="bottom">
              <ViewList />
            </Tooltip>
          </Button>
        </ButtonGroup>

        <Grid container spacing={5} style={{ paddingLeft: "15px" }}>
          <Grid item xs={11}>
            <Grid container spacing={10}>
              {format === ORGANIZATION_SELECTOR_VIEW_FORMAT.GRID ? (
                <React.Fragment>
                  {displayGrid()}
                  {isFreeTrialUser && <RoadSenseGridItem />}
                </React.Fragment>
              ) : (
                // setAnalysisData={setAnalysisData}
                <Grid item sm={12}>
                  <ChildOrgList
                    organizations={childOrganizations}
                    setActiveScan={setActiveScan}
                    organizationScans={organizationScans}
                    isOrganizationsDataLoaded={isOrganizationsDataLoaded}
                    measuringSystem={measuringSystem}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

OrganizationSelector.propTypes = {
  format: PropTypes.string.isRequired,
  handleViewChange: PropTypes.func.isRequired,
  childOrganizations: PropTypes.array.isRequired,
  setActiveScan: PropTypes.func.isRequired,
  displayGrid: PropTypes.func.isRequired,
  isOrganizationsDataLoaded: PropTypes.bool.isRequired,
  organizationScans: PropTypes.object.isRequired,
  measuringSystem: PropTypes.string.isRequired,
};

export default OrganizationSelector;
