import { openModal, stopModal, shrinkImg } from "actions/modal";
import {
  setSegmentCoords,
  showHighlight,
  hideHighlight,
} from "actions/highlight";
import { SEGMENT } from "constants/highlightTypes";

import { NOT_PAVED } from "constants/damageRatings";
import { closeSidebar } from "actions/visibility";
import { isIPad } from "utils/deviceCheck";
import chartData from "../chartData";
import { centerMap } from "../pointsUtils";
import makeSegment from "utils/makeSegment";

const roadwaySegment = ({
  feature,
  dispatch,
  colorPicker,
  modalVisibility,
  sidebarVisibility,
  enlargedImg,
  map,
  event,
}) => {
  const lng = event.lngLat.lng;
  const lat = event.lngLat.lat;
  centerMap({
    map,
    enlargedImg,
    sidebarVisibility,
    modalVisibility,
    lng,
    lat,
  });

  const layer = feature.layer;
  const properties = feature.properties;
  let segmentDistressStatistics = null;
  if (properties.segmentDistressStatistics) {
    segmentDistressStatistics = JSON.parse(
      properties.segmentDistressStatistics
    );
  }

  const ratings = JSON.parse(properties.ratings);
  const segment = makeSegment(feature);

  let coords;
  if (segment.coords) {
    coords = segment.coords.map(coord => [coord[1], coord[0]]);
  } else if (segment.latitude) {
    coords = segment.latitude.map((e, i) => [segment.longitude[i], e]);
  }
  dispatch(hideHighlight());
  dispatch(setSegmentCoords(coords));
  dispatch(showHighlight(SEGMENT));
  dispatch(stopModal());

  // close the sidebar if on iPad
  if (isIPad()) {
    dispatch(closeSidebar());
  }

  const segmentName = segment.name || "Damage Report";
  let averageRating = properties.avg_rating;
  let data;
  if (layer.color !== "808080") {
    data = chartData(ratings, segmentName, colorPicker);
  } else {
    averageRating = undefined;
    data = {
      labels: [NOT_PAVED],
      datasets: [
        {
          label: segmentName,
          backgroundColor: ["gray"],
          hoverBackgroundColor: ["gray"],
          borderColor: "black",
          hoverBorderWidth: 4,
          data: [100],
        },
      ],
    };
  }

  const style = {
    width: "40%",
    position: "fixed",
    zIndex: "5",
    backgroundColor: "white",
    marginTop: "-5%",
    marginLeft: "2%",
    borderRadius: "10px",
  };

  dispatch(
    openModal({
      type: "segment",
      pointData: {},
      segmentData: {
        title: layer.id,
        content: {
          chartData: data,
          segmentId: segment.id,
          averageRating,
          length: segment.length,
          // TODO: add the ID
          // ID: "asdfasdf",
          segmentDistressStatistics,
        },
      },
      style,
      expanded: {},
    })
  );
  dispatch(shrinkImg());
};

export default roadwaySegment;
