import React from "react";
import AppBarContainer from "../containers/AppBarContainer";
import Paper from "@material-ui/core/Paper";
import FullAnalysisChart from "./FullAnalysisChart";
import StreetTypeAnalysisChart from "./StreetTypeAnalysisChart";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { MeasuringSystem } from "../constants/measuringSystem";
import { AnalysisInterface } from "../interfaces/analysis";

const useStyles = makeStyles({
  summaryPageWrapper: {
    paddingTop: "5%",
  },
  fullAnalysisChartWrapper: {
    backgroundColor: "#f9f7f6",
    margin: "20px 20px 40px",
  },
});

export type StreetData = {
  name: string;
  ratingDistribution: { [key: string]: number };
  avgRating: number;
  key: number;
  totalLength: number;
};

type SummaryPageProps = {
  location: {
    pathname: string;
  };
  analysisData: AnalysisInterface;
  measuringSystem: MeasuringSystem;
  classificationAnalysisList: StreetData[];
  isNetworkDataLoaded: boolean;
  individualRoadsAnalysisList: [];
  isStreetDataLoaded: boolean;
};

const SummaryPage = ({
  location,
  analysisData,
  measuringSystem,
  classificationAnalysisList,
  isNetworkDataLoaded,
  individualRoadsAnalysisList,
  isStreetDataLoaded,
}: SummaryPageProps): React.ReactElement => {
  const styles = useStyles();
  return (
    <div>
      <AppBarContainer urlLocation={location.pathname} />
      <div className={styles.summaryPageWrapper}>
        <Paper className={styles.fullAnalysisChartWrapper}>
          <FullAnalysisChart
            analysisData={analysisData}
            classificationAnalysisList={classificationAnalysisList}
            measuringSystem={measuringSystem}
            isDataLoaded={isNetworkDataLoaded}
          />
        </Paper>

        {classificationAnalysisList.map(streetData => {
          const streetList = individualRoadsAnalysisList[streetData.key];
          return (
            <StreetTypeAnalysisChart
              key={`classification-analysis-${streetData.name}`}
              streetData={streetData}
              measuringSystem={measuringSystem}
              streetList={streetList}
              isDataLoaded={isStreetDataLoaded}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SummaryPage;
