import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";

import PublicRoute from "Routes/PublicRoute";
import PrivateRoute from "Routes/PrivateRoute";
import MapView from "containers/MapView/MapView";
import UserSettings from "containers/UserSettings/UserSettings";
import DashboardContainer from "containers/DashboardContainer";
import AdminSettingsContainer from "containers/AdminContainer/AdminContainer";
import SummaryPageContainer from "containers/SummaryPageContainer";
import PageNotFound from "components/PageNotFound";
import FreeTrialContainer from "containers/FreeTrialContainer";
import SuccessPageContainer from "containers/SuccessPageContainer";

import {
  ROUTE_MAP,
  ROUTE_DASHBOARD,
  ROUTE_SETTINGS,
  ROUTE_ADMIN,
  ROUTE_NOT_FOUND,
  ROUTE_WRONG_ROADWAY,
  ROUTE_SUMMARY,
  ROUTE_FREE_TRIAL,
  ROUTE_FREE_TRIAL_SUCCESS,
  ROUTE_IMAGE_LOGGER_SIGN_UP,
  ROUTE_IMAGE_LOGGER_SIGN_UP_SUCCESS,
} from "../constants/routes";
import UserStatus from "../constants/userStatus";
import ImageLoggerSignUpContainer from "../containers/ImageLoggerSignUpContainer";

const Router = ({ userUId, user, userStatus }) => {
  const isUserNull = user === null;
  const isUserDisabled = userStatus === UserStatus.DISABLED;
  return (
    <Switch>
      <PublicRoute
        path={ROUTE_FREE_TRIAL}
        component={FreeTrialContainer}
        user={user}
      />
      <PublicRoute
        path={ROUTE_FREE_TRIAL_SUCCESS}
        component={SuccessPageContainer}
        user={user}
      />
      <PublicRoute
        path={ROUTE_IMAGE_LOGGER_SIGN_UP}
        component={ImageLoggerSignUpContainer}
        user={user}
      />
      <PublicRoute
        path={ROUTE_IMAGE_LOGGER_SIGN_UP_SUCCESS}
        component={SuccessPageContainer}
        user={user}
      />
      <PrivateRoute
        userUId={userUId}
        path={ROUTE_ADMIN}
        component={AdminSettingsContainer}
        accessAllowed={!isUserNull && !isUserDisabled}
        allowFrom={!isUserNull && !isUserDisabled}
      />
      <PrivateRoute
        userUId={userUId}
        path={`${ROUTE_MAP}/:mapId`}
        component={MapView}
        accessAllowed={!isUserNull && !isUserDisabled}
        allowFrom={!isUserNull && !isUserDisabled}
      />
      <PrivateRoute
        userUId={userUId}
        path={ROUTE_DASHBOARD}
        component={DashboardContainer}
        accessAllowed={!isUserNull}
      />
      <Route exact path="/" render={() => <Redirect to={ROUTE_DASHBOARD} />} />
      <PrivateRoute
        userUId={userUId}
        path={ROUTE_SETTINGS}
        component={UserSettings}
        accessAllowed={!isUserNull}
      />
      <PrivateRoute
        userUId={userUId}
        path={`${ROUTE_SUMMARY}/:scanId`}
        component={SummaryPageContainer}
        accessAllowed={!isUserNull}
      />
      <Route path={ROUTE_NOT_FOUND} component={PageNotFound} />
      <Route path={ROUTE_WRONG_ROADWAY} render={() => <Redirect to="/" />} />
      <Redirect to={ROUTE_NOT_FOUND} />
    </Switch>
  );
};

Router.defaultProps = {
  user: null,
  userStatus: UserStatus.DISABLED,
};

Router.propTypes = {
  user: PropTypes.string,
  userStatus: PropTypes.string,
};

export default Router;
