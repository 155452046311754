import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import ColorPreferenceContainer from "containers/ColorPreferenceContainer";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import Button from "components/form/Button";
import { useSelector } from "react-redux";
import ExampleOrganizationVisibility from "../ExampleOrganizationVisiblity";

const useStyles = makeStyles({
  formControl: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 30px",
    marginTop: "1%",
  },
  buttons: {
    padding: "0px 12px 20px 0px",
    bottom: "0px",
    right: "10px",
    position: "absolute",
  },
  newCard: {
    paddingBottom: "50px",
  },
  radio: {
    margin: "-8px 0px -8px 3px",
  },
  divider: {
    marginBottom: "2%",
  },
  unitsDiv: {
    marginTop: "2%",
  },
  group: {
    marginLeft: "-2%",
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "28px",
  },
});

const AppearanceSettings = ({
  saveAppearanceSettings,
  resetAppearanceSettings,
  measuringSystem,
  updateMeasuringSystem,
  disabled,
}) => {
  const classes = useStyles();
  const freeTrialUser = useSelector(
    state => state?.user?.isFreeTrialUser || false
  );
  return (
    <div>
      <Grid container>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.typography}>Color Mode</Typography>
            </CardContent>
            <Divider />
            <CardContent>
              <ColorPreferenceContainer />
              <Typography className={classes.typography}>Units</Typography>
            </CardContent>

            <Divider />
            <CardContent>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="units"
                  name="units"
                  className={classes.group}
                  value={measuringSystem}
                  onChange={updateMeasuringSystem}
                >
                  <FormControlLabel
                    value="imperial"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                        }}
                        color="primary"
                      />
                    }
                    label="Imperial"
                  />
                  <FormControlLabel
                    value="metric"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                        }}
                        color="primary"
                      />
                    }
                    label="Metric"
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>
            <Divider />
            {freeTrialUser && (
              <React.Fragment>
                <CardContent>
                  <Typography className={classes.typography}>
                    Organizations
                  </Typography>
                </CardContent>
                <CardContent>
                  <ExampleOrganizationVisibility />
                </CardContent>
              </React.Fragment>
            )}

            <CardActions style={{ float: "right" }}>
              <Button onClick={resetAppearanceSettings} disabled={disabled}>
                Reset to Default
              </Button>
              <Button
                variant="contained"
                onClick={saveAppearanceSettings}
                color="primary"
                disabled={disabled}
              >
                Save Settings
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </div>
  );
};

AppearanceSettings.propTypes = {
  saveAppearanceSettings: PropTypes.func.isRequired,
  resetAppearanceSettings: PropTypes.func.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  updateMeasuringSystem: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AppearanceSettings;
