import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import AppearanceSettings from "components/SettingsPage/AppearanceSettings";
import ProfileSettings from "components/SettingsPage/ProfileSettings";
import { setMeasuringSystem } from "actions/userData";
import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import track from "react-tracking";
import { ROUTE_MAP } from "constants/routes";
import AppBarContainer from "containers/AppBarContainer";
import { setFirstName, setLastName } from "actions/login";
import { toggleColorBlindMode } from "../../actions/colorPicker";
import PasswordSettingsContainer from "../PasswordSettingsContainer/PasswordSettingsContainer";
import * as Sentry from "@sentry/browser/dist/index";
import { MeasuringSystem } from "constants/measuringSystem";
import RBAPI from "api/RoadwayAPI";

const styles = theme => ({
  root: {
    display: "relative",
    height: "100%",
  },
  header: {
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "center",
    fontSize: "20px",
    margin: "auto",
    paddingRight: "235px",
    color: "black",
  },
  title: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "24px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000",
    textTransform: "capitalize",
  },
  card: {
    position: "relative",
    display: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor: "white",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
  settingGroup: {
    width: 750,
    margin: "auto",
    padding: 15,
  },
  avatar: {
    alignItems: "center",
    textTransform: "uppercase",
    left: "10%",
  },
  settingsHeader: {
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "left",
    padding: "3px 0px",
  },
  settingsItemLabel: {
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "left",
    fontSize: "17px",
    padding: "3px 20px",
  },
  formControl: {
    width: "100%",
  },
  returnButton: {
    color: "#000000",
    marginLeft: -12,
    marginRight: 20,
    height: "100%",
    left: -12,
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  returnLabel: {
    color: "black",
    fontSize: "16px",
    alignText: "center",
    fontFamily: "'Open Sans', sans-serif",
    padding: "0px 0px 2px 5px",
  },
  appBar: {
    backgroundColor: "#ffffff",
    boxShadow: "none",
  },
  verticalTabs: {
    backgroundColor: theme.palette.background.paper,
  },
  settingsItemText: {
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "left",
    fontSize: "14px",
    padding: "3px 20px",
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
  mainCard: {
    marginTop: "5%",
  },
});

class UserSettings extends Component {
  state = {
    tabValue: 0,
    firstName: "",
    lastName: "",
  };

  componentDidMount() {
    const { userUId } = this.props;
    if (userUId) {
      RBAPI.getUser(userUId)
        .catch(err => {
          if (err?.response?.status === 404) {
            //
            setSnackbarMessageAndOpen(
              "Could not find user",
              MessageTypes.ERROR
            );
            return;
          }
          throw err;
        })
        .then(user => {
          this.setState({
            firstName: user.firstName,
            lastName: user.lastName,
          });
        });
    }
  }

  // method isn't used anywhere
  handleBackToRoadway = () => {
    const { history, activeScan } = this.props;
    history.push(`${ROUTE_MAP}/${activeScan}`);
  };

  handleTabChange = (_, tabValue) => {
    const { tracking, userUId } = this.props;
    tracking.trackEvent({
      event: "mouse-click",
      action: `user-settings-tab-change`,
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `user settings tab change`,
      level: Sentry.Severity.Info,
    });

    this.setState({ tabValue });
  };

  updateMeasuringSystem = event => {
    const { setMeasuringSystem } = this.props;
    setMeasuringSystem(event.target.value);
  };

  saveProfileSettings = () => {
    const {
      setSnackbarMessageAndOpen,
      userUId,
      tracking,
      setFirstName,
      setLastName,
    } = this.props;
    const { firstName, lastName } = this.state;

    tracking.trackEvent({
      event: "mouse-click",
      action: "save-profile-settings-click",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `save profile settings click`,
      level: Sentry.Severity.Info,
    });
    if (firstName && lastName) {
      RBAPI.updateUser({
        id: userUId,
        firstName,
        lastName,
      }).then(() => {
        setFirstName(firstName);
        setLastName(lastName);
        setSnackbarMessageAndOpen("Profile settings saved");
      });
    } else {
      setSnackbarMessageAndOpen(
        "Cannot submit empty profile information",
        MessageTypes.ERROR
      );
    }
  };

  onTextFieldChange = (e, characteristic) => {
    this.setState({
      [characteristic]: e.target.value,
    });
  };

  saveAppearanceSettings = async () => {
    const {
      colorPicker,
      measuringSystem,
      setSnackbarMessageAndOpen,
      userUId,
      tracking,
    } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: "save-appearance-settings-click",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `save appearance settings click`,
      level: Sentry.Severity.Info,
    });

    const editedSettings = {
      colorMode: colorPicker.colorBlindMode,
      measuringSystem: measuringSystem,
    };
    await RBAPI.updateUserSettings(userUId, editedSettings).catch(err => {
      console.error(err);
    });
    setSnackbarMessageAndOpen("Appearance settings saved");
  };

  resetAppearanceSettings = async () => {
    const {
      setSnackbarMessageAndOpen,
      measuringSystem,
      userUId,
      tracking,
      toggleColorBlindMode,
    } = this.props;

    tracking.trackEvent({
      event: "mouse-click",
      action: "reset-appearance-settings-click",
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "reset appearance settings click",
      level: Sentry.Severity.Info,
    });

    const defaultSettings = {
      colorMode: "",
      measuringSystem,
    };
    await RBAPI.updateUserSettings(userUId, defaultSettings).catch(err => {
      console.error(err);
    });
    toggleColorBlindMode(defaultSettings.colorMode);
    setSnackbarMessageAndOpen("Appearance settings reset to default");
  };

  render() {
    const {
      classes,
      measuringSystem,
      user,
      location,
      memberships,
      setSnackbarMessageAndOpen,
    } = this.props;
    const { tabValue } = this.state;
    return (
      <div className={classes.root}>
        <AppBarContainer urlLocation={location.pathname} />
        <Card className={classes.mainCard}>
          <Tabs
            className={classes.verticalTabs}
            value={tabValue}
            indicatorColor="primary"
            centered={true}
            onChange={this.handleTabChange}
          >
            <Tab label="Appearance" className={classes.tab} value={0} />
            <Tab label="Profile" className={classes.tab} value={1} />
          </Tabs>

          {tabValue === 0 && (
            <TabContainer>
              <AppearanceSettings
                classes={classes}
                saveAppearanceSettings={this.saveAppearanceSettings}
                resetAppearanceSettings={this.resetAppearanceSettings}
                measuringSystem={measuringSystem}
                updateMeasuringSystem={this.updateMeasuringSystem}
                disabled={process.env.REACT_APP_ISDEMO === "1"}
              />
            </TabContainer>
          )}
          {tabValue === 1 && (
            <TabContainer>
              <div>
                <ProfileSettings
                  classes={classes}
                  disabled={process.env.REACT_APP_ISDEMO === "1"}
                  user={user}
                  saveProfileSettings={this.saveProfileSettings}
                  onTextFieldChange={this.onTextFieldChange}
                  firstName={this.state.firstName}
                  lastName={this.state.lastName}
                  organization={
                    memberships[0] ? memberships[0].organization : ""
                  }
                />

                <PasswordSettingsContainer
                  setSnackbarMessageAndOpen={setSnackbarMessageAndOpen}
                />
              </div>
            </TabContainer>
          )}
        </Card>
      </div>
    );
  }
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 24 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.object.isRequired,
};

UserSettings.defaultProps = {
  user: "",
  userUId: "",
  history: {},
  setMeasuringSystem: () => null,
  setSnackbarMessageAndOpen: () => null,
  colorPicker: {},
  measuringSystem: MeasuringSystem.Imperial,
  classes: {},
  memberships: {},
};

UserSettings.propTypes = {
  user: PropTypes.string,
  userUId: PropTypes.string,
  history: PropTypes.object,
  setMeasuringSystem: PropTypes.func,
  setSnackbarMessageAndOpen: PropTypes.func,
  colorPicker: PropTypes.object,
  measuringSystem: PropTypes.string,
  classes: PropTypes.object,
  tracking: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  memberships: PropTypes.array,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  activeScan: PropTypes.string.isRequired,
  toggleColorBlindMode: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.email,
  colorPicker: state.colorPicker,
  measuringSystem: state.userData.measuringSystem,
  userUId: state.user.userUId,
  memberships: state.userData.memberships,
  activeScan: state.userData.activeScan,
});

const mapDispatchToProps = dispatch => ({
  setSnackbarMessageAndOpen: (message, messageType) =>
    dispatch(setSnackbarMessageAndOpen(message, messageType)),
  setMeasuringSystem: unit => dispatch(setMeasuringSystem(unit)),
  setFirstName: firstName => dispatch(setFirstName(firstName)),
  setLastName: lastName => dispatch(setLastName(lastName)),
  toggleColorBlindMode: type => dispatch(toggleColorBlindMode(type)),
});

export default track()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(styles)(UserSettings))
  )
);
