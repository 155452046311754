import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Organization from "interfaces/organization";
import ScanSwitcherSelect from "components/ScanSwitcherSelect";
import { fetchOrganizations } from "../actions/userData";
import { MessageTypes, setSnackbarMessageAndOpen } from "../actions/snackbar";
import UserStatus from "../constants/userStatus";
import State from "../interfaces/state";
import RBAPI from "api/RoadwayAPI";
import { getAssessmentType } from "utils/getAssessmentType";

interface ScanSwitcherSelectContainerInterface {
  className: string;
  handleChange: (event: object, child: object) => void;
}

const ScanSwitcherSelectContainer = ({
  className,
  handleChange,
}: ScanSwitcherSelectContainerInterface): React.ReactElement => {
  const dispatch = useDispatch();
  const organizations = useSelector(
    (state: State) => state.userData.organizations
  );
  const activeScan = useSelector((state: State) => state.userData.activeScan);
  const isOrganizationsDataLoaded = useSelector(
    (state: State) => state.userData.isOrganizationsDataLoaded
  );
  const userUId = useSelector((state: State) => state.user.userUId);
  const userStatus = useSelector((state: State) => state.user.status);

  interface FormattedOption {
    label: string;
    value: string;
    level: number;
    isOption: boolean;
  }

  // Helper method to flatten the tree based organization-scans data to list
  const formatScanOptions = (
    organizations: Organization[]
  ): FormattedOption[] => {
    const formattedOptions: FormattedOption[] = [];

    const addToFormattedOptions = (
      label: string,
      value: string,
      level: number,
      isOption = false
    ): void => {
      formattedOptions.push({
        label,
        value,
        level,
        isOption,
      });
    };

    const formatOptions = (
      organizations: Organization[],
      level: number
    ): void => {
      if (organizations && organizations.length > 0) {
        organizations.forEach(({ name, childrenOrganizations, scans }) => {
          addToFormattedOptions(name, name, level);
          if (scans && scans.length > 0) {
            scans.forEach(({ id, displayName, name, defaults }) => {
              if (defaults?.bounds) {
                addToFormattedOptions(displayName || name, id, level, true);
              }
            });
          }
          if (childrenOrganizations && childrenOrganizations.length > 0) {
            formatOptions(childrenOrganizations, level + 1);
          }
        });
      }
    };

    formatOptions(organizations, 0);
    return formattedOptions;
  };

  const getScanOption = (
    options: FormattedOption[],
    scanValue: string
  ): FormattedOption[] => options.filter(({ value }) => scanValue === value);

  if (!isOrganizationsDataLoaded) {
    dispatch(
      fetchOrganizations(
        RBAPI.fetchScansByUser(userUId, getAssessmentType()).catch(
          (err: any) => {
            if (err?.response?.status === 404) {
              setSnackbarMessageAndOpen(
                "Could not find assessments",
                MessageTypes.ERROR
              );
              return;
            }
            throw err;
          }
        )
      )
    );
  }
  const formattedScanOptions = formatScanOptions(organizations);
  const selectedScanOption = getScanOption(formattedScanOptions, activeScan);
  const hasNoData =
    !isOrganizationsDataLoaded && organizations && organizations.length === 0;

  return (
    <div className={className}>
      <ScanSwitcherSelect
        options={formattedScanOptions}
        selectedOption={selectedScanOption}
        handleChange={handleChange}
        hasNoData={hasNoData}
        isDisabled={userStatus === UserStatus.DISABLED}
      />
    </div>
  );
};

export default ScanSwitcherSelectContainer;
