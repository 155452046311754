import React from "react";
import { connect } from "react-redux";
import { toggleLayer } from "actions/layers";
import get from "lodash/get";
import RoundCheckbox from "components/RoundCheckbox";
import LightTooltip from "components/LightTooltip";
import track from "react-tracking";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser/dist/index";

import { getLayer, getLayerStatus } from "utils/layers";
import { sidewalkMap } from "utils/sidewalkHelperFunctions";
import RBAPI from "api/RoadwayAPI";

const RatingsLayerToggle = ({
  toggleLayer,
  tracking,
  activeScan,
  userUId,
  layerGroups,
  colorPicker,
  toggle,
}) => {
  const toggleLayerBySource = layerSource => {
    tracking.trackEvent({
      event: "mouse-click",
      action: `layer-toggle-${layerSource}`,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `layer toggle ${layerSource}`,
      level: Sentry.Severity.Info,
    });
    const layer = getLayer(layerSource, layerGroups);
    const layerId = get(layer, "id");
    if (layer) {
      toggleLayer(layerId);
    }

    // get variables for firestore
    if (layerSource !== "missing") {
      const layerData = getLayerStatus(layerSource, layerId, layerGroups);

      const scanState = {
        [layer.sourceLayer]: !layerData.visible,
      };
      RBAPI.updateUserLayerState(userUId, activeScan, scanState).catch(err => {
        console.error(err);
      });
    }
  };

  const isLayerVisible = layerSource => {
    if (layerSource !== "missing") {
      const layer = getLayer(layerSource, layerGroups);
      return layer.visible;
    } else {
      return false;
    }
  };

  const isLayerHidden = layerSource => {
    if (layerSource !== "missing") {
      const layer = getLayer(layerSource, layerGroups);
      return layer.hidden;
    } else {
      return false;
    }
  };

  const renderToggles = position => {
    const { RATING_1, RATING_2, RATING_3, RATING_4, RATING_5 } = colorPicker;
    switch (position) {
      case 1:
        return RATING_1;
      case 2:
        return RATING_2;
      case 3:
        return RATING_3;
      case 4:
        return RATING_4;
      case 5:
        return RATING_5;
      default:
        return null;
    }
  };

  const ratingsLayerToggle = (
    <RoundCheckbox
      key={toggle.id}
      label={
        toggle.layerType === "sidewalk"
          ? sidewalkMap[toggle.displayName[toggle.displayName.length - 1]]
          : toggle.displayName[toggle.displayName.length - 1]
      }
      color={renderToggles(toggle.position)}
      onChange={() => toggleLayerBySource(toggle.layerId)}
      checked={isLayerVisible(toggle.layerId)}
      disabled={isLayerHidden(toggle.layerId)}
      layerType={toggle.layerType}
    />
  );

  return toggle.toggleable === false ? (
    <LightTooltip placement="top" title="Layer Not Available">
      {/* I have to wrap content in div for tooltip to work */}
      {/* (https://github.com/mui-org/material-ui/issues/10405) */}
      <div>{ratingsLayerToggle}</div>
    </LightTooltip>
  ) : (
    ratingsLayerToggle
  );
};

RatingsLayerToggle.propTypes = {
  toggleLayer: PropTypes.func.isRequired,
  tracking: PropTypes.shape({
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  userUId: PropTypes.string.isRequired,
  layerGroups: PropTypes.array.isRequired,
  colorPicker: PropTypes.object.isRequired,
  toggle: PropTypes.object.isRequired,
  activeScan: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  layerGroups: state.layers.groups,
  colorPicker: state.colorPicker,
  userUId: state.user.userUId,
  activeScan: state.userData.activeScan,
});

const mapDispatchToProps = dispatch => ({
  toggleLayer: layerId => dispatch(toggleLayer(layerId)),
});

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(RatingsLayerToggle)
);
