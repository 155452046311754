import { combineReducers } from "redux";
import mapReducer from "./map";
import modalReducer from "./modal";
import userReducer from "./login";
import settingsReducer from "./settingsCog";
import highlightReducer from "./highlight";
import { LOGOUT_USER } from "../actiontypes/login";
import layers from "./layers";
import toggles from "./toggles";
import rotation from "./rotation";
import measureDistance from "./measureDistance";
import measureArea from "./measureArea";
import colorPicker from "./colorPicker";
import snackbar from "./snackbar";
import notifications from "./notifications";
import dynamicSegmentation from "./dynamicSegmentation";
import userData from "./userData";
import isolateDistressesReducer from "./isolateDistresses";
import screenCaptureReducer from "./screenCapture";
import visibility from "./visibility";

const appReducer = combineReducers({
  baseMap: mapReducer,
  colorPicker,
  dynamicSegmentation,
  highlight: highlightReducer,
  isolateDistresses: isolateDistressesReducer,
  layers,
  measureDistance,
  measureArea,
  modal: modalReducer,
  notifications,
  rotation,
  screenCapture: screenCaptureReducer,
  settingsCog: settingsReducer,
  snackbar,
  toggles,
  user: userReducer,
  userData,
  visibility,
});

export default (state, action) => {
  // This is done to reset the state of the application when a user logs out.
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }
  return appReducer(state, action);
};
