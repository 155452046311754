import React from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Avatar from '@material-ui/core/Avatar'
import AvatarSkeleton from '../../skeletons/avatar'

interface AvatarProps {
  firstName: string
  lastName: string
  handleClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

const useStyles = makeStyles({
  avatar: {
    backgroundColor: '#3f51b5'
  },
  avatarBtn: {
    marginLeft: '-6px'
  }
})

const getShortName = (firstName: string, lastName: string): string | null => {
  if (firstName && lastName) {
    return `${firstName.charAt(0).toUpperCase()}${lastName
      .charAt(0)
      .toUpperCase()}`
  }
  return null
}

const AccountAvatar = ({
  firstName,
  lastName,
  handleClick
}: AvatarProps): JSX.Element => {
  const classes = useStyles()
  const shortName = getShortName(firstName, lastName)
  return shortName !== null ? (
    <span className={classes.avatarBtn} onClick={handleClick}>
      <Avatar className={classes.avatar}>{shortName}</Avatar>
    </span>
  ) : (
    <span className={classes.avatarBtn}>
      <AvatarSkeleton></AvatarSkeleton>{' '}
    </span>
  )
}

export default AccountAvatar
