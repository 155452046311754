import React, {
  FormEvent,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Layer from "interfaces/layer";

import Dialog from "@material-ui/core/Dialog";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import Checkbox from "components/form/Checkbox";
import Radio from "components/form/Radio";

import ActivityIndicatorButton from "components/ActivityIndicatorButton";
import CheckBoxList, {
  CheckBoxListGroupedData,
} from "components/CheckBoxList/CheckBoxList";

import { RB_YELLOW } from "constants/colors";
import { Controller, useForm } from "react-hook-form";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import {
  MAP_STYLE_DARK,
  MAP_STYLE_LIGHT,
  MAP_STYLE_SATELLITE,
  MAP_STYLE_TOPOGRAPHIC,
} from "../../constants/mapStyles";
import moment from "moment-timezone";
import { AssessmentTypes } from "../../constants/assessmentTypes";
import { LayerTypes } from "../../constants/layerTypes";
import RBAPI from "api/RoadwayAPI";

export interface EmbeddedMapsUpdateFormState {
  name: string;
  id?: string;
  scanId: string;
  layers: string[];
  enabled: boolean;
  showNetworkLength: boolean;
  showNetworkScore: boolean;
  showPotholes: boolean;
  style: string;
}

interface EmbeddedMapsUpdateFormDialogProps {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  handleUpdate: (params: EmbeddedMapsUpdateFormState) => void;
  values: EmbeddedMapsUpdateFormState;
  isUpdateActive: boolean;
  scans: object[];
  isExampleOrganizationCreating: boolean;
}

const useStyles = makeStyles({
  form: {
    marginBottom: "10px",
  },
  cardHeaderRoot: {
    padding: "4px 16px",
    backgroundColor: RB_YELLOW,
  },
  gridContainerRoot: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  cardContentRoot: {
    padding: "8px 4px 16px 16px",
  },
  formControl: {
    width: "100%",
  },
  radioGroup: {
    flexDirection: "row",
  },
});

const EmbeddedMapsUpdateFormDialog = ({
  title,
  isOpen,
  handleClose,
  handleUpdate,
  values: initialFormState,
  isUpdateActive,
  scans,
  isExampleOrganizationCreating,
}: EmbeddedMapsUpdateFormDialogProps): ReactNode => {
  const [scanLayersData, setScanLayersData] = useState<CheckBoxListGroupedData>(
    {}
  );
  const [isScanLayersDataLoading, setIsScanLayersDataLoading] = useState(false);
  const [assessmentType, setAssessmentType] = useState("");

  const classes = useStyles();
  const { register, control, setValue, errors, watch, handleSubmit } = useForm({
    defaultValues: initialFormState,
  });

  const scanId = watch("scanId");
  const layers = watch("layers");
  const name = watch("name");

  const getLevelsDescription = (level: number): string => {
    switch (level) {
      case 6:
        return "Not Paved";
      case 7:
        return "Candidate";
      case 8:
        return "Sealed";
      default:
        return `${level}`;
    }
  };

  const getLayerPrefix = (type: string): string => {
    switch (type) {
      case LayerTypes.POINT:
      case LayerTypes.SEGMENT:
        return "Rating";
      case LayerTypes.POTHOLE:
        return "Potholes";
      case LayerTypes.IMAGELOGGER_POINT:
      case LayerTypes.CRACKSEAL:
        return "";
      default:
        return type;
    }
  };

  const getLayerCheckboxLabel = useCallback(
    (type: string, level: number, date: string): string => {
      const layerPrefix = level === 6 ? "" : getLayerPrefix(type);
      const levelDescription = getLevelsDescription(level);
      switch (type) {
        case LayerTypes.POTHOLE:
          return `${layerPrefix}`;
        case LayerTypes.IMAGELOGGER_POINT:
          return `${layerPrefix} ${date}`;
        default:
          return `${layerPrefix} ${levelDescription}`;
      }
    },
    []
  );

  // Load scan layers when scan value changes
  useEffect(() => {
    const onChangeScan = async () => {
      if (scanId) {
        try {
          const scan = await RBAPI.fetchScan(scanId).catch((err: any) => {
            if (err?.response?.status === 404) {
              return;
            }
            throw err;
          });
          setAssessmentType(scan.assessmentType);

          setIsScanLayersDataLoading(true);
          const scanLayers: Layer[] = await RBAPI.fetchLayersByScan(
            scanId
          ).catch((err: any) => {
            if (err?.response?.status === 404) {
              return;
            }
            throw err;
          });

          // sort layers by level and then group layers by type
          const data: CheckBoxListGroupedData = scanLayers
            .sort(
              (layer1, layer2) => (layer1?.level || 0) - (layer2?.level || 0)
            )
            .reduce(
              (
                groupedData: CheckBoxListGroupedData,
                { id, level, type, dateDriven }
              ) => {
                const date = moment(dateDriven).format("MMM Do YYYY h:mm a");
                if (type) {
                  if (!groupedData[type]) {
                    groupedData[type] = [];
                  }

                  groupedData[type].push({
                    name: id,
                    value: getLayerCheckboxLabel(type, level, date),
                  });
                }
                return groupedData;
              },
              {}
            );

          setScanLayersData(data);
        } catch (e) {
          console.error("No layers data for ", scanId, e);
        } finally {
          setIsScanLayersDataLoading(false);
        }
      }
    };
    onChangeScan();
  }, [scanId, getLayerCheckboxLabel]);

  const handleFormSubmit = (event: FormEvent): void => {
    event.preventDefault();
    handleSubmit(data => {
      if (initialFormState?.id !== undefined)
        data = { ...data, id: initialFormState.id };
      handleUpdate(data as EmbeddedMapsUpdateFormState);
    })(event);
  };

  const onChangeLayersSelect = (layers: string[]): void => {
    setValue("layers", layers);
  };

  const renderForm = (): ReactNode => (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <TextField
            label="Embedded Map Name"
            name="name"
            error={errors?.name !== undefined}
            helperText={errors?.name?.message}
            inputRef={register({
              required: "This field is required",
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Controller
                as={<Checkbox />}
                name="enabled"
                control={control}
                defaultValue={initialFormState.enabled}
              />
            }
            label="Public"
          />
          {assessmentType !== AssessmentTypes.IMAGELOGGER && (
            <FormControlLabel
              control={
                <Controller
                  as={<Checkbox />}
                  name="showNetworkScore"
                  control={control}
                  defaultValue={initialFormState.showNetworkScore}
                />
              }
              label="Network Score"
            />
          )}
          {assessmentType !== AssessmentTypes.IMAGELOGGER && (
            <FormControlLabel
              control={
                <Controller
                  as={<Checkbox />}
                  name="showNetworkLength"
                  control={control}
                  defaultValue={initialFormState.showNetworkLength}
                />
              }
              label="Network Length"
            />
          )}
          {scanLayersData.Pothole && (
            <FormControlLabel
              control={
                <Controller
                  as={<Checkbox />}
                  name="showPotholes"
                  control={control}
                  defaultValue={initialFormState.showPotholes}
                />
              }
              label="Potholes"
            />
          )}
        </Grid>
      </Grid>
      <FormControl
        className={classes.formControl}
        error={errors?.style !== undefined}
      >
        <Controller
          name="style"
          control={control}
          defaultValue={initialFormState.style}
          rules={{
            required: "This field is required",
          }}
          as={
            <RadioGroup className={classes.radioGroup}>
              <FormControlLabel
                value={MAP_STYLE_DARK}
                control={<Radio />}
                label="Dark"
              />
              <FormControlLabel
                value={MAP_STYLE_LIGHT}
                control={<Radio />}
                label="Light"
              />
              <FormControlLabel
                value={MAP_STYLE_SATELLITE}
                control={<Radio />}
                label="Satellite"
              />
              <FormControlLabel
                value={MAP_STYLE_TOPOGRAPHIC}
                control={<Radio />}
                label="Topographic"
              />
            </RadioGroup>
          }
        />
        <FormHelperText>{errors?.style?.message}</FormHelperText>
      </FormControl>
      <FormControl
        className={classes.formControl}
        error={errors?.scanId !== undefined}
      >
        <Controller
          as={
            <Select displayEmpty name="scanId" inputRef={register}>
              <MenuItem value="" disabled>
                Select Scan
              </MenuItem>
              {scans &&
                scans.map((scan: any) => (
                  <MenuItem key={scan.id} value={scan.id}>
                    {scan.displayName || scan.name}
                  </MenuItem>
                ))}
            </Select>
          }
          name="scanId"
          control={control}
          rules={{
            required: "This field is required",
          }}
          defaultValue={initialFormState.scanId}
        />
        <FormHelperText>{errors?.scanId?.message}</FormHelperText>
      </FormControl>
      <Controller
        name="layers"
        as={
          <CheckBoxList
            data={scanLayersData}
            handleChange={onChangeLayersSelect}
            selected={layers}
            isDataLoading={isScanLayersDataLoading}
            titleMessage={
              scanLayersData.Segment ||
              scanLayersData.Point ||
              scanLayersData.Pothole ||
              scanLayersData.Imageloggerpoint
                ? "Select layers (select at least 1)"
                : "No layers available"
            }
            noDataMessage="No scan selected"
          />
        }
        control={control}
        rules={{
          required: "This field is required",
        }}
        defaultValue={initialFormState.layers}
      />
    </div>
  );

  const renderFormActions = (): ReactNode => (
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <ActivityIndicatorButton
        isActive={isUpdateActive}
        progressSize={14}
        disableButtonWhileActive={true}
        disabled={
          !isExampleOrganizationCreating && name && scanId && layers.length > 0
            ? false
            : true
        }
        type="submit"
      >
        Submit
      </ActivityIndicatorButton>
    </DialogActions>
  );

  return (
    <Dialog
      title={title}
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth={"sm"}
    >
      <form onSubmit={handleFormSubmit} className={classes.form}>
        <DialogContent>{renderForm()}</DialogContent>
        {renderFormActions()}
      </form>
    </Dialog>
  );
};

EmbeddedMapsUpdateFormDialog.defaultProps = {
  title: "",
  isOpen: false,
  handleClose: (): void => {
    return;
  },
};

export default EmbeddedMapsUpdateFormDialog;
