import React, { useState, useCallback, useEffect } from 'react'
import { default as MUIDrawer } from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import {
  RB_DARK_GREY,
  RB_YELLOW,
  RB_SIDEBAR_BACKGROUND
} from '../../constants/colors'
import makeStyles from '@material-ui/core/styles/makeStyles'
import RoadboticsLogoNoText from '../../images/RoadboticsLogoNoText.png'
import clsx from 'clsx'
import AgileMapperIcon from '../SVGs/AgileMapperSVG'
import RoadWayIcon from '../SVGs/RoadWaySVG'

import Notifications from '../Notifications/Notifications'
import Settings from '../Settings/Settings'
import Tooltip from '@material-ui/core/Tooltip'
import FAQ from '../FAQ/FAQ'
import Account from '../Account/Account'
import { UserStatus } from '../../interfaces/user'
import { RBAPI } from '../../api/roadboticsAPI'

const osDrawerBorderWidth = 0
const osHighlightLeftBorderWidth = 6
export const osDrawerWidth =
  55 + osDrawerBorderWidth + osHighlightLeftBorderWidth

const useStyles = makeStyles((theme) => ({
  osRoot: {
    display: 'flex'
  },
  osTopList: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  osImageContainer: {
    display: 'flex',
    background: RB_YELLOW,
    justifyContent: 'center'
  },
  osImage: {
    width: '50px',
    padding: '6px'
  },
  osListItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px'
  },
  osPaper: {
    borderRight: `${osDrawerBorderWidth}px`,
    background: RB_SIDEBAR_BACKGROUND,
    width: osDrawerWidth,
    zIndex: 100000
  },
  osAvatarText: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '12px',
    fontWeight: 900,
    color: RB_DARK_GREY,
    padding: theme.spacing(1)
  },
  osAvatarContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    paddingTop: theme.spacing(1)
  },
  osHighlight: {
    borderLeft: `${osHighlightLeftBorderWidth}px solid ${RB_DARK_GREY}`,
    background: 'white'
  },
  osChildren: {
    position: 'relative',
    left: osDrawerWidth,
    width: `calc(100% - ${osDrawerWidth}px)`
  },
  osDrawerDivider: { width: '75%', marginTop: '10px' },
  osBottomDivider: { width: '75%', margin: 'auto' },
  osDrawer: {
    position: 'absolute'
  },
  sidebarSettings: {
    bottom: 0,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: osDrawerWidth
  }
}))

interface RouteInterface {
  name: string
  shortName: string
  url: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any
  highlighted: boolean
}

const routes: RouteInterface[] = [
  {
    name: 'RoadWay',
    shortName: 'RW',
    url: 'https://roadway.roadbotics.com/',
    icon: RoadWayIcon,
    highlighted: false
  },
  {
    name: 'AgileMapper',
    shortName: 'AM',
    url: 'https://agilemapper.roadbotics.com/',
    icon: AgileMapperIcon,
    highlighted: false
  }
  // TODO bring these back when the products are available
  // {
  //   name: 'Blueprinter',
  //   shortName: 'BP',
  //   url: 'url',
  //   icon: BlueprinterIcon,
  //   highlighted: false
  // },

  // {
  //   name: 'GIS',
  //   shortName: 'GIS',
  //   url: 'url',
  //   icon: GisIcon,
  //   highlighted: false
  // },
  // {
  //   name: 'PublicSite',
  //   shortName: 'PS',
  //   url: 'url',
  //   icon: PublicSiteIcon,
  //   highlighted: false
  // },
  // {
  //   name: 'RoadNav',
  //   shortName: 'RN',
  //   url: 'url',
  //   icon: RoadNavIcon,
  //   highlighted: false
  // },
]

interface SideBarProps {
  children: React.ReactNode
  userUId: string
  logout: (e: React.MouseEvent) => void
}

const setHighlightedProduct = (routes: RouteInterface[]): void => {
  routes.forEach((route: RouteInterface) => {
    route.highlighted = window.location.host.includes(route.name.toLowerCase())
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SideBar = ({
  children,
  userUId,
  logout
}: SideBarProps): React.ReactElement => {
  const [user, setUser] = useState({
    email: '',
    firstName: '',
    lastName: '',
    lastLogin: '',
    roadwayWelcome: true,
    userUId: '',
    id: '',
    status: UserStatus.PENDING,
    isFreeTrialUser: false
  })

  const getUser = useCallback(async () => {
    return await RBAPI.getUser(userUId)
      .catch((e: ErrorEvent) => console.error(e))
      .then((resp: any) => {
        setUser(resp)
      })
  }, [userUId])

  useEffect(() => {
    if (userUId) {
      getUser()
    }
  }, [userUId, getUser])

  const classes = useStyles()

  setHighlightedProduct(routes)
  return (
    <div className={classes.osRoot} id='root-container'>
      <MUIDrawer
        variant='permanent'
        open={true}
        className={classes.osDrawer}
        PaperProps={{ className: classes.osPaper }}
      >
        <div className={classes.osImageContainer}>
          <img
            className={classes.osImage}
            src={RoadboticsLogoNoText}
            alt='logo'
          />
        </div>

        <Divider />
        <List className={classes.osTopList}>
          {routes.map(({ name, shortName, url, icon, highlighted }) => (
            <ListItem
              className={classes.osListItem}
              button
              key={name}
              target='_blank' // Remove this if you don't want to open a new tab
              component='a'
              href={url}
            >
              <Tooltip title={name} placement='right'>
                <div
                  className={clsx(
                    classes.osAvatarContainer,
                    highlighted && classes.osHighlight
                  )}
                >
                  <Avatar
                    component={icon}
                    height='40px'
                    width='40px'
                    fill={highlighted ? RB_YELLOW : RB_DARK_GREY}
                    variant={'square'}
                  >
                    {shortName}
                  </Avatar>
                  {!highlighted && (
                    <Divider className={classes.osDrawerDivider} />
                  )}
                </div>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        <List className={classes.sidebarSettings}>
          <Divider className={classes.osBottomDivider} />
          <ListItem button>
            <Notifications lastLogin={user.lastLogin} />
          </ListItem>
          <Divider className={classes.osBottomDivider} />
          <ListItem button>
            <FAQ />
          </ListItem>
          <Divider className={classes.osBottomDivider} />
          <ListItem button>
            <Settings
              userUId={userUId}
              isFreeTrialUser={user.isFreeTrialUser}
            />
          </ListItem>
          <Divider className={classes.osBottomDivider} />
          <ListItem button>
            <Account logout={logout} user={user} />
          </ListItem>
        </List>
      </MUIDrawer>

      <div className={classes.osChildren}>{children}</div>
    </div>
  )
}

export default SideBar
