import React from "react";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";
import logo from "images/logos/transparentLogo.png";
import track from "react-tracking";
import { ScreenCaptureTypes } from "constants/screenshotTypes";
import * as Sentry from "@sentry/browser/dist/index";

class ScreenCapture extends React.Component {
  state = {
    startX: 0,
    startY: 0,
    isMouseDown: false,
    windowWidth: 0,
    windowHeight: 0,
    borderWidth: 0,
    cropPositionTop: 0,
    cropPositionLeft: 0,
    cropWidth: 0,
    cropHeight: 0,
  };

  componentDidMount = () => {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleWindowResize);
  };

  handleWindowResize = () => {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    this.setState({
      windowWidth,
      windowHeight,
    });
  };

  handStartCapture = type => {
    const { tracking, turnOnScreenCapture } = this.props;
    tracking.trackEvent({
      event: "mouse-click",
      action: "screen-capture-start-capture",
    });
    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "screen capture start capture",
      level: Sentry.Severity.Info,
    });
    if (type !== ScreenCaptureTypes.FULLSCREEN) {
      turnOnScreenCapture(ScreenCaptureTypes.SELECTED);
    } else {
      turnOnScreenCapture(ScreenCaptureTypes.FULLSCREEN);
      this.handleClickTakeScreenShot(type);
    }
  };

  handleMouseMove = e => {
    const {
      isMouseDown,
      windowWidth,
      windowHeight,
      startX,
      startY,
      borderWidth,
    } = this.state;

    let cropPositionTop = startY;
    let cropPositionLeft = startX;
    const endX = e.clientX;
    const endY = e.clientY;
    const isStartTop = endY >= startY;
    const isStartBottom = endY <= startY;
    const isStartLeft = endX >= startX;
    const isStartRight = endX <= startX;
    const isStartTopLeft = isStartTop && isStartLeft;
    const isStartTopRight = isStartTop && isStartRight;
    const isStartBottomLeft = isStartBottom && isStartLeft;
    const isStartBottomRight = isStartBottom && isStartRight;
    let newBorderWidth = borderWidth;
    let cropWidth = 0;
    let cropHeight = 0;

    if (isMouseDown) {
      if (isStartTopLeft) {
        newBorderWidth = `${startY}px ${windowWidth - endX}px ${windowHeight -
          endY}px ${startX}px`;
        cropWidth = endX - startX;
        cropHeight = endY - startY;
      }

      if (isStartTopRight) {
        newBorderWidth = `${startY}px ${windowWidth - startX}px ${windowHeight -
          endY}px ${endX}px`;
        cropWidth = startX - endX;
        cropHeight = endY - startY;
        cropPositionLeft = endX;
      }

      if (isStartBottomLeft) {
        newBorderWidth = `${endY}px ${windowWidth - endX}px ${windowHeight -
          startY}px ${startX}px`;
        cropWidth = endX - startX;
        cropHeight = startY - endY;
        cropPositionTop = endY;
      }

      if (isStartBottomRight) {
        newBorderWidth = `${endY}px ${windowWidth - startX}px ${windowHeight -
          startY}px ${endX}px`;
        cropWidth = startX - endX;
        cropHeight = startY - endY;
        cropPositionLeft = endX;
        cropPositionTop = endY;
      }
    }

    this.setState({
      borderWidth: newBorderWidth,
      cropWidth,
      cropHeight,
      cropPositionTop,
      cropPositionLeft,
    });
  };

  handleMouseDown = e => {
    const startX = e.clientX;
    const startY = e.clientY;

    this.setState(prevState => ({
      startX,
      startY,
      cropPositionTop: startY,
      cropPositionLeft: startX,
      isMouseDown: true,
      borderWidth: `${prevState.windowWidth}px ${prevState.windowHeight}px`,
    }));
  };

  handleMouseUp = () => {
    this.handleClickTakeScreenShot();
    this.setState({
      isMouseDown: false,
      borderWidth: 0,
    });
  };

  handleClickTakeScreenShot = type => {
    const { tracking, sidebarVisibility, turnOffScreenCapture } = this.props;
    tracking.trackEvent({
      event: "mouse-click",
      action: "screen-capture-cropped-canvas-made",
    });
    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: "screen capture cropped canvas made",
      level: Sentry.Severity.Info,
    });
    const {
      cropPositionTop,
      cropPositionLeft,
      cropWidth,
      cropHeight,
    } = this.state;
    const body = document.querySelector("body");
    const logoImage = new Image();
    logoImage.src = `${logo}`;
    let finalCanvas;
    html2canvas(body, {
      allowTaint: true,
      useCORS: true,
      scale: 1,
      logging: false,
    })
      .then(canvas => {
        const croppedCanvas = document.createElement("canvas");
        const croppedCanvasContext = croppedCanvas.getContext("2d");
        let width;
        let left;
        let top;
        let height;
        let logoWidth;
        let logoMargin;

        const clientWidth = document.body.clientWidth;
        const clientLeft = document.body.clientLeft;
        const sidebarClientWidth = document.getElementById("sidebarId")
          .clientWidth;
        const appbarHeight = document.getElementById("appBarId").clientHeight;
        const clientHeight = Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.clientHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight
        );

        if (type === ScreenCaptureTypes.FULLSCREEN) {
          width = sidebarVisibility
            ? clientWidth - sidebarClientWidth
            : clientWidth;
          left = sidebarVisibility
            ? clientLeft + sidebarClientWidth
            : clientLeft;
          top = appbarHeight;
          height = clientHeight - appbarHeight;

          croppedCanvas.width = width;
          croppedCanvas.height = height;
          logoWidth = 70;
          logoMargin = 15;
        } else {
          left = cropPositionLeft;
          top = cropPositionTop;
          width = cropWidth;
          height = cropHeight;
          logoMargin = 0.02 * cropWidth;
          logoWidth = 0.05 * cropWidth;
          croppedCanvas.width = cropWidth;
          croppedCanvas.height = cropHeight;
        }

        croppedCanvasContext.drawImage(
          canvas,
          left,
          top,
          width,
          height,
          0,
          0,
          width,
          height
        );
        croppedCanvasContext.drawImage(
          logoImage,
          logoMargin,
          logoMargin,
          logoWidth,
          logoWidth
        );
        finalCanvas = croppedCanvas;
      })
      .catch(error => {
        console.error("Error while capturing screenshot ", error);
      })
      .then(() => {
        this.props.onEndCapture(finalCanvas);
      })
      .catch(error => {
        console.error("Error while capturing screenshot ", error);
      })
      .then(() => {
        turnOffScreenCapture();
      });
  };

  renderChild = () => {
    const { children } = this.props;

    const props = {
      onStartCapture: this.handStartCapture,
    };

    if (typeof children === "function") return children(props);
    return children;
  };

  renderScreenWithDarkOverlay() {
    const { borderWidth, isMouseDown } = this.state;
    return (
      <div
        role="presentation"
        onMouseMove={this.handleMouseMove}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
      >
        {this.renderChild()}
        <div
          className={`overlay ${isMouseDown && "highlighting"}`}
          style={{ borderWidth }}
        />
      </div>
    );
  }

  render() {
    const { screenCapture } = this.props;

    if (
      screenCapture.isActive &&
      screenCapture.type === ScreenCaptureTypes.SELECTED
    ) {
      return this.renderScreenWithDarkOverlay();
    } else {
      return this.renderChild();
    }
  }
}

ScreenCapture.defaultProps = {
  tracking: () => null,
  sidebarVisibility: true,
  children: () => null,
  onEndCapture: () => null,
};

ScreenCapture.propTypes = {
  tracking: PropTypes.object,
  onEndCapture: PropTypes.func,
  turnOnScreenCapture: PropTypes.func.isRequired,
  turnOffScreenCapture: PropTypes.func.isRequired,
  sidebarVisibility: PropTypes.bool,
  screenCapture: PropTypes.object.isRequired,
  children: PropTypes.func,
};

export default track()(ScreenCapture);
