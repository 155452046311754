import React, { useState } from "react";
import PropTypes from "prop-types";
import firebase from "config/firebase";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { MessageTypes } from "actions/snackbar";
import Button from "components/form/Button";
import { ExpansionPanelActions } from "@material-ui/core";

const styles = makeStyles({
  fieldDiv: { margin: "2% 0" },
  textField: {
    textAlign: "center",
    width: "50%",
  },
  wholeDiv: {
    margin: "25px auto",
    width: "50%",
    textAlign: "center",
    border: "1px red solid",
  },
  details: {
    textAlign: "center",
  },
});

interface PasswordProps {
  setSnackbarMessageAndOpen: Function;
}

const PasswordSettingsContainer = (props: PasswordProps): React.ReactNode => {
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");

  const handleNewPassword = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (currentPassword !== newPassword) {
      if (newPassword.length >= 8) {
        // @ts-ignore
        const firebaseUser = firebase.auth().currentUser;

        // @ts-ignore
        const credential = firebase.auth.EmailAuthProvider.credential(
          // @ts-ignore
          firebaseUser.email,
          currentPassword
        );
        // @ts-ignore
        firebaseUser
          .reauthenticateWithCredential(credential)
          .then(resp => {
            // @ts-ignore
            resp.user
              .updatePassword(newPassword)
              .then(() =>
                props.setSnackbarMessageAndOpen("Successfully updated password")
              )
              .catch(e => console.error("could not update", e));
          })
          .catch(e => {
            if (e.code === "auth/wrong-password") {
              props.setSnackbarMessageAndOpen(
                "The current password you've entered is incorrect.",
                MessageTypes.ERROR
              );
            }
            console.error("Error Reauthenticating", e);
          });
      } else {
        props.setSnackbarMessageAndOpen(
          "The new password must be 8 characters or more",
          MessageTypes.ERROR
        );
      }
    } else {
      props.setSnackbarMessageAndOpen(
        "The new password cannot be the same as the current password",
        MessageTypes.ERROR
      );
    }
  };

  const classes = styles();
  return (
    <div className={classes.wholeDiv}>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Change Password</Typography>
        </ExpansionPanelSummary>
        <form onSubmit={handleNewPassword}>
          <div className={classes.fieldDiv}>
            <Typography>Current Password:</Typography>
            <TextField
              id="old-password"
              label="Current Password"
              value={currentPassword}
              variant="outlined"
              margin="dense"
              onChange={e => setCurrentPassword(e.target.value)}
              inputProps={{ type: "password" }}
              className={classes.textField}
            />
          </div>
          <div className={classes.fieldDiv}>
            <Typography>New Password:</Typography>
            <TextField
              disabled={!currentPassword}
              id="new-password"
              label={
                !currentPassword
                  ? "Please enter your current password"
                  : "New Password"
              }
              margin="dense"
              value={newPassword}
              variant="outlined"
              onChange={e => setNewPassword(e.target.value)}
              inputProps={{ type: "password" }}
              className={classes.textField}
            />
          </div>
          <ExpansionPanelActions>
            <Button variant="contained" color="primary" type={"submit"}>
              Update Password
            </Button>
          </ExpansionPanelActions>
        </form>
      </ExpansionPanel>
    </div>
  );
};

PasswordSettingsContainer.propTypes = {
  setSnackbarMessageAndOpen: PropTypes.func.isRequired,
};

export default PasswordSettingsContainer;
