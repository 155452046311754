import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { toggleColorBlindMode } from "actions/colorPicker";
import { measureColorChange } from "actions/measureDistance";

import ColorPreference from "components/ColorPreference";
import track from "react-tracking";
import * as Sentry from "@sentry/browser/dist/index";

const ColorPreferenceContainer = props => {
  // handles the changing of the the specific color blind modes

  const handleChange = event => {
    const { toggleColorBlindMode, measureColorChange, tracking } = props;
    const value = event.target.value;

    tracking.trackEvent({
      event: "mouse-click",
      action: `change-colorblind-${value}`,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `change-colorblind-${value}`,
      level: Sentry.Severity.Info,
    });

    measureColorChange();
    toggleColorBlindMode(value);
  };
  const { colorPicker } = props;
  return (
    <ColorPreference
      colorValue={colorPicker.colorBlindMode}
      handleChange={handleChange}
    />
  );
};

ColorPreferenceContainer.defaultProps = {
  measureColorChange: () => null,
};

ColorPreferenceContainer.propTypes = {
  toggleColorBlindMode: PropTypes.func.isRequired,
  measureColorChange: PropTypes.func,
  tracking: PropTypes.object.isRequired,
  colorPicker: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  colorPicker: state.colorPicker,
});

const mapDispatchToProps = dispatch => ({
  toggleColorBlindMode: type => dispatch(toggleColorBlindMode(type)),
  measureColorChange: () => dispatch(measureColorChange()),
});

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(ColorPreferenceContainer)
);
