import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import track from "react-tracking";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import flattenDeep from "lodash/flattenDeep";
import uniqBy from "lodash/uniqBy";

import { setSnackbarMessageAndOpen, MessageTypes } from "actions/snackbar";

import OrganizationUsers from "components/admin/OrganizationUsers";
import { validateEmail } from "utils/validateEmail";
import { RB_YELLOW } from "constants/colors";

import {
  useLoadOrganizationUsersData,
  useLoadOrganizationScansData,
} from "hooks/useLoadOrganizationData";

import UsersAdminUpdateFormDialog from "./UsersAdminUpdateFormDialog";
import UsersAdminInviteFormDialog from "./UsersAdminInviteFormDialog";
import * as Sentry from "@sentry/browser/dist/index";
import RBAPI from "api/RoadwayAPI";

const useStyles = makeStyles({
  button: { backgroundColor: RB_YELLOW, textTransform: "none" },
});

const UsersAdminContainer = props => {
  const {
    currentOrganization,
    organizationUsers,
    organizationScans,
    isOrganizationUsersDataLoaded,
    setSnackbarMessageAndOpen,
    userId,
    tracking,
    openDeletePopup,
    isDeleteDialogOpen,
  } = props;
  const newUserInvite = {
    email: "",
    role: "",
  };

  const [updateFormValues, setUpdateFormValues] = useState({});
  const [inviteFormValues] = useState(newUserInvite);
  const [isUpdateFormOpen, setIsUpdateFormOpen] = useState(false);
  const [isUserUpdateActive, setIsUserUpdateActive] = useState(false);
  const [isInviteFormOpen, setIsInviteFormOpen] = useState(false);
  const [isSendInviteActive, setIsSendInviteActive] = useState(false);
  const [invitingEmail, setInvitingEmail] = useState("");

  const handleError = (e, message) => {
    setSnackbarMessageAndOpen(message, MessageTypes.ERROR);
    if (e.response.status !== 400) {
      throw e;
    }
  };

  const loadCurrentOrganizationUsers = useLoadOrganizationUsersData(
    currentOrganization.id
  );
  const loadCurrentOrganizationScans = useLoadOrganizationScansData(
    currentOrganization.id
  );

  const handleCloseUpdateForm = () => setIsUpdateFormOpen(false);
  const handleCloseInviteForm = () => setIsInviteFormOpen(false);

  const handleClickInvite = () => setIsInviteFormOpen(true);

  const handleUpdate = async selectedUser => {
    const statusMessage = "Edit saved";
    try {
      setIsUserUpdateActive(true);
      await RBAPI.updateUser(selectedUser).catch(err => {
        if (err?.response?.status === 404) {
          //
          setSnackbarMessageAndOpen(
            "Could not update user",
            MessageTypes.ERROR
          );
          return;
        }
        throw err;
      });
      setSnackbarMessageAndOpen(statusMessage);
      loadCurrentOrganizationUsers(true);
    } catch (e) {
      const statusMessage = "Failed to save the edit";
      handleError(e, statusMessage);
    } finally {
      tracking.trackEvent({
        event: "mouse-click",
        action: `admin-user-update-${selectedUser.id}`,
        userUId: userId,
      });

      Sentry.addBreadcrumb({
        category: "mouse-click",
        message: `admin user update ${selectedUser.id}`,
        level: Sentry.Severity.Info,
      });
      setIsUserUpdateActive(false);
      setIsUpdateFormOpen(false);
    }
  };

  const handleEdit = selectedUser => {
    setUpdateFormValues(selectedUser);
    setIsUpdateFormOpen(true);
  };

  const handleInvite = async ({ email, role }) => {
    if (validateEmail(email)) {
      setIsSendInviteActive(true);
      setInvitingEmail(email);
      try {
        await RBAPI.inviteUser({
          email,
          role,
          organizationId: currentOrganization.id,
        });
        setSnackbarMessageAndOpen("Invite email sent to new user");
        loadCurrentOrganizationUsers(true);
      } catch (e) {
        // eslint-disable-next-line max-len
        const statusMessage = `Failed to invite ${email}. Please ensure that they have not been previously invited. If not, please reach out to support@roadbotics.com.`;
        handleError(e, statusMessage);
      } finally {
        tracking.trackEvent({
          event: "mouse-click",
          action: `admin-user-invite`,
          userUId: userId,
        });

        Sentry.addBreadcrumb({
          category: "mouse-click",
          message: `admin user invite ${email}`,
          level: Sentry.Severity.Info,
        });
        setIsSendInviteActive(false);
        setIsInviteFormOpen(false);
        setInvitingEmail("");
      }
    }
  };

  const onChangeCurrentOrganization = () => {
    if (currentOrganization.hasOwnProperty("id")) {
      loadCurrentOrganizationUsers();
      loadCurrentOrganizationScans();
    }
  };

  // fetch users (and scans for new invite dialog) when an organization is selected
  useEffect(onChangeCurrentOrganization, [currentOrganization]);

  const roles = ["Admin", "Member", "Viewer"];

  const selectedOrganizationUsers =
    organizationUsers[currentOrganization.id] || [];

  const allOrganizationScans = () => {
    const allScans = [
      ...(get(organizationScans, currentOrganization.id) || []),
    ];
    if (currentOrganization.childrenOrganizations) {
      Object.values(currentOrganization.childrenOrganizations).forEach(
        child => {
          allScans.push(child.scans);
        }
      );
    }
    let flatScans = flattenDeep(allScans);
    flatScans = uniqBy(flatScans, "id");
    return flatScans;
  };

  const getAllOrganizationScans = allOrganizationScans();

  useEffect(() => {
    return () => {
      if (isDeleteDialogOpen) {
        loadCurrentOrganizationUsers(true);
      }
    };
  }, [isDeleteDialogOpen, loadCurrentOrganizationUsers]);

  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={10}>
          <OrganizationUsers
            isUsersLoaded={isOrganizationUsersDataLoaded}
            organizationName={currentOrganization.name}
            organizationUsers={selectedOrganizationUsers}
            handleDelete={openDeletePopup}
            handleEdit={handleEdit}
            handleClickInvite={handleClickInvite}
            handleInvite={handleInvite}
            currentUserId={userId}
            isSendInviteActive={isSendInviteActive}
            invitingEmail={invitingEmail}
          />
        </Grid>
      </Grid>
      {isUpdateFormOpen && (
        <UsersAdminUpdateFormDialog
          classes={classes}
          handleUpdate={handleUpdate}
          roles={roles}
          isOpen
          handleClose={handleCloseUpdateForm}
          isUpdateActive={isUserUpdateActive}
          values={updateFormValues}
          title="Edit User"
        />
      )}
      {isInviteFormOpen && (
        <UsersAdminInviteFormDialog
          classes={classes}
          handleInvite={handleInvite}
          roles={roles}
          scans={getAllOrganizationScans}
          organization={currentOrganization}
          isOpen
          handleClose={handleCloseInviteForm}
          isSubmitActive={isSendInviteActive}
          values={inviteFormValues}
          title="Send Invite Email"
        />
      )}
    </div>
  );
};

UsersAdminContainer.defaultProps = {
  currentOrganization: {},
  userId: null,
};

UsersAdminContainer.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    childrenOrganizations: PropTypes.array,
  }),
  organizationUsers: PropTypes.object.isRequired,
  organizationScans: PropTypes.object.isRequired,
  isOrganizationUsersDataLoaded: PropTypes.bool.isRequired,
  setSnackbarMessageAndOpen: PropTypes.func.isRequired,
  userId: PropTypes.string,
  tracking: PropTypes.object.isRequired,
  openDeletePopup: PropTypes.func.isRequired,
  isDeleteDialogOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ userData, user }) => ({
  userId: user.userUId,
  organizationUsers: userData.organizationUsers,
  organizationScans: userData.organizationScans,
  isOrganizationUsersDataLoaded: userData.isOrganizationUsersDataLoaded,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSnackbarMessageAndOpen,
    },
    dispatch
  );

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(UsersAdminContainer)
);
