import { hideModal, openModal, updateModal, stopModal } from "actions/modal";
import {
  setAzimuth,
  setPointCoords,
  setOriginalAzimuth,
  showHighlight,
  hideHighlight,
} from "actions/highlight";
import { POINT } from "constants/highlightTypes";
import { bearingToAzimuth } from "@turf/helpers";
import { closeSidebar } from "actions/visibility";
import { isIPad } from "utils/deviceCheck";
import moment from "moment-timezone";
import { centerMap } from "../pointsUtils";
import { parseTimestamp } from "../timestamp";
import returnAddress from "../returnAddress";

const imageLoggerPoint = async ({
  feature,
  dispatch,
  dispatchHighlight = true,
  showRating = true,
  leftAndRightPoints,
  modalVisibility,
  sidebarVisibility,
  enlargedImg,
  map,
  inSegmentModal = false,
  switchPoint = true,
  modalData = {},
}) => {
  const lng = feature.geometry.coordinates[0];
  const lat = feature.geometry.coordinates[1];
  const address = await returnAddress({ lng: lng, lat: lat });

  centerMap({
    map,
    enlargedImg,
    sidebarVisibility,
    modalVisibility,
    lng,
    lat,
  });

  const properties = feature.properties;

  let timestamp;
  if (properties.timestamp) {
    timestamp = moment
      .tz(parseTimestamp(properties.timestamp), moment.tz.guess())
      .format("MMM DD, YYYY, h:mm a");
  } else {
    timestamp = null;
  }

  const geometry = feature.geometry;
  dispatch(setOriginalAzimuth(bearingToAzimuth(feature.properties.bearing)));
  dispatch(
    setAzimuth(bearingToAzimuth(feature.properties.bearing - map.getBearing()))
  );
  dispatch(hideHighlight());
  dispatch(
    setPointCoords([
      parseFloat(geometry.coordinates[0].toFixed(8)),
      parseFloat(geometry.coordinates[1].toFixed(8)),
    ])
  );

  // close the sidebar if on iPad
  if (isIPad()) {
    dispatch(closeSidebar());
  }

  if (switchPoint) {
    dispatch(stopModal());
  }

  if (dispatchHighlight) {
    dispatch(showHighlight(POINT));
  }

  const style = {
    width: "40%",
    padding: "-5%",
    position: "fixed",
    zIndex: "5",
    backgroundColor: "white",
    marginTop: "-5%",
    marginLeft: "0%",
    borderRadius: "10px",
  };
  const expandedStyle = {
    width: "80%",
    position: "fixed",
    zIndex: "5",
    backgroundColor: "white",
    marginTop: "-45%",
    marginLeft: "-45%",
    borderRadius: "10px",
  };
  // remove the rating and level from image logger
  feature.properties.level = null;
  feature.properties.rating = null;
  const content = {
    feature, // need this to prevent the app crashing
    // when toggling individual distress (some lower properties are now redundant)
    imgURL: properties.href,
    color: null,
    rating: null,
    timestamp,
    latitude: parseFloat(geometry.coordinates[1].toFixed(5)),
    longitude: parseFloat(geometry.coordinates[0].toFixed(5)),
    closeButtonClick: hideModal,
    showRating,
    leftAndRightPoints,
    bearing: properties.bearing,
    primaryDistresses: null,
    address: address,
  };
  const config = {
    hideCloseIcon: true,
    disableOverlay: true,
    style,
    expanded: expandedStyle,
  };
  if (inSegmentModal) {
    dispatch(
      updateModal({
        ...modalData,
        type: "point/segment",
        pointData: {
          ...config,
          content,
        },
      })
    );
  } else {
    dispatch(
      openModal({
        type: "point",
        segmentData: {},
        pointData: {
          title: "Circle",
          ...config,
          content,
        },
      })
    );
  }
};

export default imageLoggerPoint;
