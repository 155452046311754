import firebase from "config/firebase";
import { toggleColorBlindMode } from "../actions/colorPicker";
import {
  setHideExampleOrganizations,
  setMeasuringSystem,
} from "../actions/userData";
import store from "../store";

export const preferencesListener = userId => {
  const db = firebase.firestore();

  db.collection("users")
    .doc(userId)
    .collection("settings")
    .doc("preferences")
    .onSnapshot(doc => {
      const data = doc.data();
      if (data) {
        // have to use store.dispatch() since this is not a react component
        store.dispatch(toggleColorBlindMode(data.colorMode));
        store.dispatch(setMeasuringSystem(data.measuringSystem));
        store.dispatch(
          setHideExampleOrganizations(data.hideExampleOrganizations)
        );
      }
    });
};
