import {
  TOGGLE_DEUTERANOPIA,
  TOGGLE_PROTANOPIA,
  TOGGLE_TRITANOPIA,
  TOGGLE_ACHROMATOPIA,
  TURN_OFF_COLORBLIND,
} from "actiontypes/colorPicker";
import { showModal } from "actions/modal";

// red green colorblind
function toggleDeuteranopia() {
  return {
    type: TOGGLE_DEUTERANOPIA,
  };
}

// red green colorblind
function toggleProtanopia() {
  return {
    type: TOGGLE_PROTANOPIA,
  };
}

// blue yellow colorblind
function toggleTritanopia() {
  return {
    type: TOGGLE_TRITANOPIA,
  };
}

// monochromatic colorblind
function toggleAchromatopia() {
  return {
    type: TOGGLE_ACHROMATOPIA,
  };
}

function toggleNormal() {
  return {
    type: TURN_OFF_COLORBLIND,
  };
}

export function toggleColorBlindMode(value) {
  return (dispatch, getState) => {
    switch (value) {
      case "deuteranopia":
        dispatch(toggleDeuteranopia());
        break;
      case "protanopia":
        dispatch(toggleProtanopia());
        break;
      case "tritanopia":
        dispatch(toggleTritanopia());
        break;
      case "achromatopia":
        dispatch(toggleAchromatopia());
        break;
      default:
        dispatch(toggleNormal());
        break;
    }

    // TODO: this is a hacky way of setting the color of the modal piece, refactor the Modal to fix
    const currentState = getState();
    if (
      currentState.modal.isModalOpen &&
      currentState.modal.modalData.type === "segment"
    ) {
      const colorPicker = currentState.colorPicker;
      dispatch(
        showModal({
          ...currentState.modal.modalData,
          content: {
            ...currentState.modal.modalData.content,
            chartData: {
              ...currentState.modal.modalData.content.chartData,
              datasets: currentState.modal.modalData.content.chartData.datasets.map(
                dataset => ({
                  ...dataset,
                  backgroundColor: [
                    colorPicker.RATING_1,
                    colorPicker.RATING_2,
                    colorPicker.RATING_3,
                    colorPicker.RATING_4,
                    colorPicker.RATING_5,
                  ],
                  hoverBackgroundColor: [
                    colorPicker.RATING_1,
                    colorPicker.RATING_2,
                    colorPicker.RATING_3,
                    colorPicker.RATING_4,
                    colorPicker.RATING_5,
                  ],
                })
              ),
            },
          },
        })
      );
    }
  };
}
