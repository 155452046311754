import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import track from "react-tracking";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import {
  setActiveOrganization,
  fetchOrganizations,
  setActiveScan,
} from "actions/userData";
// eslint-disable-next-line max-len
import OrganizationSelectorGridView from "components/OrganizationSelector/OrganizationSelectorGridView";
import get from "lodash/get";
import OrganizationSelector from "../components/OrganizationSelector/OrganizationSelector";
import { ORGANIZATION_SELECTOR_VIEW_FORMAT } from "../constants/organizationSelector";
import { ROUTE_MAP } from "../constants/routes";
import * as Sentry from "@sentry/browser/dist/index";
import { useHistory } from "react-router-dom";

const gridOrgs = organizations => {
  const orgArray = [];

  const checkForGrandChildren = org => {
    let hasGrandChildren = false;
    get(org, "childrenOrganizations", []).forEach(childOrg => {
      if (!hasGrandChildren) {
        hasGrandChildren = get(childOrg, "childOrganizations.length", 0) > 0;
      }
    });
    return hasGrandChildren;
  };

  const orgLoop = org => {
    org.forEach(childOrg => {
      // Prevent all of the roadbotics orgs to be added redundantly under roadbotics
      if (childOrg.parentOrganizationId !== "null") {
        orgArray.push(childOrg);
      }

      // If the parentOrg is null or there are grandchildren, loop over the children.
      // Checking the grandchildren because if there are no grandchildren then...
      // ...it is a partner and thus we don't want to redundantly...
      // ...add the child orgs in the grid view
      if (
        childOrg.parentOrganizationId === "null" ||
        checkForGrandChildren(childOrg)
      ) {
        orgLoop(childOrg.childrenOrganizations);
      }
    });
  };
  if (organizations) {
    orgLoop(organizations);
  }
  return orgArray;
};

const OrganizationSelectorContainer = ({
  user: { userUId },
  organizations,
  setActiveOrganization,
  isOrganizationsDataLoaded,
  organizationScans,
  measuringSystem,
  tracking,
  setActiveScan,
}) => {
  const history = useHistory();
  const [viewFormat, setViewFormat] = useState(
    ORGANIZATION_SELECTOR_VIEW_FORMAT.GRID
  );

  const [gridOrganizations, setGridOrganizations] = useState([]);

  const handleViewChange = viewFormat => {
    tracking.trackEvent({
      event: "mouse-click",
      action: `organization-selector-view-change-${viewFormat}`,
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `organization selector view`,
      level: Sentry.Severity.Info,
    });
    setViewFormat(viewFormat);
  };

  const selectActiveScan = e => {
    const [organization, scan] = e.target ? e.target.value : e;
    tracking.trackEvent({
      event: "mouse-click",
      action: `organization-selector-select-active-scan-${scan}`,
      userUId,
    });

    Sentry.addBreadcrumb({
      category: "mouse-click",
      message: `organization-selector-select active scan ${scan}`,
      level: Sentry.Severity.Info,
    });
    setActiveScan(scan);
    setActiveOrganization(organization);
    history.push(`${ROUTE_MAP}/${scan}`);
  };

  const displayGrid = () => {
    if (gridOrganizations.length > 0) {
      return gridOrganizations.map(org => {
        return (
          <Grid key={org.id} item sm={12}>
            <OrganizationSelectorGridView
              key={org.name}
              organization={org}
              setActiveScan={selectActiveScan}
            />
          </Grid>
        );
      });
    }
  };

  const makeOrgList = () => {
    const orgArray = [];
    if (organizations) {
      organizations.forEach(org => {
        orgArray.push(org);
      });
    }
    return orgArray;
  };

  useEffect(() => {
    if (organizations.length > 0) {
      setGridOrganizations(gridOrgs(organizations));
    }
  }, [organizations]);

  return (
    <OrganizationSelector
      format={viewFormat}
      handleViewChange={handleViewChange}
      organizations={gridOrganizations}
      childOrganizations={makeOrgList()}
      setActiveScan={selectActiveScan}
      organizationScans={organizationScans}
      isOrganizationsDataLoaded={isOrganizationsDataLoaded}
      displayGrid={displayGrid}
      measuringSystem={measuringSystem}
    />
  );
};

OrganizationSelectorContainer.propTypes = {
  user: PropTypes.shape({
    userUId: PropTypes.string,
    hasAuthToken: PropTypes.bool,
  }).isRequired,
  organizations: PropTypes.array.isRequired,
  setActiveOrganization: PropTypes.func.isRequired,
  isOrganizationsDataLoaded: PropTypes.bool.isRequired,
  organizationScans: PropTypes.object.isRequired,
  measuringSystem: PropTypes.string.isRequired,
  tracking: PropTypes.object.isRequired,
  setActiveScan: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  organizations: state.userData.organizations,
  isOrganizationsDataLoaded: state.userData.isOrganizationsDataLoaded,
  organizationScans: state.userData.organizationScans,
  measuringSystem: state.userData.measuringSystem,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveOrganization,
      fetchOrganizations,
      setActiveScan,
    },
    dispatch
  );

export default track()(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationSelectorContainer)
);
