import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { ROUTE_DASHBOARD } from "constants/routes";
import get from "lodash/get";
import { updateOsDrawerWidth } from "../constants/ui";

const PublicRoute = ({ component: Component, user, ...rest }) => {
  // Since the osSideBar is not displayed on public routes, lets makes the
  // width 0, so that things like the map sidebar, banners, and appbars are
  // not shifted to the right unnecessarily.
  updateOsDrawerWidth(0);
  return (
    <Route
      {...rest}
      render={props =>
        user === null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: get(
                rest,
                "location.state.from.pathname",
                ROUTE_DASHBOARD
              ),
              // eslint-disable-next-line react/prop-types
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/require-default-props
  user: PropTypes.string,
};

export default PublicRoute;
