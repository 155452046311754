import { hideModal, openModal } from "actions/modal";

const roadwayPartnerLayer = ({ feature, dispatch }) => {
  const properties = feature.properties;
  const style = {
    width: "40%",
    padding: "-5%",
    position: "fixed",
    zIndex: "5",
    backgroundColor: "white",
    marginTop: "-5%",
    marginLeft: "0%",
    borderRadius: "10px",
  };

  dispatch(
    openModal({
      type: "signage",
      segmentData: {},
      pointData: {},
      content: {
        title: "Circle",
        hideCloseIcon: true,
        disableOverlay: true,
        style,
        imgURL: properties.href,
        color: properties.color,
        rating: properties.rating,
        closeButtonClick: hideModal,
        bearing: properties.bearing,
      },
    })
  );
};

export default roadwayPartnerLayer;
