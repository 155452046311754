import React from "react";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Dialog from "components/Dialog/Dialog";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import ActivityIndicatorButton from "components/ActivityIndicatorButton";
import ScansList from "components/ScansList";

import { useForm, Controller } from "react-hook-form";
import OrganizationsList from "components/OrganizationsList";

import { RB_YELLOW } from "constants/colors";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { validateEmail } from "../../utils/validateEmail";

const useStyles = makeStyles({
  form: {
    marginBottom: "10px",
  },
  cardHeaderRoot: {
    padding: "4px 16px",
    backgroundColor: RB_YELLOW,
  },
  gridContainerRoot: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  cardContentRoot: {
    padding: "8px 4px 16px 16px",
  },
  formControl: {
    width: "100%",
  },
});

const UsersAdminInviteFormDialog = ({
  title,
  isOpen,
  roles,
  scans,
  handleClose,
  handleInvite,
  values: initialFormState,
  isSubmitActive,
  organization,
}) => {
  const { register, errors, control, handleSubmit } = useForm();
  const classes = useStyles();

  const handleFormSubmit = e => {
    e.preventDefault();
    handleSubmit(data => {
      handleInvite(data);
    })(e);
  };

  const renderForm = () => (
    <form onSubmit={handleFormSubmit} className={classes.form}>
      <div>
        <TextField
          label="New user email"
          name="email"
          inputRef={register({
            required: "This field is required.",
            validate: value =>
              validateEmail(value) || "Please use a valid email",
          })}
          error={errors?.email !== undefined}
          helperText={errors?.email?.message}
        />
        <FormControl
          error={errors?.role !== undefined}
          className={classes.formControl}
        >
          <Controller
            name={"role"}
            control={control}
            rules={{
              required: "This field is required",
            }}
            defaultValue={initialFormState?.role}
            as={
              <Select displayEmpty name="role">
                <MenuItem value="" disabled>
                  Select Role
                </MenuItem>
                {roles &&
                  roles.map(role => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
              </Select>
            }
          />
          <FormHelperText>{errors?.role?.message}</FormHelperText>
        </FormControl>
      </div>
    </form>
  );

  const renderFormActions = () => (
    <ActivityIndicatorButton
      onClick={handleFormSubmit}
      isActive={isSubmitActive}
    >
      Submit
    </ActivityIndicatorButton>
  );

  const renderListComponentItem = (title, component) => (
    <Grid sm={6} item>
      <Card>
        <CardHeader
          title={title}
          titleTypographyProps={{
            variant: "overline",
            style: { fontWeight: "600" },
          }}
          className={classes.cardHeaderRoot}
        />
        <CardContent classes={{ root: classes.cardContentRoot }}>
          {component}
        </CardContent>
      </Card>
    </Grid>
  );

  const getScansListComponent = () => <ScansList scans={scans} />;
  const getOrganizationsListComponent = () => (
    <OrganizationsList currentOrganization={organization} />
  );

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      handleClose={handleClose}
      actions={renderFormActions()}
    >
      <section>
        {renderForm()}
        <Typography variant="body1" gutterBottom>
          This user will have access to:
        </Typography>
        <Grid
          container
          classes={{ root: classes.gridContainerRoot }}
          spacing={2}
        >
          {renderListComponentItem("Assessments", getScansListComponent())}
          {renderListComponentItem(
            "Organizations",
            getOrganizationsListComponent()
          )}
        </Grid>
      </section>
    </Dialog>
  );
};

UsersAdminInviteFormDialog.defaultProps = {
  title: "",
  isOpen: false,
  handleClose: () => {},
};

UsersAdminInviteFormDialog.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  roles: PropTypes.array.isRequired,
  scans: PropTypes.array.isRequired,
  handleInvite: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitActive: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
};

export default UsersAdminInviteFormDialog;
