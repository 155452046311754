import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RB_LIGHT_GREY } from "../constants/colors";
import Corner, { CornerType } from "components/Corner";

const useStyles = makeStyles({
  zoom: {
    background: RB_LIGHT_GREY,
    margin: "5px 20px ",
  },
});

const MapSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Corner corner={CornerType.TopLeft}>
      <Skeleton
        className={classes.zoom}
        variant="rect"
        width={26}
        height={52}
      />
    </Corner>
  );
};

export default MapSkeleton;
